.overlay-parking-card {
    position: absolute;
    top: calc($headerHeight + 71px);
    right: 0;
    z-index: 5;
    width: 350px;
    height: $parkingCardHeight;
    padding: 20px;
    background-color: white;
    border-left: solid 4px $color3;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-property: opacity, visibility;
    transition-duration: 300ms, 0ms;
    transition-timing-function: linear, linear;
    transition-delay: 0ms, 300ms;

    &.--home { top: calc($headerHeight + 30px); }
    &.--pageTop { top: calc($headerHeight + 71px); }
    &.--subHeader { top: calc($headerHeight + 101px); }
    &.--closed {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    @media screen and (max-height: 700px) { top: calc($headerHeight + 15px); }
    @media screen and (max-width: 1200px) { display: none; }

    &__flex {
        display: flex;
        align-items: center;
    }

    &__svg-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 55px;
        height: 55px;
        margin-right: 22px;
        border-radius: 50%;
        background-color: $color2;

        svg {
            width: 30px;
            height: 30px;

            &:last-child {
                display: none;
            }
        }
    }

    &.--no-parking &__svg-container svg {
        &:first-child {
            display: none;
        }

        &:last-child {
            display: block;
        }
    }

    &__date,
    &__title {
        font-family: $font;
        font-size: 16px;
    }

    &__date {
        display: block;
        margin-top: 5px;
        font-weight: 500;
        color: $color4;
    }

    &__title {
        font-weight: 700;
        line-height: 1.2em;
        color: $color1;
    }

    button {
        appearance: none;
        color: initial;
        background: initial;
        border: initial;
        font: initial;
        border-radius: initial;
        padding: initial;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    &__close-button {
        position: absolute;
        top: 13px;
        right: 13px;
        width: 12px;
        height: 12px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
}

.showOverlayParkingCard .overlay-parking-card {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition-delay: 300ms, 0ms;
}
