// IMPORTATION __________
@forward "./node_modules/swiper/swiper-bundle.css";
@import "../node_modules/rangeslider-pure/dist/range-slider.css";
@import "../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../node_modules/slick-carousel/slick/slick.scss";

// Général
@import 'essentials/variables';
@import 'essentials/resets';
@import 'essentials/grille';
@import 'essentials/fonts';
@import 'essentials/classes';
@import 'essentials/helper';
@import 'essentials/generic';

// Overlays
@import 'essentials/overlays/menu';
@import 'essentials/overlays/search';
@import 'essentials/overlays/mobileMenu';
@import 'essentials/overlays/fastlinks';
@import 'essentials/overlays/calendar';
@import 'essentials/overlays/locations';
@import 'essentials/overlays/location';
@import 'essentials/overlays/directory';
@import 'essentials/overlays/share';
@import 'essentials/overlays/alertPopup';
@import 'essentials/overlays/surveyPopup';

// Mixins
@import 'components/mixins/dynamic';
@import 'components/mixins/mixins';
@import 'essentials/dynamic';

// Animation
@import 'components/animation/scrollFire';
@import 'components/animation/transition';

// Components
@import 'components/drawers';
@import 'components/calendar';
@import 'components/documents';
@import 'components/hamburgerBtn';
@import 'components/vitrine';

// Formulaire
@import 'components/form/form';
@import 'components/form/radiobox';
@import 'components/form/checkbox';
@import 'components/form/form';
@import 'components/form/select';
@import 'components/form/file';
@import 'components/form/input';
@import 'components/form/textarea';
@import 'components/form/dropzone';

//  Pages
@import 'pages/home';
@import 'pages/generic';
@import 'pages/documents';
@import 'pages/events';
@import 'pages/event';
@import 'pages/news';
@import 'pages/newsDetail';
@import 'pages/map';
@import 'pages/search';
@import 'pages/contact';
@import 'pages/section';
@import 'pages/jobs';
@import 'pages/banqueDocuments';

// Partials
@import 'partials/header';
@import 'partials/footer';
@import 'partials/alertBanner';
@import 'partials/parking-card';
@import 'partials/layout/breadcrumbs';
@import 'partials/layout/share';
@import 'partials/layout/fastLinks';


