#landing {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	z-index: 9999;
	transition: visibility 0ms ease 1100ms;
}

.chargement {
	#landing {
		opacity: 1;
		visibility: visible;
	}
}

#page-transition {
	position: fixed;
	top: 0%;
	right: 0;
	bottom: 100%;
	left: 0;
	z-index: 9999;
	background: white;
	visibility: hidden;
}

.transition {
	#page-transition {
		top: 0%;
		bottom: 0%;
		visibility: visible;
		transition: top 1000ms cubic-bezier(.77,0,.175,1), bottom 1000ms cubic-bezier(.77,0,.175,1);
	}
}

.closing {
	#page-transition {
		top: 100%;
		bottom: 0%;
		visibility: visible;
		transition: top 1000ms cubic-bezier(.77,0,.175,1), bottom 1000ms cubic-bezier(.77,0,.175,1);
	}
}

.transition, .closing {
	header { pointer-events: none; }
}
