#page-map {
    position: relative;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    header {
        position: relative;
        background: $color3;
        height: $headerHeight;

        @media screen and (max-height: 650px) { height: 80px; }
        @media screen and (max-height: 550px) { height: 60px; }
        @media screen and (max-width: 1024px) { height: 80px; }
        @media screen and (max-width: 768px)  { height: 60px; }

        .grid {
            height: $headerHeight;
            align-items: center;

            @media screen and (max-height: 650px) { height: 80px; }
            @media screen and (max-height: 550px) { height: 60px; }
            @media screen and (max-width: 1024px) { height: 80px; }
            @media screen and (max-width: 768px)  { height: 60px; }

            .column.last { text-align: right; }

            .logo {
                img {
                    width: 174px;
                    height: 45px;

                    @media screen and (max-height: 650px) { width: 185px; }
                    @media screen and (max-width: 768px)  { width: 185px; }
                }
            }

            .back {
                position: relative;
                padding-right: 90px;

                @media screen and (max-height: 550px) { padding-right: 60px; }
                @media screen and (max-width: 768px)  { padding-right: 60px; min-height: 10px; }

                span {
                    &:not(.close) {
                        font: 22px/50px $font;
                        font-weight: 500;
                        color: #fff;

                        @media screen and (max-width: 1024px) { font-size: 16px; }
                        @media screen and (max-width: 768px)  { font-size: 14px; }
                        @media screen and (max-width: 500px)  { display: none; }
                    }
                    &.close {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 67px;
                        height: 67px;
                        transform: translateY(-50%) rotate(45deg);
                        background-color: rgba(0,0,0,0.1);
                        border-radius: 50%;
                        transition: background-color $ease 0.7s;

                        @media #{$down1024} { width: 50px; height: 50px; }
                        @media screen and (max-height: 550px) { width: 36px; height: 36px; }
                        @media screen and (max-width: 768px)  { width: 36px; height: 36px; }

                        &::before,
                        &::after{
                            content: '';
                            background: white;
                            width: 17px;
                            height: 2px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                        &::after{
                            width: 2px;
                            height: 17px;
                        }
                    }
                }

                &:hover{
                    span.close {
                        background-color: rgba(0,0,0,0.3);
                    }
                }
            }
        }
    }

    .navBlock {
        display: none;

        @media screen and (max-width: 1024px) { display: block; }

        span {
            height: 30px;
            display: block;
            padding: 0 20px;
            color: $color1;
            background: #fff;
            text-align: center;
            border-top: 1px solid #fff;
            cursor: pointer;
            line-height: 30px;
            font-weight: 600;
        }
    }

    .mapBlock {
        #googleMap {
            position: absolute;
            width: calc(100% - 360px);
            height: calc(100vh - #{$headerHeight});
            top: $headerHeight;
            right: 0;
            background-color: $color2;

            @media screen and (max-height: 650px) { height: calc(100vh - 80px); top: 80px; }
            @media screen and (max-height: 550px) { height: calc(100vh - 60px); top: 60px; }
            @media screen and (max-width: 1024px) { height: calc(100vh - 110px); top: 110px; width: 100%; }
            @media screen and (max-width: 768px)  { height: calc(100vh - 90px); top: 90px; }
        }
    }
}

.mapPin {
    width: 40px;
    height: 40px;
    line-height: 40px;
    //background: $color6;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    display: block;

    .marker{
        fill: #868686;
        stroke: #868686;
        height: 40px;
        width: 40px;
        transition: all 300ms;
    }

    .icon {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%,0);
        width: 1em;
        height: 1em;
        transition: all 300ms;

        fill: transparent;
        stroke: #fff;
        stroke-width: 2.5px;
        stroke-linecap: round;
    }

    &:hover,
    &.active {
        .marker{
            fill: $color1;
            stroke: $color1;
        }
        .icon {
            stroke: #fff;
        }
    }
}

// Pastilles de couleur des états des lieux
.etat {

    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: -10px;
        background-color: $color1;
        height: 5px;
        width: 5px;
        border-radius: 50%;
    }

    &.open::after { background-color: $color6 }
    &.close::after { background-color: $color8 }
	&.partial::after { background-color: $color9 }

    // Pastille de l'overlay de détails
    &.detail {

        margin-bottom: 5px !important;
		padding-left: 30px;

		&::before {
            padding-left: 30px;
        }

        &::after {
            height: 12px;
            width: 12px;
            left: 4px;
            right: auto;
            top: 50%;
            transform: translateY(-50%);
        }

		&.etat-open 	{ color: $color6; }
        &.etat-close  	{ color: $color8; }
		&.etat-partial 	{ color: $color9; }

        &.etat-open::after		{ background-color: $color6 }
        &.etat-close::after 	{ background-color: $color8 }
		&.etat-partial::after 	{ background-color: $color9 }

    }

    // Pastille de la puce sur la carte intéractive
    &.mapPin::after {
        height: 11px;
        width: 11px;
        transform: translate(50%, -50%);
        top: 5px;
        right: 5px;
    }
}

.etatWrapper {
	margin-bottom: 20px;
}

.etatnote {
	padding-left: 30px;
	font-size: 14px;
	line-height: 1.4em;
	margin-bottom: 5px;
}