// MEDIA QUERY
$down1800: 'screen and (max-width: 1800px)';     $up1800: 'screen and (min-width: 1801px)';
$down1700: 'screen and (max-width: 1700px)';     $up1700: 'screen and (min-width: 1701px)';
$down1600: 'screen and (max-width: 1600px)';     $up1600: 'screen and (min-width: 1601px)';
$down1500: 'screen and (max-width: 1500px)';     $up1500: 'screen and (min-width: 1501px)';
$down1400: 'screen and (max-width: 1400px)';     $up1400: 'screen and (min-width: 1401px)';
$down1300: 'screen and (max-width: 1300px)';     $up1300: 'screen and (min-width: 1301px)';
$down1200: 'screen and (max-width: 1200px)';     $up1200: 'screen and (min-width: 1201px)';
$down1100: 'screen and (max-width: 1100px)';     $up1100: 'screen and (min-width: 1101px)';
$down1024: 'screen and (max-width: 1024px)';     $up1024: 'screen and (min-width: 1025px)';
$down1000: 'screen and (max-width: 1000px)';     $up1000: 'screen and (min-width: 1001px)';
$down900 : 'screen and (max-width: 900px)';      $up900 : 'screen and (min-width: 901px)';
$down800 : 'screen and (max-width: 800px)';      $up800 : 'screen and (min-width: 801px)';
$down768 : 'screen and (max-width: 768px)';      $up768 : 'screen and (min-width: 769px)';
$down700 : 'screen and (max-width: 700px)';      $up700 : 'screen and (min-width: 701px)';
$down600 : 'screen and (max-width: 600px)';      $up600 : 'screen and (min-width: 601px)';
$down500 : 'screen and (max-width: 500px)';      $up500 : 'screen and (min-width: 501px)';
$down430 : 'screen and (max-width: 430px)';      $up430 : 'screen and (min-width: 431px)';
$down400 : 'screen and (max-width: 400px)';      $up400 : 'screen and (min-width: 401px)';
$down375 : 'screen and (max-width: 375px)';      $up300 : 'screen and (min-width: 376px)';
$down360 : 'screen and (max-width: 360px)';      $up360 : 'screen and (min-width: 361px)';

// TECHNIQUE
$colnb: 12; // Nombre de colonnes pour la grille
$gaps: 1%;  // Padding de chaque côté d'une colonne
//$ease: cubic-bezier(0.19, 1, 0.22, 1);
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// COULEURS
$color1: #3f3f3f; // Gris foncé
$color2: #f3f3f3; // Gris pâle
$color3: #e63735; // Rouge
$color4: #7e7e7e; // Gris medium
$color5: #363636; // Gris fil d'Ariane
$color6: #76cd95; // Vert
$color7: #e8e8e8; // Gris no3
$color8: #e14a4a; // Red
$color9: #f1d700; // Yellow
$color10: #161616; // Noir foncé

$breadcrumbsColor: #8c8c8c;
$alertColor: #f7985e;
$dropMenuColor1: #f3f3f3;
$dropMenuColor2: #eee;
$formBorderColor: #c5c5c5;

// FONTS
$font: 'Gilroy', serif;

// SIZES
$headerHeight: 88px; // Hauteur de header
$alertHeight: 48px; // Hauteur du bandeau d'alertes
$breadcrumbsHeight: 60px; // Hauteur du fil d'Ariane
$parkingCardHeight: 92px; // Hauteur de la vignette de stationnement

