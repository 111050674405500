#page-section {
    .headerPage {
        background: $color5;

        h1 { margin-bottom: 0; margin-top: 25px; }
    }

    .sectionBlock {

        .sectionWrapper {

            .flexItem {
                width: 25%;
                padding: 0 1%;
                @include setMarginTop(50px, 4);

                @media #{$down1600} { width: 33.3333%; @include setMarginTop(50px, 3); }
                @media #{$down1300} { @include setMarginTop(40px, 3); }
                @media #{$down900} { width: 50%; @include setMarginTop(30px, 2); }
                @media #{$down600} { width: 100%; @include setMarginTop(20px, 1); }

                a {
                    display: block;
                
                    .imageWrapper {
                        position: relative;
                        height: 225px;

                        .image {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-position: center;
                            background-size: cover;

                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background: rgba($color1, 0);
                                transition: background 300ms;
                            }
                        }
                    }

                    span {
                        display: block;
                        margin-top: 10px;
                        font: 25px/1.1 $font;
                        color: $color1;
                        transition: color 300ms;
                    }
                }

                &:hover {
                    .imageWrapper .image:after { background: rgba($color1, 0.4); }
                    span { color: $color1; }
                }
            }
        }
    }
}