#overlay-popup{

    .wrapper{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
        padding: 130px 40px 110px 40px;
        background-color:$color7;
        max-width: 500px;
        width: 90%;
        transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1), transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;

        @media #{$down800}{padding: 60px 40px 60px 40px;}
        @media #{$down600}{width: 80%;}

        .close {
            position: absolute;
            width: 60px;
            height: 60px;
            top: -70px;
            right: -70px;
            cursor: pointer;

            @media #{$down768}{ 
                top: -85px;
                right: 0px;
            }

            &:after{
                content: '';
                position: absolute;
                width: 60px;
                height: 60px;
                left: 50%;
                top: 50%;
                background-color: white;
                transition: transform 0.6s $ease;
                border-radius: 50%;
                transform: translate(-50%, -50%) scale(1);
            }

            div{
                position: absolute;
                left: 50%;
                top: 50%;
                width: 60px;
                height: 60px;
                transform: translate(-50%, -50%);
                z-index: 1;
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    background: $color3;
                    top: 50%;
                    left: 50%;
                    width: 20px;
                    height: 2px;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:after{
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }

            &:hover{
                &::after{
                    transform: translate(-50%, -50%) scale(1.1); 
                }
            }
        }

        .popup-text{
            text-align: center;
            font-size: 30px;
            line-height: 1.266666666666667em; // 38px/30px
            @media #{$down800}{ font-size: 25px; }
            @media #{$down500}{ font-size: 20px; }
            @media #{$down400}{ font-size: 17px; }
        }

        .popup-button{
            text-align: center;
            margin-top: 40px;
        }
    }
}

.showOverlayAlert-active{

    &.overlayClosing #overlay-popup .wrapper{
        opacity: 0;
    }
    #overlay-popup .wrapper{
        visibility: visible;
        opacity: 1;
        transition: all 1s $ease;
    }

    &.overlayClosing .popupOverlay{
        opacity: 0;
    }
    .popupOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }

}