.control-multi-file-uploader.form-group{
    margin-bottom: 45px;
    position: relative;
    border: 2px dashed $color2;
    padding: 20px;

    .dz-clickable{
        height: 100px;
        background-color: $color2;
        cursor: pointer;
    }

    .content{
        .placeholder{
            position: absolute;
            left: 50%;
            height: 100px;
            line-height: 100px;
            top: 20px;
            pointer-events: none;
            transform: translate(-50%, 0);
        }

        .dz-preview{
            position: relative;
            padding-left: 120px;
            padding-right: 35px;
            min-height: 100px;
            display: flex;
            align-items: center;
            margin-top: 20px;
            background-color: $color2;

            .thumbnail{
                position: absolute;
                left: 0;
                top: 0;
                height: 100px;
                width: 100px;
            }

            .dz-details{
                flex-wrap: wrap;
                .dz-filename,
                .dz-size{
                    width: 100%;
                    margin: 6px 0;
                }
            }

            .action-panel{
                position: absolute;
                background: $color3;
                color: white;
                border-radius: 50%;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                z-index: 4;
                a{
                    position: absolute;
                    color: white;
                    top: calc(50% + 1px);
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }

            .dz-error-mark{ display: none; }
            .dz-error-message{
                position: absolute;
                bottom: 5px;
                color: $color3;
            }

            .action-panel{
                position: absolute;
            }
        }
    }
}
