// GRILLE _________________________
.grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    flex-flow: row wrap;
	width: calc(100% + #{$gaps} + #{$gaps});
	margin-left: -$gaps;
	margin-right: -$gaps;

	.column {
		padding-right: $gaps;
		padding-left: $gaps;
	}
}


// Création des colonnes de base
@for $i from 1 through $colnb {
	.col#{$i} { width: ($i / $colnb * 100%); }
}

// Media query 1800
@media #{$down1800}{
	@for $i from 1 through $colnb {
		.col#{$i}_1800 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1700
@media #{$down1700}{
	@for $i from 1 through $colnb {
		.col#{$i}_1700 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1600
@media #{$down1600}{
	@for $i from 1 through $colnb {
		.col#{$i}_1600 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1500
@media #{$down1500}{
	@for $i from 1 through $colnb {
		.col#{$i}_1500 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1400
@media #{$down1400}{
	@for $i from 1 through $colnb {
		.col#{$i}_1400 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1300
@media #{$down1300}{
	@for $i from 1 through $colnb {
		.col#{$i}_1300 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1200
@media #{$down1200}{
	@for $i from 1 through $colnb {
		.col#{$i}_1200 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1100
@media #{$down1100}{
	@for $i from 1 through $colnb {
		.col#{$i}_1100 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1024
@media #{$down1024}{
	@for $i from 1 through $colnb {
		.col#{$i}_1024 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1000
@media #{$down1000}{
	@for $i from 1 through $colnb {
		.col#{$i}_1000 { width: ($i / $colnb * 100%); }
	}
}

// Media query 900
@media #{$down900}{
	@for $i from 1 through $colnb {
		.col#{$i}_900 { width: ($i / $colnb * 100%); }
	}
}

// Media query 800
@media #{$down800}{
	@for $i from 1 through $colnb {
		.col#{$i}_800 { width: ($i / $colnb * 100%); }
	}
}

// Media query 768
@media #{$down768}{
	@for $i from 1 through $colnb {
		.col#{$i}_768 { width: ($i / $colnb * 100%); }
	}
}

// Media query 700
@media #{$down700}{
	@for $i from 1 through $colnb {
		.col#{$i}_700 { width: ($i / $colnb * 100%); }
	}
}

// Media query 600
@media #{$down600}{
	@for $i from 1 through $colnb {
		.col#{$i}_600 { width: ($i / $colnb * 100%); }
	}
}

// Media query 500
@media #{$down500}{
	@for $i from 1 through $colnb {
		.col#{$i}_500 { width: ($i / $colnb * 100%); }
	}
}

// Media query 400
@media #{$down400}{
	@for $i from 1 through $colnb {
		.col#{$i}_400 { width: ($i / $colnb * 100%); }
	}
}
