.fastLinks{
    position: fixed;
    right: 0;
    bottom: 130px;
    background-color: white;
    visibility: hidden;
    width: 0;
    transition: width 0.4s $ease;
    overflow: hidden;
    z-index: 105;

    @media #{$down1400} {
        top: 0;
        height: 100%;
    }

    ul{
        padding: 40px 45px;
        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.08);
        @media #{$down1400} { box-shadow: none; padding-right: 110px; }
        @media #{$down1024} { padding: 35px 30px; padding-right: 110px; }
        li{
            padding-top: 18px;
            a{
                display: flex;
                align-items: center;
                font-weight: 500;
                color: $color4;
                position: relative;
                padding-left: 80px;
                white-space: nowrap;
                .iconContainer{
                    width: 55px;
                    height: 55px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    transition: all $ease 0.3s;

                    .icon{
                        width: 25px;
                        height: 25px;
                        fill: none;
                        stroke: $color1;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        z-index: 1;
                        transform: translate(-50%,-50%);
                        transition: transform 0.3s $ease, stroke 0.3s $ease;
                        stroke-width: 2.8px;
                        stroke-linecap: round;
                    }

                    .iconFull{
                        width: 25px;
                        height: 25px;
                        fill: $color1;
                        stroke: none;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        z-index: 1;
                        transform: translate(-50%,-50%);
                        transition: transform 0.3s $ease, stroke 0.3s $ease;
                    }

                    &::after{
                        content: '';
                        background-color: $color2;
                        border-radius: 50%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transition: transform $ease 0.3s, background-color $ease 0.3s;
                    }
                }
                span{
                    transition: transform $ease 0.7s;
                    line-height: 65px;
                }

                &:hover{
                    color: $color1;
                    .iconContainer{
                        .icon{
                            stroke: white;
                        }
                        .iconFull{
                            fill: white;
                        }
                        &::after{
                            transform: scale(1.18); // 65px / 55px
                            background-color: $color3;
                        }
                    }
                    span{
                        transform: translate(5px,0); // Le cercle grossit de 10px de large au hover, le offset de 5px fait rester le centre au même endroit
                    }
                }
            }
            &:first-child{
                padding-top: 0;
            }
        }
    }

}


.showOverlayFastLinks{

    &.overlayClosing .fastLinksBtn{ // Bouton pour fermer l'overlay sur mobile
        transform: translate(0,0) rotate(0);
    }
    .fastLinksBtn{
        transform: translate(0,0) rotate(45deg);
        visibility: visible;
        opacity: 1;
        &:hover{
            transform: translate(0,0) rotate(45deg) scale(1.16);
        }
    }

    &.overlayClosing .fastLinks{
        width: 0;
    }
    .fastLinks{
        visibility: visible;
        width: 350px;
        transition: width 0.4s ease;
        @media #{$down1400} { width: 420px; }
        @media #{$down600}  { width: 100% }
    }

    &.overlayClosing .fastLinksOverlay{
        opacity: 0;
    }
    .fastLinksOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }
}

.show-filterModal{
    .fastLinksBtn{
        pointer-events: none;
    }
}
