#overlayLocations {
    position: absolute;
    width: 100%;
    max-width: 360px;
    height: calc(100vh - #{$headerHeight});
    top: $headerHeight;
    left: 0;
    background: white;
    overflow: auto;
    z-index: 55;
    box-shadow: 1px 0 12px rgba(0,0,0,0.1);

    @media screen and (max-height: 650px) { height: calc(100vh - 80px); top: 80px; }
    @media screen and (max-height: 550px) { height: calc(100vh - 60px); top: 60px; }
    @media screen and (max-width: 1024px) {
        height: calc(100vh - 110px);
        top: 110px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
    }
    @media screen and (max-width: 768px) {
        height: calc(100vh - 90px);
        top: 90px;
    }

    &::-webkit-scrollbar { display: none; }

    .btnSubLvl1 {
        background-color: $color2;
        > a,
        > span {
            display: block;
            position: relative;
            padding: 30px 60px;
            padding-right: 90px;
            font-size: 22px;
            font-weight: 500;
            color: #3c3d40;
            background-color: white;
            cursor: pointer;

            @media screen and (max-width: 768px) { padding: 30px 20px 30px 60px; }
            @media screen and (max-width: 500px) { padding: 30px 20px 30px 50px; }
            
            svg {
				position: absolute;
				width: 14px;
				height: 8px;
                top: 50%;
                right: 60px;
                transform: translateY(-50%) rotate(0deg);
                fill: $color3;
                transition: transform $ease 0.7s;
                
                @media screen and (max-width: 768px) { left: 30px; }
                @media screen and (max-width: 500px) { left: 20px; }
			}
        }

        &.active{
            > a,
            > span {
                font-weight: 600;
                color: $color1;
                svg {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }

    .btnSubLvl2 {
      
        margin-top: 30px;
        &:first-child { margin-top: 25px; }
        &:last-child { margin-bottom: 45px; } 
        
        > a,
        > span {
            display: block;
            position: relative;
            //padding-top: 8px;
            padding-left: 50px;
            font: 15px/1 $font;
            font-weight: 600;
            color: #8b8d91;
            cursor: pointer;
            transition: color 300ms;
            line-height: 1.2;
            
            svg {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 50%;
                left: 9px;
                fill: none;
                stroke: #8b8d91;
                stroke-width: 3px;
                transform: translateY(-50%);
                transition: stroke 300ms;
                z-index: 2;
            }

            &:after{
                content: '';
                position: absolute;
                z-index: 1;
                width: 34px;
                height: 34px;
                background-color: white;
                border-radius: 50%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: background-color $ease 0.7s;
            }

            &:hover {
                color: $color1;
                svg{
                    stroke: white; 
                }
                &:after{
                    background-color: $color3;
                }
            }
        }

        &.active > span {
            color: $color1;
            svg {
                stroke: white;
            }
            &:after{
                background-color: $color3;
            }
        }
    }

    .subLvl1 {
        display: none;
        padding-left: 60px;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 300ms, transform 300ms;

        @media screen and (max-width: 500px) { padding-left: 50px; }

        &.open { opacity: 1; transform: none; }
    }
            
    .subLvl2 {
        display: none;
        padding-right: 30px;
        padding-left: 50px;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 400ms, transform 400ms;

        @media screen and (max-width: 500px) { padding-left: 0px; }
        
        &.open {
            opacity: 1;
            transform: none;
        }

        li {
            &:first-child { margin-top: 15px; }
            &:last-child { margin-bottom: 35px; }
            &:not(:last-child) { margin-bottom: 15px; }

            span {
                font: 15px/1 $font;
                color: $color1;
                cursor: pointer;
                transition: color 300ms;
                font-weight: 500;

                &:hover, &:active, &:focus { color: $color3; }
            }

			&.active span {
				color: $color3;
			}
        }
    }
}

.showOverlayLocations {
  
    &.closingLocations #overlayLocations {
        opacity: 0;
        transform: translateY(50px);
    }

    #overlayLocations {
        opacity: 1;
        visibility: visible;
        transform: none;
        transition: opacity 300ms $ease, transform 300ms $ease;
    }
}