.overlay-mobile-menu{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 150;
    pointer-events: none;
    transition: visibility 0ms ease 700ms, opacity 300ms;

    .wrapper{
        position: fixed;
        width: calc(100% - 331px);
        left: calc(-100% + 331px);
        height: calc(100vh - #{$headerHeight});
        padding-top: 45px;
        padding-bottom: 90px;
        top: $headerHeight;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        pointer-events: all;
        z-index: 101;
        scrollbar-width: none;
        padding-left: 30px;
        padding-right: 30px;
        transition: left $ease 0.8s;
        box-shadow: inset 0px 15px 9px -12px rgba($color1,0.1);
        @media #{$down768} { width: 100%; left: -100%; }

        &>h3{
            margin-bottom: 25px;
            font-size: 30px;
            opacity: 0;
            transform: translate(-50px,0);
            transition: transform 0.7s $ease 0.6s, opacity 0.7s $ease 0.6s;
            //Délais d'animation
            &:nth-of-type(2){ transition-delay: 0.8s; }
            &:nth-of-type(3){ transition-delay: 1s; }
        }
        &>ul{
            margin-bottom: 50px;

            &:last-child{margin-bottom: 0;}

            li.btnSubLvl1{
                margin-bottom: 25px;
                transform: translate(-50px,0);
                opacity: 0;
                transition: transform 0.7s $ease 0.7s, opacity 0.7s $ease 0.7s;

                & > span,
                & > a{
                    font-size: 25px;
                    position: relative;
                    padding-right: 25px;
                    color: $color1;
                    display: inline-block;
                    cursor: pointer;
                    svg.icon{
                        fill: $color3;
                        width: 12px;
                        height: 8px;
                        position: absolute;
                        top: 0.6em;
                        transform: translate(0, -50%) rotate(0deg);
                        right: 0;
                        transition: transform $ease 0.6s;
                    }
                }

                & > .subLvl1{
                    display: none;
                    li{
                        &:first-child{ padding-top: 15px; }
                        &:last-child{ padding-bottom: 15px; }
                        span, a{
                            padding: 10px 0;
                            font-size: 19px;
                            font-weight: 500;
                            color: $color1;
                            cursor: pointer;
                        }
                    }
                }

                &:last-child{
                    margin-bottom: 0;
                }

                &.active span svg{
                    transform: translate(0, -50%) rotate(180deg); 
                }

            }

            //Délais d'animation
            &:nth-of-type(1){
                li.btnSubLvl1:nth-child(1){ transition-delay: 0.65s; }
                li.btnSubLvl1:nth-child(2){ transition-delay: 0.7s; }
                li.btnSubLvl1:nth-child(3){ transition-delay: 0.75s; }
            }
            &:nth-of-type(2){
                li.btnSubLvl1:nth-child(1){ transition-delay: 0.85s; }
                li.btnSubLvl1:nth-child(2){ transition-delay: 0.9s; }
                li.btnSubLvl1:nth-child(3){ transition-delay: 0.95s; }
            }
            &:nth-of-type(3){
                li.btnSubLvl1:nth-child(1){ transition-delay: 1.05s; }
                li.btnSubLvl1:nth-child(2){ transition-delay: 1.1s; }
                li.btnSubLvl1:nth-child(3){ transition-delay: 1.15s; }
            }
        }
    }
}

// Animation des éléments du menu
.showOverlayMenu{
    .overlay-mobile-menu{
        .wrapper{
            & > h3,
            & > ul li.btnSubLvl1{
                transform: translate(0,0);
                opacity: 1;
            }
        }
    }
}


.showOverlayMenu:not(.overlayClosing){
    .overlay-mobile-menu{
        .wrapper{
            left: 0;
        }
    }
}

.showOverlayMenu{
    &.showAlertBanner{
        .wrapper{
            height: calc(100vh - #{$headerHeight} - #{$alertHeight});
            top: calc(#{$headerHeight} + #{$alertHeight});
        }
    }
}



.showOverlayMenu{
    &.overlayClosing .menuOverlay{
        opacity: 0;
    }
    .menuOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }
}
