.eventsHeader{
    
    &.wFilter{
        .wrapper{
            position: relative;
            padding-right: 350px;
            @media #{$down900} { padding-right: 0; padding-bottom: 80px; }
            .select-field{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0,-50%);
                width: 350px;
                //z-index: 200;
                z-index: 103;
                @media #{$down900} {
                    width: 100%;
                    bottom: 0;
                    transform: none;
                }
            }
        }
    }

}

.firstEvent{
    background-color: white;
    position: relative;
    .news{
        display: flex;
        margin-bottom: 60px;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        .thumb{
            order: 2;
            width: 50%;
            height: 475px;
            position: relative;
            overflow: hidden;
            @media #{$down1024} {
                order: 1;
                width: 100%;
                height: 380px;
            }
            @media #{$down800} {
                order: 2;
                width: 100%;
                height: auto;
                padding-top: 75.15337423312883%; // Ratio de 326px par 245px
            }

            .image{
                transition: transform $ease 0.5s;
                position: absolute;
                width: 100%;
                top: -20%;
                bottom: -20%;
            } 
        }

        .infos{
            order: 1;
            width: 50%;
            padding: 45px 90px;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            @media #{$down1200} { padding: 30px 24px; }
            @media #{$down1024} { order: 2; width: 100%; }
            .metas{
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                color: $color4;
                padding-bottom: 18px;
                .date{
                    color: $color1;
                }
            }
            .title{
                font-size: 40px;
                line-height: 1.28em; // 32px /25px
                font-weight: 500;
                color: $color1;
                padding-bottom: 30px;
                @media #{$down1200} { font-size: 30px; }
                @media #{$down1024} { max-width: 500px; }
                @media #{$down800}  { font-size: 22px; }
            }
            .readmore{
                display: inline-block;
                color: $color3;
                font-weight: 600;
                position: relative;
                padding-right: 25px;

                &:after{
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 10px;
                    top: 50%;
                    right: 10px;
                    background: url(../images/icons/arrowRed.svg) no-repeat center/contain;
                    transform: translateY(calc(-50% + 1px)); // Ajustement de 1px a cause d'illusion d'optique
                    transition: right 300ms;
                }
            }
        }
        &:hover{
            .thumb{
                .image{
                    transform: scale(1.1);
                }
            }
            .infos{
                .readmore{
                    &:after{
                        right: 0;
                    }
                }
            }
        }
    }

    .pattern{
        position: absolute;
        width: 160px;
        height: 90%;
        top: -25px;
        left: -30px;
        background-image: url(../images/content/patterns/dots.png);
        z-index: 1;
    }
}

.newsList{
    margin-bottom: -30px;
    .column{
        margin-bottom: 30px;
    }
    .news{
        background-color: white;
        display: block;
        height: 100%;
        
        @media #{$down800} { height: auto; }

        .thumb{
            width: 100%;
            padding-top: 75.15337423312883%; // Ratio de 326px par 245px
            position: relative;
            overflow: hidden;
            background-color: $color1;
            //@media #{$down800} { padding-top: 60%; }

            .image{
                transition: transform $ease 0.5s, opacity $ease 0.5s;
                opacity: 1;
                position: absolute;
                width: 100%;
                top: -20%;
                bottom: -20%;
            }
        }
        .infos{
            padding: 45px 50px;
            background-color: white;
            position: relative;
            //min-height: 256px;
            @media #{$down1200} { padding: 30px 24px; }
            .metas{
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                color: $color4;
                padding-bottom: 18px;
                .date{
                    color: $color1;
                }
            }
            .title{
                font-size: 25px;
                line-height: 1.28em; // 32px /25px
                font-weight: 500;
                color: $color1;
                padding-bottom: 40px;
                @media #{$down1200} { font-size: 22px; }
            }
            .readmore{
                display: inline-block;
                color: $color3;
                font-weight: 600;
                position: absolute;
                bottom: 50px;
                padding-right: 25px;
                @media #{$down1200} { bottom: 24px; }

                &:after{
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 10px;
                    top: 50%;
                    right: 10px;
                    background: url(../images/icons/arrowRed.svg) no-repeat center/contain;
                    transform: translateY(calc(-50% + 1px)); // Ajustement de 1px a cause d'illusion d'optique
                    transition: right 300ms;
                }
            }
        }

        &:hover{
            .thumb{
                .image{
                    transform: scale(1.1);
                    opacity: 0.5;
                }
            }
            .infos{
                .readmore{
                    &:after{
                        right: 0;
                    }
                }
            }
        }

    }
}

.composanteNouvelles{
    h2{
        font-size: 75px;
        color: #3f3f3f;
        @media #{$down768} { font-size: 60px; }
        @media #{$down500} { font-size: 50px; }
        @media #{$down400} { font-size: 33px; }
    }
}

@media #{$down900} {
    #page-news,
    #page-NewsDetail{
        .share{
            display: none;
        }
    }
}