// Les espaces
@mixin margin85 {
    margin-top: 85px;
    @media #{$down1400} { margin-top: 75px; }
    @media #{$down1200} { margin-top: 65px; }
    @media #{$down1024} { margin-top: 55px; }
    @media #{$down768}  { margin-top: 45px; }
    @media #{$down600}  { margin-top: 40px; }
}

@mixin margin50 {
    margin-top: 50px;
    @media #{$down1024} { margin-top: 45px; }
    @media #{$down768}  { margin-top: 40px; }
    @media #{$down600}  { margin-top: 30px; }
}

@mixin margin40 {
    margin-top: 40px;
    @media #{$down1024} { margin-top: 35px; }
    @media #{$down768}  { margin-top: 30px; }
    @media #{$down600}  { margin-top: 25px; }
}

@mixin margin30 {
    margin-top: 30px;
    @media #{$down768}  { margin-top: 20px; }
}

@mixin margin20 {
    margin-top: 20px;
    @media #{$down600} { margin-top: 15px; }
}

@mixin margin15 { margin-top: 15px; }

// La zone dynamique
@mixin dynamic() {
    font: 17px/1.5 $font;
    color: $color1;

    @media #{$down600} { font-size: 16px; }

    :first-child { margin-top: 0; }
    :last-child { margin-bottom: 0; }
    h2, h3, h4, p, a, ul, ol, table, hr, img { margin: 0; }
    
    h2 {
        font-size: 40px;
        line-height: 1.2em;
        font-weight: 600;
        color: $color1;
        margin-top: 70px;
        margin-bottom: 30px;


        @media #{$down1400} { font-size: 33px; }
        @media #{$down1200} { font-size: 31px; }
        @media #{$down1024} { font-size: 29px; }
        @media #{$down768}  { font-size: 27px; }
    }

    h3 {
        font-size: 22px;
        line-height: 1.454545454545455em; // 32px / 22px
        color: $color1;
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 10px;

        @media #{$down1024} { font-size: 25px; }
    }

    p {
        font-size: 17px;
        line-height: 1.882352941176471em; // 32px / 17px
        color: $color1;
        font-weight: 500;
        margin: 10px 0;

        a{ margin: 0 !important; }

        @media #{$down600} { font-size: 16px; }
    }
    
    ul {
        list-style-type: none;
        
        & > li {
            position: relative;
            padding-left: 10px;
            font: 17px/1.5 $font;
            color: $color1;
            font-weight: 500;

            @media #{$down600} { font-size: 16px; }
            
            &:not(:last-child) { margin-bottom: 10px; }
            
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                top: 10px;
                left: 0;
                border-radius: 50%;
                background: $color3;
            }
            
            p {
                margin-bottom: 0;
                font: 17px/1.5 $font;
                color: $color1;

                @media #{$down600} { font-size: 16px; }
            }

            a{
                margin: 0;
            }
        }
    }
    
    ol {
        counter-reset: list;
        
        & > li {
            position: relative;
            padding-left: 28px;
            font: 17px/1.5 $font;
            color: $color1;
            font-weight: 500;

            @media #{$down600} { font-size: 16px; }
            
            &:not(:last-child) { margin-bottom: 10px; }
            
            &:before {
                counter-increment: list;
                content: counter(list) ". ";
                position: absolute;
                left: 0;
                color: $color3;
                font-weight: bold;
            }
            
            p {
                margin-bottom: 0;
                font: 17px/1.5 $font;
                color: $color1;
                
                @media #{$down600} { font-size: 16px; }
            }
        }

        ol{
            & > li {
                &:before {
                    counter-increment: list;
                    content: counter(list, lower-alpha) ". ";
                }
            }
        }
    }

    ul, ol {
        margin: 10px 0;
        li li {
            margin-top: 10px !important;
            margin-bottom: 0 !important;
        }
    }

    a:not(.btn):not(.link) {
        position: relative;
        font: 17px/1 $font;
        color: $color1;
        transition: color 300ms;
        font-weight: 700;

        &::before,
        &::after{
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            height: 3px;
            width: 100%;
            background-color: $color1;
            opacity: 0.3;
        }
        &::after{
            background-color: $color3;
            opacity: 1;
            width: 0;
            transition: width $ease 0.5s;
        }
        &:hover{
            &::after{
                width: 100%;  
            }
        }
    }

    img {
        max-width: 100%;
        width: 100%;
        margin: 30px 0 20px;
    }

    hr {
        margin-block-start: 0;
        margin-block-end: 0;
        border-bottom-width: 0;
    }

    pre{
        font-size: 13px;
        margin: 10px 0;
        font-weight: 500;
        white-space: pre-wrap;
    }
    table + pre{
        margin-top: -40px;
        margin-bottom: 60px;
    }


    blockquote, blockquote > p{
        font-size: 28px;
        line-height: 1.714285714285714em; //48px / 28px
        @media #{$down500} { font-size: 22px; }
        @media #{$down400} { font-size: 20px; padding-left: 25px; }
    }

    blockquote {
        padding-left: 45px;
        border-left: 2px rgba($color1,0.1) solid;
        position: relative;
        margin: 50px 0;
        &::after{
            content: '';
            background-color: $color3;
            width: 9px;
            height: 9px;
            position: absolute;
            top: 50%;
            left: -1px;
            transform: translate(-50%,-50%);
            border-radius: 50%;
        }
    }

    table {
        border: 1px solid;
        color: color1;
        margin: 60px 0;
        
        @media #{$down500} {
            border: 0;
            display: block;
            overflow-x: auto;
            white-space: nowrap;
        }

        thead {
            tr th,
            tr td {
                padding: 20px;
                color: #fff;
                background: $color1;     
                border: 1px solid #c7c9d0;
                font-weight: 700;
                font-size: 17px;
                text-align: center !important;
            }
        }

        tbody {
            tr td, tr th {
                padding: 20px;                 
                text-align: center !important;
                border: 1px solid #c7c9d0;
                vertical-align: middle !important;
                font-size: 15px;
                line-height: 1.5em;
                font-weight: 500;
                background:white;
            }

            tr td.secondary-header-1 {
                background: #606060;
                color: #fff;
            }
            tr td.secondary-header-2 {
                background: #e0dfdf;   
                color: $color1;
            }
            tr td.header {
                background: $color1; 
                color: #fff;
            }
        }
		
		@media #{$down1400} {
            thead tr th, thead tr td, tbody tr td, tbody tr th { padding: 20px 10px; font-size: 14px; }
        }
        
		@media #{$down1200} {
            thead tr th, thead tr td, tbody tr td, tbody tr th { padding: 15px 7px; font-size: 13px; }
		}
		
		@media #{$down600} {
            thead tr th, thead tr td, tbody tr td, tbody tr th { padding: 10px 5px; font-size: 12px; }
		}
    }

}