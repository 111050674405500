#overlay-mapDetail {
  
    position: absolute;
    width: 100%;
    max-width: 360px;
    height: 100%;
    max-height: calc(100vh - 200px);
    left: 360px;
    bottom: -50px;
    background: white;
    z-index: 50;
    opacity: 0;
    visibility: hidden;

    .closeOverlay{
        position: absolute;
        right: 20px;
        top: 13.5px;
        width: 50px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        cursor: pointer;
        transform: rotate(45deg);
        transition: background-color $ease 0.7s;
        

        &:hover{
            background-color: $color5
        }
        &::before,
        &::after{
            content: '';
            background: #fff;
            width: 17px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);       
        }
        &::after{
            width: 2px;
            height: 17px;
        }
    }

    @media screen and (max-height: 800px) { max-height: calc(100vh - 150px); }
    @media screen and (max-height: 700px) { max-height: calc(100vh - 125px); }
    @media screen and (max-height: 650px) { max-height: calc(100vh - 80px); }
    @media screen and (max-height: 550px) { max-height: calc(100vh - 60px); }
    @media screen and (max-width: 1024px) { left: 0px; }
    @media screen and (max-width: 768px)  { max-height: calc(100vh - 90px); }

    .image {
        height: 200px;

        @media screen and (max-width: 500px) { height: 175px; }
        @media screen and (max-width: 425px) { height: 150px; }
    }

    .bande {
        height: 75px;
        background-color: $color1;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #fff;

        @media screen and (max-width: 768px) { top: 10px; right: 10px; width: 36px; height: 36px; }

        &:hover {
            &:before, &:after { background: $color2; }
        }
        
        &:before, &:after { background: $color1; }
    }

    .text {
      
        padding: 50px 50px;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 200px);
        background: white;

        @media screen and (max-width: 500px) { height: calc(100% - 175px); }
        @media screen and (max-width: 425px) { height: calc(100% - 150px); }
        @media screen and (max-width: 1024px) { padding: 40px 50px; }
        @media screen and (max-width: 768px)  { padding: 30px 40px; }
        @media screen and (max-width: 600px)  { padding: 30px; }
        @media screen and (max-width: 500px)  { padding: 30px 20px; }
        @media screen and (max-width: 375px)  { padding: 20px 15px; }

        &::-webkit-scrollbar { display: none; }
        
        &.noImage {
          height: calc(100% - 75px);
          @media screen and (max-width: 500px) { height: calc(100% - 75px); }
          @media screen and (max-width: 425px) { height: calc(100% - 75px); }
        }

        h3 {
            font-size: 25px;
            font-weight: 500;
            font-size: 25px;
            line-height: 1.2;
        }

        .infos {
          
            margin-top: 25px;

            p, a {

              position: relative;
              padding-left: 30px;
              margin-bottom: 15px;
              font: 15px/1.2 $font;
              transition: color 300ms;
              font-weight: 500;

              svg {
                  position: absolute;
                  width: 18px;
                  height: 18px;
                  top: 1px;
                  left: 0;
                  stroke: $color5;
                  stroke-width: 3px;
                  fill: none;       
              } 
            }

            a {
                color: $color1;
                font-weight: 700;
                
                &::before,
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 30px;
                    height: 4px;
                    right: 0;
                    background-color: #bdbdbd;
                    opacity: 0.3;
                }
                &::after{
                    background-color: $color3;
                    opacity: 1;
                    right: 100%;
                    transition: right $ease 0.5s;
                }
                &:hover{
                    &::after{
                        right: 0;
                    }
                }
            }
        }
    }
    
    .desc {
      margin-top: 30px;
      p { font: 17px/1.4 $font; }
    }

    .relations {

      margin-top: 15px;

      h4 {
        margin-top: 20px;
        font: 16px/1.1 $font;
        font-weight: 600;
        margin-bottom: 10px
      }

      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;

        > li {
          margin-bottom: 5px;
          position: relative;
        }

        > li:before {
          display: inline-block;
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          top: 6px;
          left: -12px;
          border-radius: 50%;
          background: #e63735;
        }

        a {
          font: 15px/1.2 $font;
          transition: color 300ms;
          vertical-align: top;
          color: $color1;
          font-weight: 500;
          &:hover {
            color: $color3;
          }
        }
      }
    }
}

.showOverlayLocation{
    &.overlayClosing{
        #overlay-mapDetail{
            bottom: -50px;
            opacity: 0;
        }
    }

    #overlay-mapDetail {
        transition: opacity 700ms $ease, bottom 700ms $ease;
        opacity: 1;
        bottom: 0px;
        visibility: visible;
    }
}