.checkboxField {
	position: relative;
	label {
		display: inline-block;
		position: relative;
		padding-left: 40px;
		font: 17px/1.3 $font;
		color: $color1;
		cursor: pointer;
		user-select: none;
		font-weight: 500;

		@media #{$down1024} { padding-left: 30px; }
		@media #{$down500} { font-size: 15px; }

		input { position: absolute; opacity: 0; cursor: pointer; }

		span {
			position: absolute;
			width: 21px;
			height: 21px;
			top: 0;
			left: 0;
			border: 2px solid #bdbdbd;

			@media #{$down500} { width: 20px; height: 20px; }
			
			&:before, &:after {
				content: '';
				position: absolute;
				left: 50%;
				transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1), height 300ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 300ms;
			}

			&:before {
				width: 0px;
				height: 0px;
				top: 50%;
				background: $color3;
				transform: translate(-50%, -50%);
			}

			&:after {
				width: 5px;
				height: 10px;
				top: 45%;
				opacity: 0;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				transform: translate(-50%, -50%) rotate(45deg);
				transition-delay: 200ms;
			}
		}

		input:checked ~ span {
			&:before {
				width: 21px; height: 21px;

				@media #{$down500} { width: 21px; height: 21px; }
			}
			&:after  { opacity: 1; }
		}

		&.main{ //Label du groupe de champs, pas de chaques boites
			font-size: 19px;
			font-weight: 600;
			padding-left: 0;
			margin-bottom: 15px;
		}
	}

	.checkbox-field{
		margin-bottom: 15px;
	}
}
