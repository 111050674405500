///////////////////////
// LES CHOSEN SELECT //
///////////////////////

.chosen-container .chosen-results li.highlighted { color: $color3; }
.chosen-container-single .chosen-search { display: none; }
.chosen-disabled .chosen-single { cursor: default; }
.chosen-disabled { opacity: 0.35 !important; cursor: default; }

.chosen-container {
    position: relative;
    display: inline-block;
    width: 100% !important;
    font-size: 15px;
    vertical-align: middle;
    background: #fff;
    user-select: none;
    z-index: 50;

	.chosen-single {
		display: block;
		position: relative;
		width: 100%;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		z-index: 49;
		transition: background 300ms, color 300ms, border 300ms;

		span {
			display: block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		div {
			display: block;
			position: absolute;
			width: 40px;
			height: 100%;
			top: 0;
            right: 8px;
            transform: rotate(90deg);
			transition: transform 500ms;

			b { display: none; }
		}
	}

	.chosen-drop {
		position: absolute;
		width: 100%;
		top: 0;
		border: none;
		background: #fff;
		opacity: 0;
		visibility: hidden;
		z-index: 48;
		transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms;
	}

	&.chosen-with-drop .chosen-drop {
		top: 0;
		padding-top: 65px;
		visibility: visible;
		opacity: 1;
		transition: padding 500ms, opacity 500ms, box-shadow 500ms;
	}

	.chosen-results {
		position: relative;
		max-height: 240px;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		li {
			display: none;
			padding: 12px 30px;
			margin: 0;
			font: 17px/30px $font;
            letter-spacing: 1px;
			list-style: none;
			word-wrap: break-word;
			-webkit-touch-callout: none;
            transition: color 300ms;
            font-weight: 600;

			&:first-child { padding-top:    25px; }
            &:last-child  { padding-bottom: 25px; }

			&.active-result {
				display: list-item;
				cursor: pointer;
				color: $color1;
            }

			&.disabled-result {
				display: list-item;
				color: rgba($color1, 0.4);
				cursor: default;
            }

			&.result-selected {
				font-family: $font;
				cursor: default;
			}
		}
    }

    &.chosen-container-active {
        z-index: 50;

        &.chosen-with-drop .chosen-single div { transform: rotate(-90deg); }
    }
}

select {
    display: block;
    width: 100%;
    opacity: 0;

    &::-ms-expand { display: none; }

    &:focus, &:active {
        outline: none !important;
        border-color:  $color3;
    }
}



// Style 1
.select-field.style1 {
    margin-bottom: 45px;

	.chosen-container {
        background: transparent;

        .chosen-single,
        .chosen-default {
			padding: 0 45px 0 30px;
			font: 17px/66px $font;
			color: $color1;
            background: #fff;
            letter-spacing: 1px;
            font-weight: 600;

			div {
				background: url('../images/icons/arrowRed.svg') no-repeat center center;
				background-size: 6px auto;
                transition: transform 300ms;
			}
		}

        .chosen-results {
            li {
                &.active-result {
                    color: rgba($color4, 0.8);

                    &:hover { color: $color1; }
                }

                &.result-selected { color: $color1; }
            }
        }

        &.chosen-with-drop {
            .chosen-single{
                color: white;
                background-color: $color1;
            }
        }
    }


    // Select natif
    select {
        display: block;
        position: relative;
        height: 66px;
        padding: 0 45px 0 30px;
        font: 17px/66px $font;
        color: $color1;
        opacity: 1;
        background: #fff;
        text-overflow: ellipsis;
        letter-spacing: 1px;
        white-space: nowrap;
        appearance: none;
        overflow: hidden;
        outline: none;
        border: none;
        font-weight: 600;
        visibility: visible;
        background-image: url('../images/icons/arrowRedBottom.svg');
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 10px;
    }

    &.gray{
        .chosen-container{
            .chosen-single{
                background-color: $color2;
            }
            .chosen-drop{
                background-color: $color2;
            }

            &.chosen-with-drop{
                .chosen-single{
                    background-color: $color1;
                }
            }
        }
    }
}

.selectOverlay{
    transition: opacity 0.7s ease, visibility 0s ease 0.7s;
    z-index: 103;
}
.show-filterModal{
    .chosen-container.chosen-container-active{
        z-index: 300;
    }
    .selectOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease;
    }
}
