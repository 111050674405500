.overlayDirectory {
    position: absolute;
    width: 100%;
    max-width: 360px;
    height: 100%;
    max-height: calc(100vh - 200px);
    right: 0;
    background: white;
    z-index: 1150;
    opacity: 0;
    top: 50%;
    transform: translate(0, -50%);
    visibility: hidden;

    .closeOverlay{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 67px;
        height: 67px;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        transform: rotate(45deg);
        transition: background-color $ease 0.7s;
        

        &:hover{
            background-color: rgba(255,255,255,0.8)
        }
        &::before,
        &::after{
            content: '';
            background: $color3;
            width: 17px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);       
        }
        &::after{
            width: 2px;
            height: 17px;
        }
    }

    @media screen and (max-height: 800px) { max-height: calc(100vh - 150px); }
    @media screen and (max-height: 700px) { max-height: 100vh; }

    .image {
        height: 200px;

        @media screen and (max-width: 500px) { height: 175px; }
        @media screen and (max-width: 425px) { height: 150px; }
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #fff;

        @media screen and (max-width: 768px) { top: 10px; right: 10px; width: 36px; height: 36px; }

        &:hover {
            &:before, &:after { background: $color2; }
        }
        
        &:before, &:after { background: $color1; }
    }

    .text {
        padding: 35px 55px;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 200px);
        background: white;

        @media screen and (max-width: 500px) { height: calc(100% - 175px); }
        @media screen and (max-width: 425px) { height: calc(100% - 150px); }

        @media screen and (max-width: 1024px) { padding: 40px 50px; }
        @media screen and (max-width: 768px)  { padding: 30px 40px; }
        @media screen and (max-width: 600px)  { padding: 30px; }
        @media screen and (max-width: 500px)  { padding: 30px 20px; }
        @media screen and (max-width: 375px)  { padding: 20px 15px; }

        &::-webkit-scrollbar { display: none; }

        h3{
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 35px;
        }

        .infos {
            margin-bottom: 30px;

            p, a {
                position: relative;
                padding-left: 30px;
                margin-bottom: 10px;
                font: 17px /1.5 $font;
                color: $color1;
                transition: color 300ms;
                font-weight: 500;

                svg {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 1px;
                    left: 0;
                    fill: none;
                    stroke: $color1;
                    stroke-width: 2px;
                    transition: stroke 300ms;
                }
                &.note{
                    padding-left: 0;
                    margin-bottom: 30px;
                }
            }

            a{
                &::before,
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 30px;
                    height: 4px;
                    right: 0;
                    background-color: #bdbdbd;
                    opacity: 0.3;
                }
                &::after{
                    background-color: $color3;
                    opacity: 1;
                    right: 100%;
                    transition: right $ease 0.5s;
                }
                &:hover{
                    &::after{
                        right: 0;
                    }
                }
            }
        }

        .list {
            color: $color1;
            h4 {
                margin-bottom: 10px;
                font-weight: 600;
            }
            li {
                font-weight: 500;
                margin-top: 10px;
            }
        }
    }
}

.showOverlayDirectory{
    &.overlayClosing{
        .overlayDirectory{
            bottom: -50px;
            opacity: 0;
        }
    }

    .overlayDirectory {
        transition: opacity 700ms $ease, bottom 700ms $ease;
        opacity: 1;
        bottom: 0px;
        visibility: visible;
    }

    &.overlayClosing .directoryOverlay{
        opacity: 0;
    }
    .directoryOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }
}