.share{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px;
    span{
        color: $color4;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        padding-right: 20px;
        transition: color 0.7s $ease;
    }
    svg{
        width: 17px;
        height: 17px;
        fill: $color1;
    }
    &:hover{
        span{
            color: $color1;
        }
    }

    &.dark{
        svg{
            fill: white;
        }
        &:hover{
            span{
                color: white;
            }
        }
    }
}