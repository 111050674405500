//------------------\\
//--- Scrollfire ---\\
//------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce CSS a besoin du plugin BLK_scrollfire pour fonctionner, le css est appliquer sur les attribut data
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$scrollfire: '[data-scrollfire], [data-multiscrollfire]';
$fire: '&[data-scrollfire="fire"], &[data-multiscrollfire="fire"]';

// LES ANIMATIONS __________
// Fade
[data-fade] {
    opacity: 0;
    transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);

    #{$fire} { opacity: 1; }
}

// Fade up
[data-fadeup] {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);

    @media #{$down1024} { transform: translateY(50px); }
    @media #{$down768}  { transform: translateY(30px); }

    #{$fire} { opacity: 1; transform: none; }
}



// LES DÉLAIS PENDANT LES CHARGEMENTS ET TRANSITIONS __________
.transition, .chargement {
    [data-fade] { opacity: 0 !important; }

    [data-fadeup] {
        opacity: 0 !important; transform: translateY(100px) !important;

        @media #{$down1024} { transform: translateY(50px) !important; }
        @media #{$down768}  { transform: translateY(30px) !important; }
    }

    #{$scrollfire} {
        transition-delay: 100ms !important;
    }
}

.transition {
    section:not(.bannerBlock), footer {
        opacity: 0 !important;
        transform: translateY(100px) !important;
        transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);

        @media #{$down1024} { transform: translateY(50px); }
        @media #{$down768}  { transform: translateY(30px); }
    }
}


// LES DÉLAIS APRÈS LES CHARGEMENTS ET TRANSITIONS __________
#{$scrollfire} {
	transition-delay: 0ms;
    &[data-delay1] { transition-delay: 300ms; }
    &[data-delay2] { transition-delay: 400ms; }
    &[data-delay3] { transition-delay: 500ms; }
    &[data-delay4] { transition-delay: 600ms; }
    &[data-delay5] { transition-delay: 700ms; }
    &[data-delay6] { transition-delay: 800ms; }
    &[data-delay7] { transition-delay: 900ms; }

    @media #{$down768} { transition-delay: 0ms !important; }
}
