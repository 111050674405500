.content{
    overflow: hidden;
}

.pageTop{
    background-color: $color1;
    height: calc(100vh - #{$headerHeight});
    min-height: 600px;
    transition: height $ease 0.7s;
    z-index: 3;
    position: relative;
    @media #{$down900}  { height: auto; }

    .share{
        position: absolute;
        right: calc(1% + 35px );
        top: 15px;
        z-index: 3;
        @media #{$down1200} { top: 12px; }
        @media #{$down900}  { top: 75px; }
        @media #{$down768}  { display:none; }
    }

    .toggleContainer{
        position: absolute;
        right: 50px;
        bottom: 50px;
        z-index: 3;
    }

    .topLeft{
        position: relative;
        height: 100%;
        position: relative;
        z-index: 2;
        h1{
            color: white;
            min-height: 310px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @media #{$down1400} { min-height: 280px; }
            @media #{$down1024} { min-height: 210px; }
            @media #{$down900}  {
                padding-top: 120px;
                position: relative;
                height: 650px;
            }
            @media #{$down768} { height: 550px; }
            @media #{$down600} { height: 500px; }
            @media #{$down500} { height: 450px; }
            .headerImage{
                overflow: hidden;
                width: 100%;
                left: 0;
                right: 0;
                bottom: -60px;
                top: 305px;
                position: absolute;
                z-index: -1;
                margin-top: -30px;

                @media #{$down1400}{top: 268px;}
                @media #{$down1024}{top: 210px;}
                @media #{$down900}{
                    position: absolute;
                    top: 250px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: auto;
                }
                @media #{$down768} { top: 234px; }
                @media #{$down600} { top: 217px; }
                @media #{$down500} { top: 208px; }

                &:after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    left: 0;
                    right: 0;
                    top: 0;
                    height: 60%;
                    pointer-events: none;
                    background: linear-gradient(to bottom, rgba($color1,0.7) 0%,rgba($color1,0) 100%);
                }

                .image{
                    position: absolute;
                    width: 100%;
                    top: -10%;
                    bottom: -10%;
                    left: 0;
                    right: 0;
                    @media #{$down1300} { top:0; bottom:0; }
                }

                p {
                    display: inline-block;
                    position: absolute;
                    padding: 5px 10px;
                    right: 0;
                    bottom: 0;
                    background: rgba(#26282e, 0.7);
                    font: 13px/1 $font;
                    color: #fff;
                }
            }
        }
    }
    .topRight{
        position: relative;
        .intro{
            position: absolute;
            color: white;
            bottom: 0;
            left: 0;
            padding: 115px 100px;
            font-size: 17px;
            line-height: 2.117647058823529em; // 36px/17px
            max-width: 525px;
            font-weight: 500;
            @media #{$down900} {
                position: static;
                max-width: none;
                padding: 60px 50px;
            }
            @media #{$down768} {
                padding: 50px 30px;
            }
            @media #{$down768}  { padding: 50px 30px; }
            @media #{$down500}  { padding: 45px 20px; }
        }
    }

    &.noBanner{
        height: auto;
        min-height: auto;
        .noBannerPageTop{
            position: relative;
            @media #{$down1024}  { padding-top: $breadcrumbsHeight; }
            h1{
                color: white;
                max-width: 70%;
            }
            .intro{
                color: white;
                padding-top: 30px;
                font-size: 17px;
                line-height: 2.117647058823529em; // 36px/17px
                max-width: 800px;
            }
        }
    }

    &.noIntro{
        min-height: auto;
    }
}

.showAlertBanner{ // Ajuster la hauteur si le bandeau d'alertes est affiché
    .pageTop{
        height: calc(100vh - #{$alertHeight} - #{$headerHeight});
        @media #{$down900}   { height: auto; }

        &.noBanner{
            height: auto;
        }
    }
}

.grayBg{
    background-color: $color2;
}

.contentPattern{
    width: 40%;
    height: 180px;
    position: absolute;
    top: -60px;
    right: 10%;
    background-image: url(../images/content/patterns/dots.png);
    z-index: 2;

    @media #{$down900} {
        width: 75%;
        height: 50px;
        top: 0;
    }
}

.grid.noBanner{
    .contentPattern{
        height: 135px;
        @media #{$down900} {
            width: 75%;
            height: 50px;
            top: 0;
        }
    }
}

.main{
    .wrapper{
        max-width: 1000px;
    }
}

.rubricsWrapper{
    h2{
        margin-bottom: 30px;
    }
    .dynamic{
        h2{
            margin-top: 50px;
            margin-bottom: 20px;
            font-size: 25px;
            font-weight: 700;
        }
        h3{
            margin-top: 20px;
            margin-bottom: 15px;
            font-size: 20px;
            font-weight: 700;
        }
        /*p,ul,li{
            margin: 16px 0;
        }*/

    }
}
