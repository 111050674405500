// Le conteneur des documents
.documents li:not(:last-child) { margin-bottom: 20px; }

// Document normal
.document {
    display: block;
    position: relative;

    .contentWrapper{
        background: #fff;
        padding: 40px 120px 40px 70px;
        position: relative;
        z-index: 1;
        @media #{$down1024} { padding: 40px 100px 40px 30px; }
        @media #{$down768}  { padding: 30px 100px 30px 20px; }
        @media #{$down600}  { padding: 20px 50px 20px 20px; }
        
        &-flex {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $color1;
            h6:first-child {
                transition: padding-left cubic-bezier(0.19, 1, 0.22, 1) 0.5s, color $ease 0.7s;
            }
        }
        
        h6{ color: $color4; }
        h6.red { color: $color3 }
        h5{
            transition: padding-left cubic-bezier(0.19, 1, 0.22, 1) 0.5s, color $ease 0.7s;
        }
    }

    .iconContainer{
        position: absolute;
        top: 50%;
        right: 45px;
        height: 50px;
        width: 50px;
        transform: translate(0,-50%);
        z-index: 1;

        @media #{$down1024} { right: 30px; }
        @media #{$down600} { width: 30px; height: 30px; right: 10px; } 
    
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 18px;
            stroke: $color3;
            transform: translate(-50%,-50%);
            transition: stroke $ease 0.3s, transform $ease 0.3s;
            z-index: 2;

            @media #{$down600} { width: 15px; height: 15px; }

            use { transition: fill $ease 0.3s, transform $ease 0.3s; }
        }

        &::after{
            content: '';
            position: absolute;
            height: 50px;
            width: 50px;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%,-50%) scale(1);
            background-color: $color2;
            transition: all 0.6s $ease;
            @media #{$down600} { width: 30px; height: 30px; } 
        }
    }


    // Document plus 
    &.plus {
        margin-top: 152px;
        @media #{$down600} { margin-top: 0; }

        &.noThumb{
            margin-top: 0;
        }

        .imageWrapper {
            position: absolute;
            width: 208px;
            height: 208px;
            overflow: hidden;
            border-radius: 50%;
            left: 0;
            top: 0;
            z-index: 0;
            margin-left: -60px;
            margin-top: -122px;

            @media #{$down600} { display: none; }

            .image {
                position: absolute;
                top: -20px;
                right: 0;
                bottom: -20px;
                left: 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba($color1, 0);
                    transition: background 300ms;
                }
            }
        }
    }

    &::after{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        background-color: $color3;
        z-index: 1;
        transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
    }

    &:hover {
        .contentWrapper{
            &-flex h6:first-child {
                padding-left: 20px;
            }
            h5{
                padding-left: 20px;
            }
        }
        .iconContainer{
            svg{
                stroke: white;
            }
            &::after{
                background-color: $color3;
                transform: translate(-50%,-50%) scale(1.2);
            }
        }
        &:after{
            width: 15px;
        }
    }

    &.inRubric {
        .contentWrapper{
            background-color: $color2;
            padding: 30px 120px 30px 40px;
            @media #{$down1024} { padding: 30px 120px 30px 30px; }
            @media #{$down600}  { padding: 30px 70px 30px 20px;  }
            h5 {
                color: $color1;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .iconContainer{
            z-index: 2;
            &::after{
                background-color: white;
            }
        }

        &::after{
            width: 0;
            background-color: rgba(0,0,0,0.04);
            transition: width $ease 0.5s;
        }

        &:hover {
            .contentWrapper{
                h5,h6{
                    padding-left: 0;
                }
            }

            .iconContainer{
                svg{
                    stroke: $color3;
                }
            }

            &::after{
                width: 100%;
            }
        }
    }
}

.collapsed {
    padding: 0!important;
    
    .document::after {
        display: none;
    }

    ul > li {
        border-top: solid $color4 1px;
        &:first-child {
            border-top: none;
        }
    } 
    .collapsedImage {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 120px;
        background-size: cover;
        background-position: center;
        z-index: 1;

        @media #{$down1024} { width: 110px; }
        @media #{$down768} { width: 90px; }
        @media #{$down600} { display: none; }
    }
    .contentWrapper {
        padding-left: 130px;
        padding-right: 210px;
        @media #{$down1024} { padding-left: 100px; padding-right: 180px; }
        @media #{$down768}  { padding-left: 80px; padding-right: 170px; }
        @media #{$down600}  { padding-right: 150px; }
    }
    .iconContainer {
        right: 130px;
        @media #{$down1024} { right: 100px; }
        @media #{$down768} { right: 80px; }
    }

    //documents+
    .collapsedPlus {
        .contentWrapper{
            padding-left: 170px;
            @media #{$down1024} { padding-left: 140px; }
            @media #{$down768}  { padding-left: 120px; }
            @media #{$down600}  { padding-left: 80px; }
        }
    }
}