.newsBack{
    padding-bottom: 15px;
    a{
        color: $color3;
        font-size: 15px;
        font-weight: 600;
        padding-left: 30px;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            width: 6px;
            height: 10px;
            top: 50%;
            left: 0;
            background: url(../images/icons/arrowRed.svg) no-repeat center/contain;
            transform: translateY(-50%) rotate(180deg);
            transition: right 300ms;
            transition: left $ease 0.7s;
        }

        &:hover{
            &::after{
                left: -10px;
            }
        }
    }
}

.newsWhiteBg{
    background-color: white;
}

.newsDetails{
    color: $color1;

    .topInfos{
        padding-bottom: 20px;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        .categ{
            color: $color4;
        }
    }

    h1{
        max-width: 70%;
        line-height: 1.04em; //78px/75px
        margin-bottom: 50px;
    }

    .imageContainer{
        width: 100%;
        padding-top: 53.76344086021505%; //ratio de 500px / 930px
        position: relative;
        overflow: hidden;
        .image{
            position: absolute;
            left: 0;
            right: 0;
            top: -10%;
            bottom: -10%;
        }

        p {
            display: inline-block;
            position: absolute;
            padding: 5px 10px;
            right: 0;
            bottom: 0;
            background: rgba(#26282e, 0.7);
            font: 13px/1 $font;
            color: #fff;
        }
    }

    .dynamic{
        max-width: 900px;
        margin: 0 auto;
    }
}
