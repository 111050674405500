.fastLinksToggle{
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;
    background: white;
    border-radius: 50%;
    transition: opacity 300ms, visibility 0ms ease 300ms, background 300ms, transform 300ms $ease;
    cursor: pointer;

    &:hover{
        background-color: $color1;
    }

    &::before,
    &::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        background: $color3;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 17px;
    }
    &::before{
        width: 17px;
        height: 2px;
    }

    &.redHover{
        &:hover{
            background-color: $color3;
            &::before,
            &::after{
                background: white;
            }
        }
    }
}