#overlaySurvey{

    .wrapper {

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
        max-height: 80%;
        padding: 90px;
        background: #fff;
        text-align: center;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        width: 700px;
        transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1), transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 0;

        @media screen and (max-width: 1024px) { padding: 75px 30px; }
        @media screen and (max-width: 768px)  { width: 500px; padding: 60px 20px 40px; }
        @media screen and (max-width: 600px) {
            width: auto;
            left: 20px;
            right: 20px;
            transform: translateY(-50%);
        }

        &::-webkit-scrollbar { display: none; }

        .close {

            position: absolute;
            width: 60px;
            height: 60px;
            top: 0;
            right: 0;
            cursor: pointer;

            div {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 60px;
                height: 60px;
                transform: translate(-50%, -50%);
                z-index: 1;
                opacity: 0.8;
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    background: $color3;
                    top: 50%;
                    left: 50%;
                    width: 20px;
                    height: 2px;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:after{
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }

            &:hover{
                div {
                    opacity: 1;
                }
            }
        }

        .sondageContent {

            h4 {
                margin: 0 0 40px 0;
                font-size: 30px;
                @media #{$down768}
                {
                    font-size: 25px;
                }
                @media #{$down768}
                {
                    margin-bottom: 30px;
                    font-size: 22px;
                }
                @media #{$down500}
                {
                    font-size: 20px;
                }
            }
            h6 {
                color: $color4;
                margin: 0 0 20px 0;
            }

            .submitButton {
                cursor: pointer;
            }

            div.note {

                margin: 40px 0 0 0 !important;
                @media #{$down768} 	{
                    margin: 30px 0 0 0 !important;
                }
                p,
                p a {
                    font-size: 14px !important;
                    line-height: 1.5em !important;
                }

                a:not(.btn):not(.link)::before, a:not(.btn):not(.link)::after {
                    height: 2px;
                    bottom: 0px;
                }
            }

            .xMargin {
                margin-bottom: 30px;
            }

            sup.required_dot {
                color: $color3;
                padding: 2px 0;
                font-weight: bold;
            }
        }

        .sondageConfirm {

            h3 {
                margin: 0 0 50px 0;
                @media #{$down1400}                    {
                    margin-bottom: 40px;
                }
                @media #{$down1100}                    {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.showOverlaySurvey-active{

    &.overlayClosing #overlaySurvey .wrapper {
        opacity: 0;
    }

    #overlaySurvey .wrapper{
        visibility: visible;
        opacity: 1;
        transition: all 1s $ease;
    }

    &.overlayClosing .popupOverlay{
        opacity: 0;
    }

    .popupOverlay{
        z-index: 360;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease;
    }
}

//Les Styles de questions pour sondages
.sondageFormStyle {

    max-width: 800px;
    margin: 0;

    fieldset {
        margin: 0 0 40px 0 !important;
        @media #{$down768} 	{ margin: 0 0 30px 0 !important; }
    }

    .input-checkbox {
        position: relative;
        display: inline-block;
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 1;
            margin: 0;
            border: none;
            outline: none; -webkit-appearance: none; -moz-appearance: none; border-radius: 0; -webkit-tap-highlight-color: rgba(0,0,0,0);
            &:disabled {
                cursor: default;
            }
            &:disabled + .check {
                background: grey !important;
                border-color: grey;
            }
            &:checked + .check {
                font-size: 12px;
                background: red;
                .icon {
                    opacity: 1;
                    @media #{$down768} {
                        width: 1em;
                        height: 1em;
                        vertical-align: 1px;
                    }
                }
            }
        }
        .check {
            width: 22px;
            height: 22px;
            border: solid 2px red;
            line-height: 20px;
            text-align: center;
            border-radius: 2px;
            transition: background 250ms;
            @media #{$down600} {
                width: 18px;
                height: 18px;
            }
            .icon {
                transition: all 300ms;
                width: 1.3em; height: 1.3em;
                vertical-align: -0.25em;
                fill: none;
                overflow: visible;
                stroke: #fff;
                stroke-width: 1.5px;
                opacity: 0;
            }
        }
    }

    .ratyStarsWrapper {

        text-align: center;

        .ratyStars {
            display: inline-block;

            img {
                margin-right: 10px;
                height: 40px;
                @media #{$down768} {
                    height: 26px;
                }

                &:last-of-type {
                    margin: 0;
                }
            }
        }

        &.range7 {
            .ratyStars {
                img {
                    @media only screen and (max-width : 360px) {
                        margin-right: 5px;
                    }
                }
            }
        }

        &.range9 {
            .ratyStars {
                img {
                    @media #{$down600} {
                        margin-right: 5px;
                    }

                    @media #{$down500} {
                        margin-right: 2px;
                    }

                    @media only screen and (max-width : 360px) {
                        height: 20px;
                    }
                }
            }
        }
    }

    .checkBoxListWrapper {

        position: relative;
        display: inline-block;
        min-width: 300px;

        @media only screen and (max-width : 380px) {
            min-width: auto;
            width: 100%;
        }

        > div.error {
            padding: 0;
            top: -25px;
            left: 0;
        }

        .input-field {
            display: none;
            padding: 0;
            margin: 20px 0 0 0;

            label{
                left: 0;
            }

            .error {
                left: 0;
            }
        }

        .checkBoxList {

            li {

                margin-bottom: 20px;

                &.wThb {

                    padding-left: 140px;

                    @media only screen and (max-width : 380px) {
                        padding-left: 120px;
                    }

                    .input-checkbox {

                        input {
                            width: 140px;
                            height: 80px;
                        }

                        .check {
                            top: 29px;
                        }
                    }

                    label {
                        min-height: 80px;
                        padding-top: 29px;

                        img {
                            display: block;
                        }
                    }

                    img {
                        position: absolute;
                        left: 40px;
                        top: 0;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;

                        @media only screen and (max-width : 380px) {
                            left: 30px;
                        }
                    }

                    @media #{$down600} {

                        .input-checkbox {

                            input {
                                width: 140px;
                                height: 80px;
                                @media #{$down600} {
                                    width: 125px;
                                }
                            }

                            .check {
                                top: 31px;
                            }
                        }

                        label {
                            min-height: 80px;
                            padding-top: 31px;
                        }
                    }
                }
            }
        }

        .qtsGalerie {

            @media only screen and (max-width : 400px) {
                text-align: center;
            }

            li {
                display: inline-block;
                width: auto;
                margin: 0 5px 10px 5px;
                max-height: 152px;

                input {
                    position: absolute;
                    width: 0;
                    height: 0;

                    &[type="radio"]:checked + label {
                        cursor: default;
                    }

                    &:checked + label {

                        img {
                            border: solid 2px $color3;
                        }

                        span {
                            opacity: 0.3;
                        }
                    }
                }

                label {

                    display: block;
                    position: relative;
                    max-height: 152px;
                    cursor: pointer;

                    img {
                        border: solid 2px transparent;
                        max-width: 100%;
                    }

                    span {
                        position: absolute;
                        top:0;
                        left:0;
                        width: 100%;
                        height: 100%;
                        background: $color3;
                        opacity: 0;
                        transition: opacity 200ms;
                    }
                }

                &:hover {

                    span {
                        opacity: 0.2;
                    }
                }
            }
        }
    }

    .checkBoxList {

        text-align: left;

        li {
            position: relative;
            display: block;
            padding-left: 50px;
            cursor: pointer;
            @media #{$down600} {
                padding-left: 35px;
            }

            &:last-child {
                margin: 0;
            }

            &::after {
                display: table;
                content: '';
                clear: both;
            }

            &:hover .input-checkbox .check {
                background-color: rgba($color3, 0.5);
            }

            .input-checkbox {

                position: absolute;
                left: 0;
                top: 0;

                input {
                    width: 50px;
                    height: 22px;
                    @media #{$down600} {
                        width: 40px;
                        height: 18px;
                    }
                }

                .check {
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-color: $color3;
                }

                input:checked + .check {
                    background: $color3;
                }

                &.radio {

                    .check {
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        @media #{$down600} {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    input:checked + .check {
                        &::after {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 8px;
                            height: 8px;
                            margin: -4px 0 0 -4px;
                            content: '';
                            background: #fff;
                            border-radius: 50%;
                        }
                    }
                }
            }

            label {
                float: none;
                display: block;
                font-size: 18px;
                line-height: 22px;
                cursor: pointer;
                @media #{$down600} {
                    font-size: 16px;
                    line-height: 18px;
                }

                img {
                    display: none;
                }
            }

        }
    }

    .linkertsWrapper {

        > ul {
            display: table;
            width: 100%;
        }

        li {
            box-sizing: border-box;
            padding: 0 !important;
        }

        .subjectRow {

            display: table-row;
            width: 100%;
            font: 15px/22px Gilroy;
            font-weight: 400;

            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.02)
            }

            > div {
                display: table-cell;
                border-bottom: solid 1px #e5e5e5;
                padding: 20px 10px;
            }

            .subject {
                padding: 20px 0 20px 10px;
                width: 50%;
                text-align: left;
            }

            .steps
            {
                position: relative;
                width: 50%;
                vertical-align: middle;

                li {
                    float: left;
                    padding: 0;
                    height: 22px;
                    text-align: center;
                    cursor: default;

                    .input-checkbox {

                        position: relative;
                        display: inline-block;
                        height: 100%;
                        width: 100%;

                        input {
                            position: static;
                        }

                        .check {
                            left: 50%;
                            margin-left: -11px;
                        }
                    }

                    &::after {
                        content: none;
                    }
                }

                .step5 li { width: 20% }
                .step4 li { width: 25% }
                .step3 li { width: 33.3% }

                .error {
                    top: 2px;
                    padding: 0;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    font: 12px/12px Gilroy;
                    font-weight: 400;
                }
            }

            &::after {
                display: table;
                content: '';
                clear: both;
            }

            @media #{$down600} 	{

                display: block;

                > div {

                    display: block;
                }

                .subject {
                    width: 100%;
                    padding: 20px 10px 0 10px;
                    border-bottom: none;
                }

                .steps {
                    width: 100%;
                    padding: 20px 10px;
                    height: 62px;

                    ul {

                        &::after {
                            display: table;
                            content: '';
                            clear: both;
                        }
                    }
                }
            }
        }

        .headerRow {

            .subject {

                // On retire le padding car on a pas de légende à gauche.
                padding: 0;

                font: 14px/20px Gilroy;
                font-weight: 600;

                span {
                    margin: 0 20px 0 0;
                    white-space: nowrap;

                    label {
                        display: inline-block;
                        width: 10px;
                    }
                }
            }

            .steps {

                padding-top: 10px;
                vertical-align: bottom;

                .legend {

                    text-align: center;
                    font: 14px/20px Gilroy;
                    font-weight: 600;
                    margin: 0 0 20px 0;

                    span {
                        margin: 0 10px;
                        white-space: nowrap;

                        label {
                            display: inline-block;
                            width: 10px;
                        }
                    }
                }

                > div {
                    border-top: none;
                }

                li {
                    height: auto;
                    font: 14px/20px Gilroy;
                    font-weight: 600;
                }
            }

            @media #{$down600} 	{

                .subject {
                    text-align: center;
                    padding-bottom: 0;

                    span {
                        margin: 0 10px;
                    }
                }

                .steps {
                    text-align: center;
                    height: auto;
                    padding: 10px;
                }
            }
        }

        @media #{$down600} 	{
            display: block;
        }
    }

    .booleanPush {

        position: relative;
        max-width: 700px;
        text-align: center;
        display: -webkit-flex;
        display: flex;           /* establish flex container */
        justify-items: center;
        justify-content: center;
        /*flex-direction: column;  make main axis vertical */
        align-items: stretch;     /* center items horizontally, in this case */
        flex-wrap: wrap;

        .flexrow {
        }

        div.error {
            left: 0;
            top: -25px;
            padding: 0;
            width: 100%;
        }

        button {
            padding: 10px 20px;
            width: 200px;
            background: $color7;
            cursor: pointer;
            border-radius: 20px;
            border: none;

            &:hover {
                background: $color1;
                color: white;
            }

            @media only screen and (max-width : 500px) {
                display: block;
                max-width: none;
                width: 100%;
            }
        }

        button.checked {
            color: #fff;
            cursor: default;
            background: $color3;
        }

        button:first-child {

            margin: 0 20px 0 0;

            @media only screen and (max-width : 500px) {
                margin: 0 0 20px 0;
            }
        }
    }

    .radioRange {

        position: relative;
        max-width: 700px;
        padding: 0 0 0 11px;

        div.error {
            left: 0;
            top: -25px;
            padding: 0;
            width: 100%;
            text-align: center;
        }


        label {

            &.leftLabel {
                span {
                    float: left;
                    margin-left: -11px;
                }
            }

            &.rightLabel {
                span {
                    float: right;
                    margin-right: -11px;
                }
            }
        }

        .checkBoxList {

            position: relative;
            margin: 0;

            &::after {
                display: table;
                content: '';
                clear: both;
            }

            li {
                position: relative;
                float: left;
                width: 10%;
                padding: 0 20px;

                @media #{$down600} {
                    padding: 0 10px;
                }

                .input-checkbox {

                    right: 0;
                    input { width: 100%; }
                    .check {
                        left: 50%;
                        margin-left: -11px;
                    }
                }

                label {
                    width: 100%;
                    margin-top: 40px;
                    text-align: center;
                    font-size: 15px;
                    line-height: 1.3;
                    @media #{$down600} 	{ font-size: 14px; }
                }
            }
        }

        &.range2 {

            padding: 0;

            .checkBoxList {

                li {
                    margin: 0;
                    width: 50%;
                }
            }
        }
        &.range3 {

            max-width: 500px;

            .checkBoxList {

                li {
                    width: 48%;
                    padding: 0;
                    margin: 0;
                    height: 20px;

                    &:first-child {
                        width: 24%;
                        .check {
                            left: 0;
                        }
                    }

                    &:last-child {
                        width: 24%;
                        .check {
                            left: 100%;
                        }
                    }
                }
            }

            .labelRow {
                width: 96%;
            }
        }
        &.range5 {
            .checkBoxList {
                li {
                    width: 24%;
                    padding: 0;
                    margin: 0;
                    height: 20px;

                    &:first-child {
                        width: 12%;
                        .check {
                            left: 0;
                        }
                    }

                    &:last-child {
                        width: 12%;
                        .check {
                            left: 100%;
                        }
                    }
                }
            }

            .labelRow {
                width: 96%;
            }
        }
        &.range7 {

            .checkBoxList {
                li {
                    width: 16%;
                    padding: 0;
                    margin: 0;
                    height: 20px;

                    &:first-child {
                        width: 8%;
                        .check {
                            left: 0;
                        }
                    }

                    &:last-child {
                        width: 8%;
                        .check {
                            left: 100%;
                        }
                    }
                }
            }

            .labelRow {
                width: 96%;
            }
        }
        &.range9 {

            .checkBoxList {

                li {
                    width: 12%;
                    padding: 0;
                    margin: 0;
                    height: 20px;

                    &:first-child {
                        width: 6%;
                        .check {
                            left: 0;
                        }
                    }

                    &:last-child {
                        width: 6%;
                        .check {
                            left: 100%;
                        }
                    }
                }
            }

            .labelRow {
                width: 96%;
            }
        }
    }

    .labelRow {

        display: table;
        font-size: 14px;
        line-height: 20px;
        color: $color1;
        margin-top: 12px;
        width: 100%;
        @media #{$down600} 	{ font-size: 12px; }

        label {
            vertical-align: top;
            display: table-cell;

            &.leftLabel {
                width: 34%;
                text-align: left;
                padding-right: 20px;
            }

            &.centerLabel {
                width: 32%;
                text-align: center;
            }

            &.rightLabel {
                width: 34%;
                padding-left: 20px;
                text-align: right;
            }

            @media only screen and (max-width : 380px) {
                max-width: 40%;
                &.centerLabel { display: none; }
            }
        }
    }

    .rangeSlider {
        .rangeSlider__fill {
            background: $color3;
        }
    }

    .textfield {

        padding: 0;
        margin: 0 !important;

        label{
            left: 0;
            line-height: 45px;
        }

        div.error {
            left: 0;
        }

        textarea {
            line-height: 1.3;
            min-height: 45px;
        }
    }

    .submitButton {

        margin: 0;
        min-width: 120px;
    }

    .leftAlign {

        .checkBoxListWrapper {
            display: block;
            min-width: auto;
        }

        .ratyStarsWrapper {
            text-align: left;
        }

        .booleanPush {
            text-align: left;
            justify-content: flex-start;
        }

        .radioRange {

            div.error {
                text-align: left;
            }

            &.range2 {

                .checkBoxList {
                    li {
                        padding: 0 40px 0 0;

                        @media #{$down600} {
                            padding: 0 20px 0 0;
                        }

                        label {
                            text-align: left;
                        }
                    }
                }

                .input-checkbox {
                    .check {
                        left: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
