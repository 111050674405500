.eventsMain{
    .eventsLeft{
        @media #{$down1024}{
            order: 2;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 80px;
        }
        @media #{$down600}{
            padding-bottom: 0;
        }
        .eventsWrapper{

            border-right: 2px solid rgba($color1,0.1);
            @media #{$down768} { border-right: 0; }

            .event{
                display: block;
                position: relative;
                padding-right: 50px;
                @media #{$down768} { padding-right: 0; }

                &:not(:last-child){
                    margin-bottom: 130px;
                    @media #{$down600} { margin-bottom: 30px; }

                }

                .eventImage{
                    width: 100%;
                    height: 455px;
                    overflow: hidden;
                    position: relative;
                    z-index: 2;

                    @media #{$down768} {
                        height: auto;
                        padding-top: 60%; // ratio de 450px / 750px
                    }

                    .image{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        transition: transform 0.7s $ease; 
                    }
                }
                .eventInfos{
                    background-color: $color1;
                    position: absolute;
                    left: 80px;
                    right: 130px;// 80px + 50px
                    bottom: -80px;
                    padding: 50px 75px;
                    color: white;
                    z-index: 2;

                    @media #{$down1400} { left: 50px; right: 100px; }
                    @media #{$down1400} { right: 50px; }
                    @media #{$down1024} { padding: 45px 60px; }
                    @media #{$down768}  { padding: 30px 30px; }
                    @media #{$down600}  {
                        position: relative;
                        left: auto;
                        right: auto;
                        bottom: auto;
                    }
    
                    .date{
                        display: flex;
                        flex-wrap: wrap;
                        background-color: white;
                        border-radius: 50%;
                        width: 120px;
                        height: 120px;
                        position: absolute;
                        top: 0;
                        right: 30px;
                        transform: translate(50%, -50%);
                        color: $color1;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;

                        @media #{$down600} { right: 50px }
    
                        span{
                            font-size: 35px;
                            font-weight: 700;
                            display: block;
                            text-align: center;
                            width: 100%;
                            padding-bottom: 5px;
                        }
                    }
    
                    .categ, .dateText{
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 600;
                        padding-bottom: 20px;
                        color: $color4;
                    }

                    .dateText {
                        color: #fff;
                    }

                    .metas {
                        padding-bottom: 20px;
                    }
    
                    .title{
                        font-size: 28px;
                        line-height: 1.25em; // 35px / 28px
                        font-weight: 500;
                    }
    
                    .infos{
                        margin-top: 50px;
                        div{
                            padding-left: 30px;
                            margin-bottom: 12px;
                            position: relative;
                            font-size: 14px;
                            font-weight: 600;
                            svg{
                                fill: $color3;
                                width: 12px;
                                height: 16px;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translate(0, -50%);
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }

                    .dateTextuelle {
                        svg {
                            fill: none !important;
                            stroke: $color3;
                            stroke-width: 3.5px;
                        }
                    }
                }
                &:hover{
                    .image{
                        transform: scale(1.1);
                    }
                }

                &::after{
                    content: '';
                    background-color: $color3;
                    position: absolute;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    pointer-events: none;
                    top: 50%;
                    right: 0;
                    transform: translate(calc(50% + 1px ),-50%);
                    @media #{$down768} { display: none; }
                }

                .pattern{
                    position: absolute;
                    width: 180px;
                    height: 93%;
                    top: -40px;
                    left: -45px;
                    background-image: url(../images/content/patterns/dots.png);
                    z-index: 1;
                    pointer-events: none;
                }
            }

            .btnDate {
                margin-right: 50px;
                padding: 14px 54px 9px 35px;

                @media screen and (max-width: 1024px) {
                    margin-top: -7px;
                }
                @media screen and (max-width: 600px) {
                    padding: 14px 40px 9px 20px;
                }
            }
        }
    }

    .eventsRight{
        padding-left: calc( 50px - 1% );

        @media #{$down1024}{
            order: 1;
            padding-left: 0;
        }

        .max400{
            max-width: 400px;
        }

        .filters{
            margin-top: 20px;
            position: relative;
            @media #{$down1024}{
                margin-bottom: 50px;
                max-width: 100%;
                padding-right: 100px;
            }
            @media #{$down768}{
                padding-right: 0;
                padding-top: 85px;
            }
            .select-field{
                margin-bottom: 0;
            }

            .calendarBtn{
                height: 66px;
                width: 66px;
                background-color: white;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                transition: background-color $ease 0.7s;
                z-index: 50;
                cursor: pointer;
                svg{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    fill: $color3;
                    width: 20px;
                    height: 20px;
                    transition: fill $ease 0.7s;
                }
                &:hover{
                    background-color: $color1;
                    svg{
                        fill: white;
                    }
                }
                @media #{$down768}{
                    top: 0;
                    left: 0;
                }
            }
        }

        .toggleContainer{
            margin-top: 20px;
        }
    }
}


@media #{$down900} {
    #page-events,
    #page-event{
        .share{
            display: none;
        }
    }
}