#overlay-share {
    position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	overflow: hidden;
	visibility: hidden;
	z-index: 2001;
    pointer-events: none;
    transition: opacity 0.7s $ease, visibility 0ms ease 700ms;

	.wrapper {
        position: fixed;
        padding: 90px 85px;
        top: 50%;
        left: 50%;
		background: #fff;
        pointer-events: all;
        text-align: center;
        z-index: 100;
        opacity: 0;
        transform: translate(-50%, 0%);

        @media #{$down1024} { padding: 50px; }
        @media #{$down600} {
            width: auto;
            right: 20px;
            left: 20px;
            padding: 50px 20px;
            transform: translateY(-50%);
        }

        h3 { margin-bottom: 32px; }

        .close {
            position: absolute;
            width: 60px;
            height: 60px;
            top: -40px;
            right: -40px;
            transform: translate(50%,-50%);
            cursor: pointer;
            background-color: white;
            transition: transform 0.3s $ease;
            border-radius: 50%;
            @media #{$down768} { top: -50px; right: 30px; }

            .plus1,
            .plus2{
                position: absolute;
                width: 20px;
                height: 2px;
                top: 50%;
                left: 50%;
                background: $color3;
                transition: transform 0.7s $ease ;
            }
            .plus1{ transform: translate(-50%, -50%) rotate(45deg) scale(1); }
            .plus2{ transform: translate(-50%, -50%) rotate(-45deg) scale(1); }

            &:hover  {
                transform: translate(50%,-50%) scale(1.16); // 70px / 60px
                .plus1 { transform: translate(-50%, -50%) rotate(45deg) scale(0.84); }
                .plus2 { transform: translate(-50%, -50%) rotate(-45deg) scale(0.84); }
            }
        }

        ul {
            position: relative;
            margin-top: 16px;

            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 20px;

                    @media #{$down600} { margin-right: 10px; }
                }

                a {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    background: $color2;
                    border-radius: 50%;
                    transition: background $ease 0.3s, transform $ease 0.3s;

                    svg {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 50%;
                        left: 50%;
                        fill: $color1;
                        transform: translate(-50%, -50%);
                        transition: fill $ease 0.3s, transform $ease 0.3s;
                    }

                    &:hover {
                        background: $color3;
                        transform: scale(1.2);
                        svg{
                            fill: white;
                            transform: translate(-50%, -50%) scale(0.8);
                        }
                    }
                }
            }
        }
	}
}


.showOverlayShare{

    &.overlayClosing #overlay-share{
        opacity: 0;
        transition: opacity 0.7s ease, visibility 0ms;
        .wrapper {
            opacity: 0;
            transition: opacity 0.7s ease, visibility 0ms;
        }
    }

    #overlay-share {
        overflow-y: auto;
        visibility: visible;
        transition: opacity 0.7s ease, visibility 0ms;
        &::-webkit-scrollbar { display: none; }

        .wrapper {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
            transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms;
    
            @media #{$down600} { transform: translateY(-50%); }
    
            .close {
                &:before, &:after { width: 18px; transition: width 500ms $ease 200ms, background 300ms; }
            }
        }
    }




    &.overlayClosing .shareOverlay{
        opacity: 0;
    }
    .shareOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }
}
