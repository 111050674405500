.backToTop{
    position: relative;
    height: 0;
    div{
        position: absolute;
        transform: rotate(-90deg);
        bottom: 90px;
        right: 25px;
        font-weight: 700;
        cursor: pointer;
        color: $color1;
        font-size: 12px;
        text-transform: uppercase;
        padding-right: 8px;
        svg{
            width: 10px;
            height: 6px;
            fill: $color3;
            transform: rotate(-90deg) translate(1px ,8px);
            transition: transform $ease 0.7s;
        }

        &:hover{
            svg{
                transform: rotate(-90deg) translate(1px ,15px); 
            }
        }
    }
}

.prefooter{
    position: relative;
    overflow: hidden;
    background-color: $color1;
    .text{
        position: relative;
        z-index: 2;
        p{
            color: white;
            font-size: 75px;
            line-height: 1.04em; // 78px / 75px
            font-weight: bold;
            margin-bottom: 50px;
            @media #{$down1100} { font-size: 60px; }
            @media #{$down1024} { font-size: 50px; line-height: 1.244444444444444em; }
            @media #{$down768}  { font-size: 40px; }
            @media #{$down400}  { font-size: 33px; }
        }
    }
    .image{
        background-image: url(../images/content/footer/citizenAlert.jpg);
        position: absolute;
        top: -50px;
        right: 0;
        bottom: -50px;
        left: 0;
        z-index: 1;
        opacity: 0.4;
    }
}

footer{
    background-color: white;
    position: relative;
    .contact{
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $color1;
        width: 30%;
        max-width: 470px;
        z-index: 3;
        @media #{$down1400} { padding: 60px; }
        @media #{$down1300} {
            position: static;
            width: 100%;
            max-width: none;
            img{ max-width: 300px; }
        }
        @media #{$down600} {
            padding: 40px;
            img{ max-width: 200px; }
        }

        p{
            color: white;
            line-height: 32.76px;
            margin-top: 30px;
            font-weight: 500;
            font-size: 19px;
            @media #{$down600} { font-size: 16px; }
            &.big{
                margin-top: 65px;
                font-size: 25px;
                font-weight: bold;
                @media #{$down1300} { margin-top: 30px; }
                @media #{$down600} { font-size: 21px; }
            }
            a.link{
                position: relative;
                color: white;
                &::before,
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 3px;
                    width: 100%;
                    background-color: $color2;
                    opacity: 0.3;
                }
                &::after{
                    background-color: $color3;
                    opacity: 1;
                    width: 0;
                    transition: width $ease 0.5s;
                }
                &:hover{
                    &::after{
                        width: 100%;  
                    }
                }
            }
        }
    }
    .footerTop{
        border-bottom: 1px solid #d9d9d9;
        height: 70px;
        position: relative;
        .overline{
            .socialFooter{
                background-color: white;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: absolute;
                bottom: 0px;
                transform: translate(0,50%);
                padding: 0 30px;
                margin-left: -30px;
                font-weight: 500;
                letter-spacing: 0.02em;
                @media #{$down600} { justify-content: center; width: 100%; }
                .lienSEAO{
                    margin-right: 30px;
                    @media #{$down600} { margin-right: 0; }
                    img{
                        width: 98px;
                        height: 29px;
                    }
                }
                ul.medias{
                    display: flex;
                    margin-left: 18px;
                    @media #{$down600} {
                        margin-left: 0;
                        margin-top: 20px;
                        width: 100%;
                        justify-content: center;
                    }
                    li{
                        padding-left: 18px;
                        a{
                            transition: opacity 0.3s;
                            svg{
                                fill: $color1;
                                width: 17px;
                                height: 16px;
                            }
                            &:hover{
                                opacity: 0.7;
                            }
                        }
                        &:first-child{
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
    .copyrights{
        padding-top: 65px;
        padding-bottom: 30px;
        color: #838383;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.02em;
        flex-wrap: wrap;

        a{
            color: #838383;
            margin-left: 60px;
        }

        @media #{$down600} {
            justify-content: center;
            a,span{
                margin: 0;
                width: 100%;
                display: block;
                text-align: center;
            }
            a{
                margin-top: 15px;
            }
        }
    }
}