// FORMULAIRE __________
// Form
form {
	display: block; 
	width: 100%;
}

// Error
div.error {
	position: absolute;
	padding-top: 7px;
	top: 100%;
	font: 12px/1 $font;
	color: $color3;
	font-weight: 500;
}

.inputField, .selectField, .checkboxField, .radioField, .fileField, .textField {
	position: relative;
	width: 100%;
	margin-bottom: 45px;
	font-weight: 500;

	.hook {
		position: absolute;
		top: 50%;
		right: 30px;
		width: 12px;
		height: 6px;
		border-bottom: 1px solid $color3;
		border-left: 1px solid $color3;
		opacity: 0;
		transform: rotate(-45deg) translateY(-50%) scale(0);
		transition: transform 300ms, opacity 300ms;
	}

	&:first-child{
		margin-top: 45px;
	}

	input,textarea{
		&.error{
			border-bottom-color: $color3 !important;
		}
	}
}


// Composantes formulaires --------------------------------------------------------
section.contextualForm{
	&>div{
		background: white;

	}
}

section.contextualForm,
section.rubriqueForm{
	.formulaire_confirmation{
		position: absolute;
		top: -30px;
	}

	.formulaire_confirmation_message{
		display: none;
	}

	.form-alert{
		padding: 16px 30px;
		button.close{ display: none; }
		&.form-alert-success{ background-color: #bdf3c1; }
		&:not(:last-child){ margin-bottom: 40px; }
	}

	button{
		cursor: pointer;
	}
}