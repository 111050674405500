.homeSlider{
    position: relative;
    @media #{$down768} { padding-right: 0 }

    .homeSlides{
        &.slick-dotted{
        margin-bottom: 0;
        }
        .slide{
            height: calc(100vh - #{$headerHeight});
            min-height: 580px;
            padding-bottom: 200px;
            display: flex !important;
            align-items: center;
            transition: height $ease 0.7s 0.3s;
            @media #{$down1400} { min-height: 450px; }
            @media #{$down1300} { padding-bottom: 180px;}
            @media #{$down768}  { padding-bottom: 133px; padding-left: 0;}
            @media #{$down600}  { height: calc(100vh - #{$headerHeight} - 170px); padding-bottom: 0; }

            @media #{$down768}{
                .lP200{
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
            @media #{$down500}{
                .lP200{
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .titre{
                color: white;
                font-size: 75px;
                font-weight: 700;
                text-shadow: 0px 3px 12px rgba(0,0,0,0.2);
                @media #{$down1100} { font-size: 60px; }
                @media #{$down1024} { font-size: 50px; line-height: 1.244444444444444em; }
                @media #{$down768}  { font-size: 40px; }
            }

            .btn{
                margin-top: 50px;
            }

            .image{
                position: absolute;
                top: -50px;
                right: 0;
                bottom: -50px;
                left: 0;
                z-index: -1;

                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-image: linear-gradient(to bottom, rgba($color1,0.4), rgba($color1,0));
                }
            }

            &::after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba($color1,0.2);
                z-index: -1;
            }
        }

        .slick-dots{
            position: absolute;
            top: 50%;
            left: 0;
            bottom: auto;
            right: auto;
            z-index: 100;
            width: 38px;
            margin-left: 80px;
            padding-bottom: 250px;
            transform: translateY(-50%);
            @media #{$down1400} { padding-bottom: 180px; }
            @media #{$down1200} { margin-left: 60px; }
            @media #{$down1024} { margin-left: 30px; }
            @media #{$down768}  { display: none !important; }
            li{
                display: inline-block;
                height: auto;
                width: auto;
                margin: 0;
                button{
                    position: relative;
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                    background-color: transparent;
                    padding: 0;
                    &::before{ //Cercle intérieur
                        content: '';
                        background-color: white;
                        border-radius: 50%;
                        height: 7px;
                        width: 7px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        opacity: 1;
                        transition: all $ease 0.7s;
                    }
                    &::after{ // Cercle extérieur
                        content: '';
                        position: absolute;
                        height: 4px;
                        width: 4px;
                        left: 50%;
                        top: 50%;
                        border: 1px solid white;
                        display: block;
                        border-radius: 50%;
                        transform: translate(-50%,-50%);
                        transition: all $ease 0.7s;
                    }
                }
                &.slick-active{
                    button{
                        &::before{
                            height: 10px;
                            width: 10px;
                        }
                        &::after{
                            height: 100%;
                            width: 100%;
                            border: 1px solid white;
                        }
                    }
                }
            }
        }
    }

    .searchboxContainer{
        position: absolute;
        bottom: 75px;
        left: 0;
        width: 100%;
        @media #{$down768} { bottom: 30px; max-width: none; padding-right: 30px; }
        @media #{$down600} {
            position: static;
            padding: 0;
        }

        .searchbox{
            background-color: $color1;
            width: 85%;
            max-width: 960px;
            display: flex;
            padding: 33px 50px;
            @media #{$down1300} { padding: 24px 30px; }
            @media #{$down768}  { width: 100%; }
            @media #{$down500}  { padding: 24px 15px; }
            .magnifierIcon{
                width: 55px;
                height: 55px;
                background-color: rgba(0,0,0,0.3);
                border-radius: 50%;
                position: relative;

                @media #{$down500} {
                    width: 45px;
                    height: 45px;
                    top: 5px;
                }

                svg{
                    fill: white;
                    height: 21px;
                    width: 21px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            .searchInput{
                flex-grow: 1;
                padding: 0 30px;
                position: relative;
                @media #{$down768} { padding-right: 0; }
                @media #{$down500} { padding: 0; }

                label,
                input{
                    font-family: $font;
                }

                label{
                    position: absolute;
                    top: 0;
                    left: 30px;
                    font-size: 28px;
                    color: #fff;
                    cursor: text;
                    transition: opacity 300ms;
                    line-height: 55px;
                    @media #{$down768} { font-size: 25px; }
                    @media #{$down500} { font-size: 21px; left: 15px; }
                }

                input{
                    border: 0;
                    background-color: transparent;
                    font-size: 28px;
                    color: #fff;
                    height: 55px;
                    line-height: 55px;
                    width: 100%;
                    @media #{$down768} { font-size: 25px; }
                    @media #{$down500} { font-size: 21px; padding-left: 15px; }
                }

                input.notEmpty + label,
                input:focus + label,
                input.valid + label,
                input.error + label {
                    opacity: 0;
                    cursor: default;
                    pointer-events: none;
                }
            }

            @media #{$down1300} {
                .btn.white{
                    padding: 20px 54px 18px 35px;
                }
            }

            #submitSearchMobile{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 57px;
                height: 57px;
                background-color: white;
                border-radius: 50%;
                transition: all 0.3s;
                svg{
                    fill: $color1;
                    width: 20px;
                    height: 20px;
                }
                &:hover{
                    background-color: $color3;
                    svg{
                        fill: white;
                    }
                }
            }
        }
    }

    .homeFastLinks{
        .fastLinks{
            top: calc(8px + $parkingCardHeight);
            bottom: auto;
            position: absolute;
            opacity: 1;
            visibility: visible;
            z-index: 1;
            width: 350px;
            box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.08);

            @media #{$down1500} {
                top: auto;
                bottom: 0;
                transform: none;
            }

            .closeFastLinksOverlay{
                display: none;
            }

            &.hidePanel{
                width: 0;
            }

            @media only screen and (max-height: 800px) {
                ul{
                    li {
                        padding-top: 8px;

                        a{
                            padding-left: 65px;
                            .iconContainer{
                                width: 45px;
                                height: 45px;
                                svg{
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-height: 750px) {
                ul{
                    padding: 20px 45px;

                    li {
                        padding-top: 4px;
                    }
                }
            }
        }
    }

    .arrowDown{
        position: absolute;
        width: 115px;
        height: 110px;
        right: 0;
        bottom: 0;
        @media #{$down1200} { width: 90px; }
        @media #{$down1024} { width: 50px; }

        .arrowContainer{
            width: 9px;
            height: 110px;
            position: absolute;
            cursor: pointer;
            left: 50%;
            bottom: 15px;
            transform: translate( -50%, 0);

            svg{
                fill: none;
                stroke: $color3;
                width: 9px;
                height: 90px;

                animation-name: floating;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;

                &:hover{
                    animation-play-state: paused;
                }
            }
        }

        @keyframes floating {
            0% { transform: translateY(0); }
            30% { transform: translateY(-15%); }
            100% { transform: translateY(0); }
        }
    }
}

.showAlertBanner{ // Ajuster la hauteur si le bandeau d'alertes est affiché
    .homeSlider{
        .homeSlides{
            .slide{
                height: calc(100vh - #{$alertHeight} - #{$headerHeight});
                @media #{$down600}   { height: calc(100vh - #{$alertHeight} - #{$headerHeight} - 170px); }
            }
        }
    }
}

.eventsSlider{
    background-color: $color2;
    position: relative;

    .topSection{
        align-items: center;
        h2{
            font-size: 75px;
            color: $color1;
            font-weight: 700;
            @media #{$down1100} { font-size: 60px; }
            @media #{$down1024} { font-size: 50px; }
            @media #{$down768}  { font-size: 40px; }
            @media #{$down400}  { font-size: 33px; }
        }
        p{
            font-size: 25px;
            @media #{$down768}  { font-size: 19px; }
        }

        @media #{$down1024} {
            .allEventsBtn{
                padding-top: 30px;
                text-align: left;
            }
        }
    }

    .bottomSection{
        position: relative;
        @media #{$down768} { padding-left: 0; padding-right: 0; }

        .eventsInfosSlides{
            position: absolute;
            width: 100%;
            max-width: 360px;
            margin-left: 100px;
            bottom: -70px;
            z-index: 50;
            background-color: $color1;
            @media #{$down1300} { margin-left: 30px; }
            @media #{$down1024} { bottom: 0; }
            @media #{$down768} {
                margin-left: 0;
                position: relative;
                bottom: auto;
                max-width: none;
            }

            .slick-list{
                overflow: visible;
            }
            .slick-track{
                pointer-events: none;
            }

        }

        .eventsImagesSlides{
            position: relative;
            z-index: 2;
            .slick-list{
                padding-right: 250px;
                @media #{$down1200} { padding-right: 130px; }
                @media #{$down1024} { padding-right: 60px; }
                @media #{$down768}  { padding-right: 0; }
            }
            .slide{
                position: relative;
                height: 620px; // 500px + 70px + 50px : Hauteur de l'image + espace pour le bloc sous l'image + 50px pour la transition vers le haut
                padding-right: 50px;
                @media #{$down768} {
                    height: auto;
                    padding-right: 0;
                }

                .image{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 50px;
                    bottom: 120px; //70px + 50px
                    overflow: hidden;

                    @media #{$down768} {
                        width: 100%;
                        position: relative;
                        padding-top: calc(60% + 60px );
                        margin-bottom: -60px;
                    }

                    .scale{
                        position: absolute;
                        height: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transition: transform 0.7s $ease;
                        background-size: cover;
                        background-position: center;
                    }
                }

                .box{
                    position: absolute;
                    background-color: $color1;
                    color: white;
                    padding: 80px 60px;
                    pointer-events: all;
                    bottom: 50px;
                    width: 360px;
                    left: 100px;
                    transform: translateY(50px);
                    opacity: 0;
                    transition: opacity 300ms linear 1s, transform 300ms $ease 1s;
                    @media #{$down1400}  { left: 50px; }
                    @media #{$down1024}  { padding: 45px 60px; }
                    @media #{$down768}   {
                        position: static;
                        width: 100%;
                        padding: 30px 30px;
                        //transform: translateY(0);
                        //opacity: 1;
                    }

                    .date{
                        display: flex;
                        flex-wrap: wrap;
                        background-color: white;
                        border-radius: 50%;
                        width: 120px;
                        height: 120px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(50%, -50%);
                        color: $color1;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        @media #{$down768}  { right: 80px; }

                        span{
                            font-size: 35px;
                            font-weight: 700;
                            display: block;
                            text-align: center;
                            width: 100%;
                            padding-bottom: 5px;
                        }
                    }

                    .categ{
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 600;
                        padding-bottom: 20px;
                        color: $color4;
                    }

                    .title{
                        font-size: 28px;
                        line-height: 1.25em; // 35px / 28px
                        font-weight: 500;
                    }

                    .infos{
                        margin-top: 50px;
                        div{
                            padding-left: 30px;
                            margin-bottom: 12px;
                            position: relative;
                            font-size: 14px;
                            font-weight: 600;
                            svg{
                                fill: $color3;
                                width: 12px;
                                height: 16px;
                                position: absolute;
                                top: 0.4em;
                                left: 0;
                                transform: translate(0, -50%);
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }

                }


                &.slick-active{
                    .box{
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                &.slick-current:hover{
                    .image{
                        .scale{
                            transform: scale(1.1);
                        }
                    }
                }
            }

        }

        .eventsImagesSlidesWrapper{
            position: relative;
            .pattern{
                position: absolute;
                left: -53px;
                bottom: 65px;
                width: 300px;
                height: 500px;
                background-image: url(../images/content/patterns/dots.png);
                z-index: 0;

                @media #{$down768} {
                    bottom: auto;
                    top: -30px;
                }
            }
        }

        .eventsNavigation{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 300px;
            padding-left: 610px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;


            @media #{$down1400} {
                padding-left: 530px;
            }

            @media #{$down1200} {
                padding-left: 500px;
                padding-right: 180px;
            }

            @media #{$down1024} {
                padding-left: 460px;
                padding-right: 0;
             }

            @media #{$down768} {
                position: static;
                padding-left: 0;
            }

            .pagerContainer{
                padding-top: 35px;
                padding-bottom: 70px;
                position: relative;
                z-index: 2;
                width: 100%;
                align-items: center;
                justify-content: center;
                @media #{$down768} {
                    padding-bottom: 0;
                }
                .pager{
                    font-weight: bold;
                    font-size: 12px;
                }

                .nav{
                    padding-left: 30px;
                    padding-right: 0;

                    .prev,
                    .next{
                        display: inline-block;
                        padding: 0 15px;
                        cursor: pointer;
                        svg{
                            fill: none;
                            stroke: $color3;
                            width: 88px;
                            height: 9px;
                            transition: transform $ease 0.6s;
                            @media #{$down768} { width: 50px; }
                        }
                        &:hover{
                            svg{
                            transform: translateX(10px);
                            }
                        }
                    }

                    .prev{
                        svg{
                            transform: translateX(0) rotate(180deg);
                        }
                        &:hover{
                            svg{
                            transform: translateX(-10px) rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        &.singleSlide{
            .eventsImagesSlides{
                .slide{
                    padding-right: 0;
                    .image{
                        right: 0;
                    }
                }
                .slick-list{
                    padding-right: 0;
                }
            }
            .eventsNavigation{
                display: none;
            }
        }
    }

    &:before{
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 275px;
        height: 100%;
        background: white;
        z-index: 0;

        @media #{$down1200} { width: 155px; }
        @media #{$down1024} { width: 85px; }
        @media #{$down768}  { display: none; }
    }
}

.mapCta{
    background-color: $color1;
    position: relative;
    @media #{$down1300} { padding-right: 0 }
    @media #{$down768} { padding-right: 30px }
    @media #{$down500} { padding-right: 20px }
    h2{
        font-size: 45px;
        line-height: 1.244444444444444em; //56px / 45px
        color: white;
        font-weight: 700;
        max-width: 550px;
        margin-bottom: 50px;
        padding-right: 30px;
        @media #{$down1100} { font-size: 60px; }
        @media #{$down1024} { font-size: 50px; }
        @media #{$down768}  { font-size: 40px; padding-right: 0; margin-left: auto; margin-right: auto;}
        @media #{$down400}  { font-size: 33px; }
    }
    .mapText{
        position: relative;
        z-index: 1;
        @media #{$down768}  { text-align: center; }
    }
    .mapImage{
        position: relative;
        z-index: 5;

        @media #{$down768} {
            width: 100%;
            padding-top: 75%;
        }

        .imageContainer{
            position: absolute;
            background-color: $color1;
            top: -50px;
            bottom: -50px;
            left: 0;
            right: 0;
            overflow: hidden;
            @media #{$down1024} { top: -25px; bottom: -25px; }
            @media #{$down768} { top: 0; bottom: -50px; }
            .mapIcon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 6;
                background-color: $color3;
                padding: 40px;
                border-radius: 50%;
                box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);
                animation: pulse 2s infinite 2s;

                @media #{$down1024} { padding: 30px; }
                @media #{$down768} { padding: 20px; }

                svg{
                    width: 80px;
                    height: 78px;
                    stroke: white;
                    position: relative;
                    top: 5px;
                    @media #{$down1024} { width: 70px; height: 70px; }
                    @media #{$down768} { width: 60px; height: 60px; }
                }
            }
            .dots{
                width: 900px;
                height: 615px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
                @media #{$down1024} { width: 600px; height:410px; }
                @media #{$down768}  { width: 768px; height:519px; }
                @media #{$down500}  { width: 500px; height:341px; }
                .dot{
                    z-index: 10;
                    position: absolute;
                    width: 44px;
                    height: 44px;
                    background: #fff;
                    border-radius: 50%;
                    box-shadow: 0 0 0 rgba(255, 255, 255, 0.2);
                    transform: translate(-50%,-50%);

                    &:nth-child(1){ left: 29%; top: 65%; animation: pulse 2s infinite 0.5s; }
                    &:nth-child(2){ left: 61%; top: 80%; animation: pulse 2s infinite 1s; }
                    &:nth-child(3){ left: 71%; top: 45%; animation: pulse 2s infinite 1.5s; }

                    svg{
                        position: absolute;
                        fill: $color3;
                        width: 14px;
                        height: 19px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }

                    @keyframes pulse {
                        0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
                        70% { box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); }
                        100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); }
                    }
                }
            }
            .lines{
                width: 900px;
                height: 615px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 5;
                @media #{$down1024} { width: 600px; height:410px; }
                @media #{$down768}  { width: 768px; height:519px; }
                @media #{$down500}  { width: 500px; height:341px; }

                path{
                    stroke: white;
                    stroke-width: 1.5px;
                    fill: none;
                    stroke-dasharray: 8;
                    //stroke-dasharray: 1000;
                    //stroke-dashoffset: 1000;
                    //animation: dash 5s linear forwards;
                    animation: dash 60s linear infinite;
                    stroke-dashoffset: 1000;

                    @keyframes dash {
                        to {
                            stroke-dashoffset: 0;
                        }
                    }
                }
            }
            .image{
                position: absolute;
                background-image: url(../images/content/home/mapCTA.jpg);
                opacity: 0.75;
                top: -20px;
                bottom: -20px;
                left: 0;
                right: 0;
            }
        }
    }

    .decoration{
        height: 100%;
        width: 100%;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        &:after{
            content: "";
            position: absolute;
            background-color: rgba(255, 255, 255, 0.05);
            width: 155%;
            height: 325%;
            border-radius: 50%;
            top: 0;
            right: -70%;
            z-index: 0;
        }
    }
}


.homeNews{
    .topSection{
        position: relative;
        overflow: hidden;
        @media #{$down1024} { padding-bottom: 140px; }
        @media #{$down800} { padding-bottom: 90px; }
        @media #{$down768} { padding-top: 120px; }

        h2{
            font-size: 75px;
            color: #3f3f3f;
            font-weight: 700;
            @media #{$down1100} { font-size: 60px; }
            @media #{$down1024} { font-size: 50px; line-height: 1.244444444444444em; }
            @media #{$down768}  { font-size: 40px; }
            @media #{$down400}  { font-size: 33px; }
        }
        p{
            font-size: 25px;
            @media #{$down768}  { font-size: 19px; }
        }
        .pattern{
            position: absolute;
            right: 0;
            top: -60px;
            width: 41.66667%; // 5/12
            height: 190px;
            background-image: url(../images/content/patterns/dots.png);
            @media #{$down800} { height: 90px; }
        }
    }
    .bottomSection{
        background-color: $color2;

        //Pour styles de bases pour la liste des nouvelles, voir fichier 'news.scss'
        .newsList{

            .news{
                .infos{
                    min-height: 256px;
                    @media #{$down800} { min-height: auto; }
                }
            }

            .column:nth-child(1),
            .column:nth-child(3){
                transform: translateY(-60px);
                @media #{$down800} { transform: translateY(0); }
            }
            .column:nth-child(2){
                transform: translateY(-155px);
                @media #{$down1400} { transform: translateY(-100px); }
                @media #{$down800} { transform: translateY(-90px); }
                @media #{$down800} { transform: translateY(0); }
            }

            .column:nth-child(1){
                @media #{$down800} { margin-top: -60px; }
                position: relative;
                &:after{
                    content: '';
                    background-image: url(../images/content/patterns/dots.png);
                    position: absolute;
                    left: -40px;
                    bottom: -30px;
                    width: 185px;
                    height: 60%;
                    z-index: -1;
                    background-position: 0 0;
                }
            }
        }

        .btnNews{
            margin-top: -110px;
            @media #{$down1400} { margin-top: -100px; }
            @media #{$down800} { margin-top: 0; padding-top: 50px; }
        }
    }
}

.newsletterCta{
    background-color: $color2;
    .imageBlock{
        position: relative;
        &::after{
            content: '';
            background-image: url(../images/content/patterns/dots.png);
            position: absolute;
            left: -40px;
            bottom: 40px;
            width: 100%;
            height: 60%;
            z-index: 0;
            background-position: 0 0;
        }
        .imageContainer{
            position: absolute;
            background-color: #3f3f3f;
            top: 0;
            bottom: 75px;
            left: 0;
            right: 0;
            overflow: hidden;
            z-index: 2;
            .image{
                position: absolute;
                background-image: url(../images/content/home/ctaNewsletter.jpg);
                //opacity: 0.75;
                top: -30px;
                bottom: -30px;
                left: 0;
                right: 0;
            }
        }
    }

    h2{
        font-size: 75px;
        color: #3f3f3f;
        font-weight: 700;
        @media #{$down1100} { font-size: 60px; }
        @media #{$down1024} { font-size: 50px; line-height: 1.244444444444444em; }
        @media #{$down768}  { font-size: 40px; }
        @media #{$down400}  { font-size: 33px; }
    }
    p{
        font-size: 25px;
        @media #{$down768}  { font-size: 19px; }
    }
    .formContainer{
        background-color: white;
        margin-left: -250px;
        z-index: 2;
        position: relative;
        @media #{$down800} { margin-left: -345px; }
        @media #{$down768} { margin-left: 0; }
        form{
            .inputField:first-child{
                margin-top: 0;
            }
        }
    }
    #infolettreWrapper{
        h3{
            padding-bottom: 10px;
        }
    }
}
