.documentsBlock {
    position: relative;

    .select-field{
        position: absolute;
        top: -8px;
        right: 0;
        width: 350px;
        @media #{$down900} {
            position: relative;
            top: 0;
            width: 100%;
            margin-bottom: 0;
        }
    }

    .documentsWrapper {
        & > div {
            margin-top: 80px;

            @media #{$down768} { margin-top: 50px; }

            &:first-child { margin-top: 25px; }

            h3 {
                margin-bottom: 50px;

                @media #{$down1024} { margin-bottom: 40px; }
                @media #{$down768}  { margin-bottom: 30px; }
            }

            p {
                margin-bottom: 50px;
            }
        }
    }

    @media #{$down900} { 
        margin-top: 60px;
        &:first-child{ margin-top: 0; }
    }
}

.noDocuments{
    p{
        font-size: 17px;
        line-height: 1.882352941176471em;
        color: #3f3f3f;
        font-weight: 500;
        margin: 15px 0;
    }
    .btnContainer{
        margin-top: 60px;
        margin-bottom: 0;
    }
}