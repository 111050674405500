//Pour les styles de bases du haut de page, voir styles de la page générique

#page-contact{
    .pageTop{
        min-height: 750px;
        .topLeft{
            h1{
                @media #{$down900}  { height: auto; }
                span{
                    display: block;
                    color: white;

                    &.h2{
                        margin-top: 60px;
                        font-size: 28px;
                    }
                    &.h3{
                        margin-top: 20px;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 1.5em;
                    }
                }
                .headerImage{
                    bottom: 0;
                    margin-top: 0;
                    top: auto;
                    height: 250px;
                }
            }
        }
        .topRight{
            .intro{
                max-width: 780px;

                // Aligner ce titre avec celui de la section de gauche
                top: 0;
                padding: 150px 80px 60px;
                @media #{$down1400} { padding: 120px 80px 60px; }
                @media #{$down1024} { padding: 80px 60px 60px; }
                @media #{$down900}  { padding: 60px 50px; }
                @media #{$down768}  { padding: 50px 30px; }
                @media #{$down500}  { padding: 45px 20px; }

                h4{
                    font-weight: 600;
                    opacity: 0.5;
                    margin-bottom: 20px;
                    text-transform: uppercase;

                    // Aligner ce titre avec celui de la section de gauche
                    margin-top: calc(60px + calc(75px * 1.066666666666667));
                    @media #{$down1300} { margin-top: calc(60px + calc(70px * 1.066666666666667)); }
                    @media #{$down1024} { margin-top: calc(60px + calc(60px * 1.066666666666667)); }
                    @media #{$down900}  { margin-top: 0; }
                }

                h3{
                    font-size: 22px;
                    color: $color1;
                    margin-top: 35px;
                    small{
                        font-size: 0.7727272727272727em; //17px/22px
                    }
                    &:first-of-type{ margin-top: 0; }
                }

                p{
                    color: $color1;
                    font-size: 17px;
                    line-height: 1.588235294117647em; // 25px / 17px
                    margin: 16px 0;
                    font-weight: 500;

                    &:first-child{ margin-top: 0 }
                    &:last-child{ margin-bottom: 0 }

                    &.margin-top {
                        margin-top: 35px;
                    }
                }

                a{
                    color: $color1;
                    position: relative;
                    font-weight: 700;
                    &:before,
                    &:after{
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 4px;
                        width: 100%;
                        background-color: $color1;
                        opacity: 0.3;
                    }
                    &:after{
                        background-color: $color3;
                        opacity: 1;
                        width: 0;
                        transition: width $ease 0.5s;
                    }
                    &:hover{
                        &:after{
                            width: 100%; 
                        }
                    }
                }
            }
        }
    }
}

.contactMain{

    .contactLeft{
        padding: 0;
        background-color: white;
        @media #{$down1300}  { order: 2; }
    }

    .contactRight{
        padding: 0;
        @media #{$down1300}  { order: 1; }
        h2{ margin-bottom: 50px; }

        .drawers{
            & > li{
                & > .toggle{
                    padding-top: 32px;
                    padding-bottom: 32px;
                    h5{
                        font-size: 22px;
                        @media #{$down500}{ font-size: 20px; }
                        @media #{$down400}  { font-size: 18px; }
                    }
                }
            }
        }
    }

}