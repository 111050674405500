.overlay-calendar{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: white;

    visibility: hidden;
    opacity: 0;
    transition: all 0.7s $ease;
    z-index: 250;

    .cal{
        padding: 0 30px;
        &::before{
            content: '';
            position: absolute;
            height: 77px;
            top: 0;
            left: 0;
            right: 0;
            background: $color1;
        }
    }

    .datepicker table{
        width: 300px;
    }

    .closeCalendarOverlay{
        display: inline-block;
        position: absolute;
        width: 60px;
        height: 60px;
        background: white;
        border-radius: 50%;
        transform: translate(50%,-50%) rotate(45deg);
        right: -30px;
        top: -30px;
        cursor: pointer;

        @media #{$down500} {
            right: 30px;
            top: -45px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background: #e63735;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 17px;
        }
        &::before{
            width: 17px;
            height: 2px;
        }

        &:hover{
            background-color: $color1;
            &::before,
            &::after {
                background: white;
            }
        }
    }
}



.showOverlayCalendar{

    &.overlayClosing .overlay-calendar{
        opacity: 0;
    }
    .overlay-calendar{
        visibility: visible;
        opacity: 1;
        transition: all 1s $ease;
    }


    &.overlayClosing .calendarOverlay{
        opacity: 0;
    }
    .calendarOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }
}