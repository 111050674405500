.fileField {
	position: relative;

	.error { 
		z-index: 2;
		top: 70%;
		margin-left: 30px;
	}

	.uploadBtnWrapper {
		position: relative;
		width: 100%;
		display: inline-block;

		input[type=file] {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		.btnUpload {
			display: block;
			position: relative;
			width: 100%;
			padding: 30px 120px 30px 40px;
			font-weight: 700;
			color: $color1;
			border: none;
			background: $color2;
			cursor: pointer;
			transition: background 300ms, color 300ms;

			font-size: 14px;
			font-weight: bold;

			@media #{$down768} { padding: 30px 70px 30px 20px; }
			@media #{$down600} { padding: 20px 70px 20px 20px; }

			.icon {
				position: absolute;
				width: 50px;
				height: 50px;
				top: calc(50% - 25px);
				right: 30px;

				@media #{$down768} { width: 40px; height: 40px; top: calc(50% - 20px); right: 20px; }
				@media #{$down400} { width: 30px; height: 30px; top: calc(50% - 15px); }

				&:before {
					content: '';
					position: absolute;
					width: 50px;
					height: 50px;
					top: 50%;
					left: 50%;
					background: #fff;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					transition: transform 300ms;
				}

				svg {
					position: absolute;
					width: 18px;
					height: 18px;
					top: 50%;
					left: 50%;
					stroke: $color3;
					fill: none;
					transform: translate(-50%, -50%);

					@media #{$down400} { width: 16px; height: 16px; }
					
					use {
						stroke: $color3;
						fill: none;
					}
				}
			}

			&:hover {
				background: #e8e7e7; 

				.icon:before { transform: translate(-50%, -50%) scale(1.1); }
			}
		}
	}
}
