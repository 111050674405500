section.vitrine{
    & > a,
    & > div{
        display: flex;
        min-height: 430px;
        align-items: center;
        position: relative;
        overflow: hidden;
        background-color: $color1;
        
        .wrapper{
            text-align: center;
            width: 100%;
            position: relative;
            z-index: 2;
            h2{
                color: white;
            }
        }

        .image{
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
            top: -10%;
            bottom: -10%;
            opacity: 0.5;
        }
    }
}