.breadcrumbs{
    position: absolute;
    z-index: 4;
    left: 1%;
    top: 0;
    background-color: white;
    max-width: 100%;
    @media #{$down900} { width: 100% }
    
    & > ul{
        display: flex;
        align-items: center;
        height: $breadcrumbsHeight;
        padding: 0 40px;
        @media #{$down768} { padding: 0 30px; }
        @media #{$down500} { padding: 0 20px; }
        li{
            padding-right: 26px;
            position: relative;
            font-size: 13px;
            white-space: nowrap;
            @media #{$down600} { padding-right: 20px; }
            a,span{
                color: $breadcrumbsColor;
                font-weight: 500;
                transition: color 0.7s $ease;
                letter-spacing: 0.02em;
                line-height: 1.2em;
                &.home{
                    background-color: $color2;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    position: relative;
                    transition: background-color $ease 0.7s;
                    @media #{$down400} { 
                        height: auto;
                        width: auto;
                        background-color: transparent !important;
                    }
                    svg{
                        fill: $color1;
                        width: 14px;
                        height: 12px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        position: relative;
                        transition: fill $ease 0.7s;
                        @media #{$down400} { transform: none; top: auto; left: auto;}
                    }
                    &:hover{
                        background-color: $color1;
                        svg{
                            fill: white;
                        }
                    }
                }
            }
            a{
                &:hover{
                    color: $color1;
                }
            }
            &::after{
                content: '-';
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translate(0,-50%);
                color: $breadcrumbsColor;
                @media #{$down600} { right: 8px; }
            }
            &:last-child{
                padding-right: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $color1;
                span{
                    color: $color1;
                }
                svg{
                    display: none;
                }

                &.hasDropdown{
                    cursor: pointer;
                    span{
                        padding-right: 8px;
                    }
                    svg{
                        display: inline-block;
                        width: 9px;
                        height: 5px;
                        fill: $color3;
                        transition: transform $ease 0.7s;
                    }
                }
                &::after{
                    content: normal;
                }
            }
        }
    }

    .breadcrumbsDropDown{
        display: none;
        overflow: hidden;
        padding: 0 40px;
        border-top: $color2 1px solid;
        ul{
            flex: 0;
            padding: 30px 0 40px 66px;
            li{
                a{
                    font-size: 13px;
                    font-weight: 600;
                    color: $breadcrumbsColor;
                    padding: 8px 0;
                    transition: color 0.7s $ease;
                    letter-spacing: 0.02em;
                    transition: transform $ease 0.7s, color $ease 0.7s;
                    &:hover{
                        color: $color1;
                        font-weight: 600;
                        transform: translateX(10px);
                    }
                }
                &.active{
                    position: relative;
                    a{
                        color: $color1;
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        display: block;
                        left: -20px;
                        top: calc( 0.4em + 9px);
                        height: 6px;
                        width: 6px;
                        background-color: $color3;
                        border-radius: 50%;
                        transform: translate(0,-50%);
                    }
                }
            }
        }
    }

    &.dark{
        background-color: $color5;
        & > ul{
            li{
                a,span{
                    &.home{
                        background-color: $color1;
                        svg{
                            fill: white;
                        }
                        &:hover{
                            background-color: $color2;
                            svg{
                                fill: $color1;
                            }
                        }
                    }
                }
                a{
                    &:hover{
                        color: white;
                    }
                }
                &:last-child{
                    color: white;
                    span{
                        color: white;
                    }
                }
            }
        }

        .breadcrumbsDropDown{
            border-top: #4d4d4d 1px solid;
            ul{
                li{
                    a{
                        &:hover{
                            color: white;
                        }
                    }
                    &.active{
                        a{
                            color: white;
                        }
                    }
                }
            }
        }
    }

}

.showOverlayBreadcrumbs{
    .breadcrumbs{
        & > ul{
            li{
                &:last-child{
                    svg{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.subHeader{
    position: relative;
    min-height: $breadcrumbsHeight;
    .breadcrumbs{
        left: 0;
        top: 0;
    }
    .share{
        position: absolute;
        right: 50px;
        top: 30px;
    }
}