// .radioField {
// 	position: relative;

// 	label {
// 		display: inline-block;
// 		position: relative;
// 		padding-left: 40px;
// 		font: 17px/1.3 $font;
// 		color: $color1;
// 		cursor: pointer;
// 		user-select: none;
// 		font-weight: 500;

// 		&:not(:nth-last-child(2)){
// 			margin-right: 20px;
// 		}

// 		@media #{$down1024} { padding-left: 30px; }
// 		@media #{$down600} {
// 			display: block;
// 			margin-top: 10px;
// 		}
// 		@media #{$down500} { font-size: 15px; }

// 		input { position: absolute; opacity: 0; cursor: pointer; }

// 		span {
// 			position: absolute;
// 			width: 21px;
// 			height: 21px;
// 			top: 0;
// 			left: 0;
// 			border: 2px solid #bdbdbd;
// 			border-radius: 50%;

// 			@media #{$down500} { width: 20px; height: 20px; }

// 			&:before, &:after {
// 				content: '';
// 				position: absolute;
// 				width: 0px;
// 				height: 0px;
// 				top: 50%;
// 				left: 50%;
// 				border-radius: 50%;
// 				transform: translate(-50%, -50%);
// 				transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1), height 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
// 			}

// 			&:before { background: $color3; }
// 			&:after { background: #fff; transition-delay: 200ms; }
// 		}

// 		input:checked ~ span {
// 			&:before {
// 				width: 21px; height: 21px;

// 				@media #{$down500} { width: 20px; height: 20px; }
// 			}
// 			&:after  {
// 				width: 6px; height: 6px;

// 				@media #{$down500} { width: 6px; height: 6px; }
// 			}
// 		}

// 		&.main{ //Label du groupe de champs, pas de chaques boites
// 			font-size: 19px;
// 			font-weight: 600;
// 			padding-left: 0;
// 			margin-bottom: 15px;
// 		}
// 	}

// 	.radio-field{
// 		margin-bottom: 15px;
// 	}
// }

// .radioFieldWrapper {
// 	h5 {
// 		margin-bottom: 30px;
// 		color: $color1;

// 		@media #{$down1024} { margin-bottom: 20px; }
// 	}

// 	&.inline {
// 		.radioField {
// 			display: inline-block;

// 			@media #{$down768} { display: block; }

// 			&:not(:last-child) {
// 				margin-right: 60px;

// 				@media #{$down1400} { margin-right: 50px; }
// 				@media #{$down1200} { margin-right: 40px; }
// 				@media #{$down1024} { margin-right: 30px; }
// 				@media #{$down768}  { margin-right: 0; margin-bottom: 20px; }
// 			}
// 		}
// 	}

// 	&.block {
// 		.radioField {
// 			display: block;
// 			max-width: 860px;

// 			&:not(:last-child) {
// 				margin-bottom: 30px;
// 			}

// 			label{
// 				&:not(:nth-last-child(2)){
// 					margin-bottom: 20px;
// 				}
// 			}
// 		}
// 	}

// }

/*** RADIO ***/
.radioField {
	position: relative;

	label {
		display: inline-block;
		position: relative;
		padding-left: 30px;
		font: 1rem/1.3 $font;
		cursor: pointer;
		user-select: none;

		input { position: absolute; opacity: 0; cursor: pointer; }

		span {
			position: absolute;
			width: 20px;
			height: 20px;
			top: 0;
			left: 0;
			border: 1px solid rgba(#000, 0.4);
			border-radius: 50%;

			&:before {
				content: '';
				position: absolute;
				width: 10px;
				height: 10px;
				top: 50%;
				left: 50%;
				background: $color3;
				border-radius: 50%;
				transform: translate(-50%, -50%) scale(0);
				transition: transform 225ms $easeInOut;
			}
		}

		input:checked ~ span:before { transform: translate(-50%, -50%) scale(1); }
	}

    label.master {
        font: 18px/45px $font;
        font-weight: 500;
        @media screen and (max-width: 600px) { font-size: 15px; line-height: 35px; }
    }

    &.list {
        label {
            display: block;
            margin-top: 20px;
            margin-left: 30px;
        }
        label.master {
            padding: 0;
            margin-left: 0;
        }
    }

	&.white {
		label {
			color: #fff;

			span {
				border: 1px solid rgba(#fff, 0.4);
				background: #fff;
			}
		}
	}

	.mb10 {
		margin-bottom: 10px;
	}

	.title {
		color: #000;
	}

	.text {
		font-size: 16px;
		margin-bottom: 17px;
	}

	h4, h4 > p {
		font-weight: 600;
	}
}

#formIncendies {
	.col6 {
		display: inline-block;
	}

	.mb35 {
		margin-bottom: 35px;
	}

	.inputField:first-child {
		margin-top: 0;
	}

	.note {
		font-size: 12px;
	}

	h2 {
		margin-bottom: 50px;
	}

	.floatRightDesktop {
		float: right;
		@media screen and (max-width: 1300px) { float: none; }
	}
}
