// UN CHAMP DE TEXTE __________
.inputField {
	position: relative;

	input {
		width: 100%;
		height: 45px;
		padding: 0;
		border: none;
		border-bottom: 3px solid $formBorderColor;
		font: 17px/45px $font;
		font-weight: 700;
		color: $color1;
		background: transparent;
		transition: border 300ms ease;
		font-weight: 500;
		
		@media #{$down500} { font-size: 15px; line-height: 35px; height: 35px; }
		
		&:focus, &.valid { border-bottom-color: $color1; }
	}
	
	label {
		position: absolute;
		top: 0px;
        left: 0;
		font: 17px/45px $font;
		color: $color1;
		cursor: text;
		transition: 300ms $ease;
		font-weight: 500;

		@media #{$down500} { font-size: 15px; line-height: 35px; }
		@media #{$down400} { font-size: 14px; }
	}

	input:focus + label,
	input.valid + label,
	input.error + label,
	input.notEmpty + label {
		top: -12px;
		font-size: 11px;
		line-height: 12px;
		cursor: default;
		pointer-events: none;

		@media #{$down500} { top: -10px; }
	}

	input.vide + label {
		top: 0px;
		font: 17px/45px $font;

		@media #{$down500} { font-size: 15px; line-height: 35px; }
	}

	&.white {
		input {
			border-bottom: 2px solid #fff;
			color: #fff;
			
			&:focus, &.valid { border-bottom-color: #fff; }
		}
		
		label { color: #fff; }
	}
}
