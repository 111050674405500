.eventBack{
    padding-bottom: 15px;
    a{
        color: $color3;
        font-size: 15px;
        font-weight: 600;
        padding-left: 30px;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            width: 6px;
            height: 10px;
            top: 50%;
            left: 0;
            background: url(../images/icons/arrowRed.svg) no-repeat center/contain;
            transform: translateY(-50%) rotate(180deg);
            transition: right 300ms;
            transition: left $ease 0.7s;
        }

        &:hover{
            &::after{
                left: -10px;
            }
        }
    }

}

.eventWhiteBg{
    background-color: white;
}

.eventDetails{
    color: $color1;

    .topInfos{
        padding-bottom: 20px;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        .categ{
            color: $color4;
        }
    }
    h1{
        max-width: 70%;
        line-height: 1.04em; //78px/75px
    }
    .bottomInfos{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 600;
        font-size: 14px;

        div {
            margin-bottom: 15px;
        }

        .location,
        .time,
        .dateTextuelle{
            display: flex;
            @media #{$down500} { width: 100%; }
            svg{
                height: 14px;
                width: 14px;
                fill: $color3;
            }
            span{
                display: inline-block;
                margin-left: 18px;
                transform: translate(0, 1px);
            }
            &:not(:last-child){
                margin-right: 50px;
                @media #{$down500} { margin-right: 0; margin-bottom: 15px; }
            }
        }

        .dateTextuelle {
            svg {
                fill: none;
                stroke: $color3;
                stroke-width: 3.5px;
            }
        }
    }
    .imageContainer{
        width: 100%;
        padding-top: 53.76344086021505%; //ratio de 500px / 930px
        position: relative;
        overflow: hidden;

        .image{
            position: absolute;
            left: 0;
            right: 0;
            top: -10%;
            bottom: -10%;
        }

        p {
            display: inline-block;
            position: absolute;
            padding: 5px 10px;
            right: 0;
            bottom: 0;
            background: rgba(#26282e, 0.7);
            font: 13px/1 $font;
            color: #fff;
        }
    }

    .dynamic{
        max-width: 900px;
        margin: 0 auto;
    }

}

.eventNav, // Comprends aussi les style pour la nav des nouvelles puisque les styles sont les mêmes
.newsNav{
    background-color: $color1;
    display: flex;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 600;
    height: 88px;
    @media #{$down768} {
        flex-wrap: wrap;
        padding-top: 15px;
        padding-bottom: 15px;
        height: 120px;
    }
    .back{
        flex-grow: 1;
        @media #{$down768} { width: 100%; text-align: center; }
        a{
            padding-left: 30px;
            position: relative;
            color: white;
            &::before{
                content: '';
                position: absolute;
                width: 6px;
                height: 10px;
                top: 50%;
                left: 0;
                background: url(../images/icons/arrow.svg) no-repeat center/contain;
                transform: translate(0, -50%) rotate(180deg);
                transition: transform $ease 0.7s;
            }
            &:hover{
                &::before{
                    transform: translate(-10px, -50%) rotate(180deg);
                }
            }
        }
    }
    nav{
        @media #{$down768} { width: 100%; text-align: center; }
        a,
        span{
            &.prevEvent,
            &.nextEvent,
            &.prevNews,
            &.nextNews{
                color: white;
                display: inline-block;
                span{
                    display: inline-block;
                    padding: 0 24px;
                }
                svg{
                    fill: none;
                    stroke: white;
                    stroke-width: 2px;
                    width: 68px;
                    height: 9px;
                    transition: transform $ease 0.7s;
                    @media #{$down500} { display: none; }
                }

                &:first-child{
                    border-right: 2px #949494 solid;
                }

                &:hover{
                    svg{
                        transform: translate(10px);
                    }
                }
            }

            &.prevEvent,
            &.prevNews{
                svg{
                    transform: rotate(180deg);
                }
                &:hover{
                    svg{
                        transform: rotate(180deg) translate(10px);
                    }
                }
            }
        }
        span{
            &.prevEvent,
            &.nextEvent,
            &.prevNews,
            &.nextNews{
                pointer-events: none;
                color: #949494;
                svg{
                    stroke: #949494;
                }
            }
        }
    }
}

.eventsSlider{
    // Voir css de la page d'accueil pour la base de style du carrousel d'événements
    &:before{
        content: normal;
    }
}

.eventsImagesSlidesWrapper {
    .metas {
        padding-bottom: 20px;
    }
}
