.overlay-dropDown{
    display: flex;
    position: absolute;
    height: 0;
    top: 100%;
    right: 0;
    left: 0;
    background: #fff;
    align-items: center;
    visibility: hidden;
    pointer-events: none;
    scrollbar-width: none;
    z-index: 250;
    transition: visibility 0ms ease 700ms;

    max-height: calc(100vh - #{$headerHeight} - 30px); //30px de jeu pour pouvoir sortir du menu

    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: all;

    //Cacher la scrollbar tout en laissant le scroll possible
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }

    // Call to action Portail citoyen -------------------------------------
    &>aside{
        width: 380px;
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../images/content/header/cta-alert.jpg);
        @media #{$down1300} { width: 300px; }
        &>div{
            position: relative;
            z-index: 1;
            text-align: center;
            .icon{
                stroke: white;
                width: 87px;
                height: 78px;
                margin-bottom: 30px;
                @media #{$down1300} { width: 60px; height:56px; }
            }
            h6{
                color: white;
                font-weight: 500;
                font-size: 17px;
                margin-bottom: 10px;
                line-height: 1em;
                text-transform: none;
                @media #{$down1300} { font-size: 13px; }
            }
            h4{
                color: white;
                font-weight: bold;
                font-size: 26px;
                line-height: 1.153846153846154em; //30px / 26px
                padding-bottom: 40px;
                @media #{$down1300} { font-size: 20px; }
            }
        }
        &::after{
            content: '';
            background-color: rgba($color1,0.8);
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    // Liste des pages de la section --------------------------------------
    &>div{
        display: flex;
        flex-grow: 1;
        padding: 0 0 0 380px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0s $ease 0.7s;
        overflow: hidden;
        @media #{$down1300} { padding: 0 0 0 300px; }

        ul{
            width: 33.3333%;
            padding: 60px;
            margin-top: -50px;
            padding-top: 110px;
            background-color: $dropMenuColor1;
            transition: all 0.7s $ease 0.05s;
            opacity: 0;
            visibility: hidden;
            transform: translateY(50px);
            @media #{$down1300} { padding: 40px; padding-top: 90px;}
            &:nth-child(even){
                background-color: $dropMenuColor2;
            }
            li{
                h6{
                    font-weight: bold;
                    text-transform: none;
                    font-size: 17px;
                    line-height: 1em;
                    margin-bottom: 15px;
                }

                a{
                    font-size: 14px;
                    line-height: 1em;
                    font-weight: 500;
                    padding: 6px 0px;
                    color: $color1;
                    display: block;
                    transition: padding $ease 0.3s;
                }

                &.active{
                    a{
                        position: relative;
                        &::before{
                            content: '';
                            background-color: $color3;
                            height: 6px;
                            width: 6px;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: -18px;
                            transform: translateY(-50%);
                        }
                    }
                }

                &:hover{
                    a{
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

.showAlertBanner{
    .overlay-dropDown{
        max-height: calc(100vh - #{$headerHeight} - #{$alertHeight} - 30px); //30px de jeu pour pouvoir sortir du menu
    }
}

.dropDownOverlay {
    transition: opacity 0.7s ease,
}

.showOverlayDropDown{
    .overlay-dropDown{
        visibility: visible;
        pointer-events: all;
        transition: none;
        &>div{
            opacity: 1;
            transform: none;
            transition: all 0.3s $ease;
        }
    }

    &.guichet {
        .overlay-dropDown [data-links="guichet"] { display: flex; opacity: 1; visibility: visible;
            ul{ transform: none; visibility: visible; opacity: 1; transition: all $ease 0.8s 0.3s }
        }
        .sousMenu[data-section="guichet"]{ color: $color3 }
    }
    &.ville {
        .overlay-dropDown [data-links="ville"]   { display: flex; opacity: 1; visibility: visible;
            ul{ transform: none; visibility: visible; opacity: 1; transition: all $ease 0.8s 0.3s }
        }
        .sousMenu[data-section="ville"]  { color: $color3 }
    }
    &.loisirs {
        .overlay-dropDown [data-links="loisirs"] { display: flex; opacity: 1; visibility: visible;
            ul{ transform: none; visibility: visible; opacity: 1; transition: all $ease 0.8s 0.3s }
        }
        .sousMenu[data-section="loisirs"]{ color: $color3 }
    }

    .dropDownOverlay {
        visibility: visible;
        opacity: 1;
    }
}