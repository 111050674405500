.jobsHeader{
    .zone.dynamic{
        padding-top: 50px;
        @media #{$down500} { padding-top: 40px; }
    }
}

#jobsBlock{
    .category{
        &:not(:last-child){margin-bottom: 60px;}

        &>h2{
            margin-bottom: 30px;
        }

        ul{
            li{
                background: white;
                margin-bottom: 30px;
                a{
                    display: flex;
                    width: 100%;
                    min-height: 150px;
                    align-items: center;
                    padding: 30px 50px;
                    transition: background 0.6s $ease;
                    position: relative;
                    @media #{$down768} { padding: 20px 40px; }
                    
                    .wrapper{
                        padding-right: 145px;
                        width: 100%;
                        position: relative;
                        transition: padding-left cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
                        @media #{$down768} { padding-right: 50px; }

                        h5,h6{
                            font-weight: 500;
                            font-size: 25px;
                            color: #3f3f3f;
                        }

                        h6{
                            font-size: 12px;
                            font-weight: 600;
                            color: #7e7e7e;
                            text-transform: uppercase;
                        }

                        .icon{
                            position: absolute;
                            top: 50%;
                            transform: translate(0,-50%);
                            width: 50px;
                            height: 50px;
                            right: 0;
                            svg{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                width: 25px;
                                height: 25px;
                                stroke: white;
                                z-index: 1;
                            }
                            &:after{
                                position: absolute;
                                content: '';
                                width: 50px;
                                height: 50px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%,-50%) scale(1);
                                background: $color3;
                                border-radius: 50%;
                                transition: transform 0.5s $ease;
                            }
                        }

                        .readmore{
                            position: absolute;
                            top: 50%;
                            transform: translate(0,-50%);
                            right: 0;
                            display: inline-block;
                            color: $color3;
                            font-weight: 600;
                            padding-right: 25px;
                            &::after{
                                content: '';
                                position: absolute;
                                width: 6px;
                                height: 10px;
                                top: 50%;
                                right: 10px;
                                background: url(../images/icons/arrowRed.svg) no-repeat center/contain;
                                transform: translateY(calc(-50% + 1px));
                                transition: right 300ms;
                            }
                        }
                    }

                    &::before{
                        content: '';
                        position: absolute;
                        display: block;
                        top: 0;
                        left: 0;
                        width: 5px;
                        height: 100%;
                        background: $color3;
                        transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
                    }

                    &:hover{
                        .wrapper{
                            padding-left: 10px;
                            .readmore{
                                &::after{
                                    right: 0;
                                }
                            }
                            .icon{
                                &:after{
                                    transform: translate(-50%,-50%) scale(1.2);
                                }
                            }
                        }

                        &::before{
                            width: 15px;
                        }
                    }

                }
            }
        }
    }
}

.jobTopInfos{
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    .type{
        color: $color4;
    }
}

.carreerFormBox{
    background: white;
}

.dynamic h2.jobFormTitle{
    margin-bottom: 0;
}