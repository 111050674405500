.alertBlock{
    position: relative;
    z-index: 104;
    background-color: $alertColor;
    width: 100%;
    display: flex;
    height: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: height $ease 0.7s 0.3s;

    @media #{$down900}{ justify-content: flex-start; }
    
    .link{
        font-weight: 600;
        color: white;
        padding: 10px 75px;
        display: inline-block;
        line-height: 1.2em;
        font-size: 17px;
        transition: opacity $ease 0.7s;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 1400px;
        opacity: 0;
        @media #{$down1024}{ font-size: 15px; }
        @media #{$down900}{
            padding: 10px 50px;
            padding-right: 100px;
            width: 100%;
            text-align: left;
        }
        @media #{$down768} { padding-left: 30px; }
        @media #{$down600} { font-size: 13px; }
        @media #{$down500} { padding-left: 20px; }
    }
    
    .closeAlert{
        position: absolute;
        right: 50px;
        top: 50%;
        background-color: rgba(0,0,0,0.1);
        border-radius: 50%;
        height: 33px;
        width: 33px;
        transform: translate(0,-50%) rotate(45deg);
        transition: opacity $ease 0.7s;
        @media #{$down1024}{ right: 30px; }
        &::before,
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background: white;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 13px;
        }
        &::before{
            width: 13px;
            height: 2px;
        }
        &:hover{
            opacity: 0.5;
        }
    }
}


.showAlertBanner{
    .alertBlock{
        height: $alertHeight;
        .link{
            opacity: 1;
            &:hover{
                opacity: 0.6;
            }
        }
    }
}