.overlay-search-wrapper{
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    z-index: -1;
    visibility: hidden;
    transition: bottom 0.7s $ease;
    
    .overlay-search{
        align-items: center;
        background-color: white;
        padding-top: 60px;
        padding-bottom: 60px;
        box-shadow: inset 0 15px 15px -15px rgba(0,0,0,0.1);
        @media #{$down500} { padding-top: 35px; padding-bottom: 35px; }

        .magnifier{
            padding-right: 30px;
            svg{
                fill: $color3;
                height: 32px;
                width: 32px;
            }
        }

        .input{
            flex-grow: 1;
            padding-right: 65px;
            @media #{$down500} { padding-right: 55px; }
            form{
                position: relative;
                input[type='text']{
                    width: 100%;
                    border: 0;
                    height: 44px;
                    font-size: 28px;
                    font-family: $font;
                    font-weight: 400;
                    @media #{$down500} { font-size: 24px; }
                    @media #{$down400} { font-size: 21px; }

                    &::placeholder { color: rgba($color1,0.5); opacity: 1; } /* Chrome, Firefox, Opera, Safari 10.1+ */
                    &:-ms-input-placeholder { color: rgba($color1,0.5); }/* Internet Explorer 10-11 */
                    &::-ms-input-placeholder { color: rgba($color1,0.5); }/* Microsoft Edge */
                }

                .searchInput{
                    position: relative;
                    .emptyBtn{
                        position: absolute;
                        width: 33px;
                        height: 33px;
                        right: -43px;
                        top: 50%;
                        transform: translate(0,-50%);
                        cursor: pointer;

                        &>div{
                            width: 33px;
                            height: 33px;
                            &:before,
                            &:after{
                                content: '';
                                width: 12px;
                                height: 2px;
                                background-color: $color1;
                                position: absolute;
                                transform: translate(-50%,-50%) rotate(-45deg);
                                top: 50%;
                                left: 50%;
                                z-index: 1;
                            }
                            &:after{
                                transform: translate(-50%,-50%) rotate(45deg);
                            }
                        }

                        &::after{
                            content: '';
                            position: absolute;
                            width: 33px;
                            height: 33px;
                            background-color: $color2;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%) scale(1.0);
                            border-radius: 50%;
                            transition: transform 0.6s $ease;
                        }

                        &:hover{
                            &::after{
                                transform: translate(-50%,-50%) scale(1.1);
                            }
                        }
                    }
                }
            }
        }

        .searchBtn{
            position: relative;

            & > div{
                width: 55px;
                height: 55px;
                position: relative;

                svg.icon{
                    fill: white;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    cursor: pointer;
                }

                &:after{
                    content: '';
                    width: 55px;
                    height: 55px;
                    background-color: $color3;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: transform $ease 0.3s;

                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            &:hover{
                & > div{
                    &:after{
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
            }
        }
    }
}

.showOverlaySearch{
    &.overlayClosing .overlay-search-wrapper{
        bottom: 100%;
        visibility: hidden;
        transition: bottom 0.7s $ease, visibility 0s ease 0.7s;
    }
    .overlay-search-wrapper{
        bottom: calc(100% - 175px - #{$headerHeight});
        visibility: visible;
        transition: bottom 0.7s $ease;
        @media #{$down500} { bottom: calc(100% - 125px - #{$headerHeight}); }
    }

    &.overlayClosing .searchOverlay{
        opacity: 0;
    }
    .searchOverlay{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.7s ease,
    }

    // Rendre le drop down du menu inactif quand l'overlay de recherche est ouvert
    header:not(.map) .headerRight ul{
        pointer-events: none;
        opacity: 0.35;
    }

    &.overlayClosing{
        header:not(.map) .headerRight ul{
            opacity: 1;
        }
    }
}
