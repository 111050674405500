// STYLES __________
h1, .h1 {
	font-size: 75px;
	line-height: 1.066666666666667em; //80px / 75px
	font-weight: 900;
	color: $color1;

	@media #{$down1300} { font-size: 70px; }
	@media #{$down1024} { font-size: 60px; }
	@media #{$down768}  { font-size: 50px; }
	@media #{$down600}  { font-size: 40px; }
	@media #{$down500}  { font-size: 35px; }
	@media #{$down400}  { font-size: 33px; }

	sup {
		vertical-align: super;
		font-size: 50px;

		@media #{$down1300} { font-size: 40px; }
		@media #{$down1024} { font-size: 35px; }
		@media #{$down768}  { font-size: 30px; }
		@media #{$down600}  { font-size: 25px; }
		@media #{$down500}  { font-size: 20px; }
		@media #{$down400}  { font-size: 16px; }
	}
}

h2, .h2 {
	font-size: 40px;
	font-weight: 600;
	line-height: 1.2em;
	color: $color1;

	@media #{$down1300} { font-size: 40px; }
	@media #{$down1024} { font-size: 35px; }
	@media #{$down768}  { font-size: 33px; }
	@media #{$down600}  { font-size: 32px; }
	@media #{$down500}  { font-size: 30px; }
	@media #{$down400}  { font-size: 28px; }

	//span { color: $color4; }
}

h3, .h3 {
	font: 35px/1.2 $font;
	font-weight: 700;
	color: $color1;

	@media #{$down1200} { font-size: 32px; }
	@media #{$down1024} { font-size: 28px; }
	@media #{$down768}  { font-size: 25px; }
	@media #{$down600}  { font-size: 23px; }
	@media #{$down500}  { font-size: 20px; }
}

h4, .h4 {
	font: 25px/1.3 $font;
	color: $color1;

	@media #{$down1024} { font-size: 23px; }
	@media #{$down768}  { font-size: 21px; }
	@media #{$down600}  { font-size: 20px; }
}

h5, .h5 {
	font: 25px/1.5 $font;
	font-weight: 500;
	color: $color1;

	@media #{$down1024} { font-size: 20px; }
	@media #{$down768}  { font-size: 19px; }
}

h6, .h6 {
	font: 12px/1.5 $font;
	font-weight: 700;
	color: $color1;
	text-transform: uppercase;
}

p {
	font: 17px/1.5 $font;
	color: $color1;
}

a {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}


// Le bouton
.btn {
	position: relative;
	padding: 20px 54px 18px 35px;
	font: 15px/1.3 $font;
	font-weight: 600;
	color: #fff;
	background: $color3;
	border-radius: 50px;
	transition: color 300ms, background 300ms;
	border: 0;

	&:after {
		content: '';
		position: absolute;
		width: 6px;
		height: 10px;
		top: 50%;
		right: 35px;
		background: url('../images/icons/arrow.svg') no-repeat center / contain;
		transform: translateY(calc(-50% + 1px)); // Ajustement de 1px a cause d'illusion d'optique
		transition: right 300ms;

		@media #{$down1300} { right: 25px; }
		@media #{$down500} { right: 20px; }
	}

	&:hover {
		background: $color1;

		&:after {
			right: 25px;

			@media #{$down1300} { right: 20px; }
			@media #{$down500} { right: 20px; }
		}
	}

	&.white{
		color: $color1;
		background: white;
		&:after {
			background-image:url('../images/icons/arrowRed.svg')
		}
		&:hover {
			color: white;
			background: $color3;
			&:after {
				background-image:url('../images/icons/arrow.svg')
			}
		}
	}

	&.dark{
		background: $color1;
		&:hover {
			background: $color3;
		}
	}

	&.w-icon{
		padding-right: 65px;
		@media #{$down1300} { padding-right: 55px; }
		&:after { content: normal; }
		.icon-container{
			display: block;
			position: absolute;
			width: 45px;
			height: 45px;
			background-color: $color3;
			border-radius: 50%;
			right: 8px;
			top: 50%;
			transform: translate(0, -50%);

			@media #{$down1300} {
				width: 35px;
				height: 35px;
				right: 6px;
			}

			svg{
				fill: white;
				width: 16px;
				height: 16px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}

	&.voir {
		margin-top: 30px;

		.icon-container {
			background-color: #fff;

			svg {
				fill: $color3;
			}
		}

		&:hover {
			.icon-container {
				background-color: rgb(44, 44, 44);
	
				svg {
					fill: #fff;
				}
			}
		}
	}

}

// Ajouter cette classe à un parent pour déclencher l'effect hover du bouton
.triggerHover:hover{
	.btn {
		background: $color1;

		&:after {
			right: 25px;

			@media #{$down1300} { right: 20px; }
			@media #{$down500} { right: 20px; }
		}
	}
}

.image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

svg.icon.stroke{
	fill: transparent;
	stroke: $color1;
}

[data-mainScrollbar] { overflow: hidden; }
html:not(.isMobile) {
	overflow: hidden;

	[data-mainScrollbar] {
		position: static;
		width: 100%;
		height: 100vh;
	}
}

.fancybox-caption__body {
	font-family: $font;
}