@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/gilroy/Gilroy-Bold.woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/gilroy/Gilroy-SemiBold.woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/gilroy/Gilroy-Medium.woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/gilroy/Gilroy-Regular.woff');
	font-weight: normal;
	font-style: normal;
}