header:not(.map) {
    background-color: white;
    position: relative;
    height: $headerHeight;
    font-size: 16px;
    font-weight: 600;
    z-index: 110;
    box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.1);

    .menuWrapper{
        width: 100%;
        display: flex;
        background: white;
    }

    .headerLeft{
        background-color: white;
        .logo{
            height: $headerHeight;
            display: flex;
            align-items: center;
            img{
                width: 174px;
                height: 45px;
                @media #{$down1200} { width: 230px; height: 50px }
                @media #{$down500}  { width: 180px; }
                @media #{$down400}  { width: 145px; }

                @media screen and (max-width: 375px) { width: 125px; }
                @media screen and (max-width: 350px) { width: 115px; }
            }
        }
    }
    .headerRight{
        margin-left: auto;
        display: flex;
        background-color: white;
        nav{
            ul{
                display: flex;
                padding-right: 20px;
                transition: opacity 0.6s $ease;
                li{
                    a{
                        height: $headerHeight;
                        display: flex;
                        align-items: center;
                        padding: 0 30px;
                        transition: color .6s $ease;
                        span{
                            margin-left: 12px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            svg{
                                width: 9px;
                                height: 5px;
                                fill: $color3;
                            }
                        }
                        &:hover{
                            color: $color3;
                        }

                        &.iconMenu{
                            position: relative;
                            width: 70px;
                            padding: 0;
                            svg,
                            img{
                                height: 25px;
                                width: 25px;
                                fill: transparent;
                                stroke: $color1;
                                stroke-width: 2.5px;
                                z-index: 2;
                                transition: all $ease 0.3s;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                transition: opacity 300ms ease;
                                opacity: 1;
                            }
                            img {
                                height: 35px;
                                width: 35px;

                                &:nth-child(2) {
                                    opacity: 0;
                                }
                            }
                            &:after{
                                content: '';
                                border-radius: 50%;
                                width: 55px;
                                height: 55px;
                                background-color: $color2;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%) scale(1);
                                z-index: 1;
                                transition: all $ease 0.3s;
                            }
                            &:hover{
                                svg{
                                    stroke: white;
                                }

                                img {
                                    &:nth-child(1) {
                                        opacity: 0;
                                    }
                                    &:nth-child(2) {
                                        opacity: 1;
                                    }
                                }

                                &:after{
                                    transform: translate(-50%,-50%) scale(1.18);
                                    background-color: $color3;
                                }
                            }
                        }
                    }
                }
            }
        }
        .searchTopBtn{
            height: $headerHeight;
            background-color: $color3;
            color: white;
            transition: background-color .6s $ease;
            cursor: pointer;
            overflow: hidden;
            width: 224px;
            @media #{$down1200} { padding: 0; width: 90px; order: 2;}
            @media #{$down500}  { width: 70px; }
            @media #{$down400}  { width: 50px; }

            .btnContent{
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                svg{
                    width: 20px;
                    height: 20px;
                    fill: white;
                    box-sizing: content-box;
                    padding-right: 18px;
                    @media #{$down1200} { padding-right: 0; }
                }
                .close{
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    position: relative;
                    margin-right: 18px;
                    @media #{$down1200} { margin-right: 0; }
                    &::before,
                    &::after{
                        content: '';
                        width: 15px;
                        height: 2px;
                        background: white;
                        transform: translate(-50%,-50%) rotate(-45deg);
                        top: 50%;
                        left: 50%;
                        position: absolute;
                    }
                    &::after{
                        transform: translate(-50%,-50%) rotate(45deg);
                    }
                }
            }

            &:hover{
                background-color: $color1;
            }
        }
        .hamburgerTopBtn{
            height: $headerHeight;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            transition: background-color .6s $ease;
            cursor: pointer;
            width: 90px;
            @media #{$down1200} { order: 1; }
            @media #{$down500}  { width: 70px; }

            svg{
                width: 20px;
                height: 20px;
                fill: $color3;
                box-sizing: content-box;
            }
        }

        .parking {
            height: $headerHeight;
            display: flex;
            align-items: center;

            svg {
                pointer-events: none;
            }
        }

        .parking-svg-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background-color: $color2;

            @media #{$down500}  {
                width: 45px;
                height: 45px;
            }

            svg {
                width: 30px;
                height: 30px;

                @media #{$down500}  {
                    width: 25px;
                    height: 25px;
                }

                &:last-child {
                    display: none;
                }
            }

            &.--no-parking svg {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }
        }
    }
}

// Boutons de liens rapides desktop et mobile
.fastLinksBtn{
    position: fixed;
    right: 35px;
    bottom: 35px;
    width: 60px;
    height: 60px;
    opacity: 0;
    visibility: hidden;
    background: $color3;
    border-radius: 50%;
    z-index: 350;
    transition: opacity 0.3s, visibility 0ms ease 0.3s, transform 0.3s $ease;

    @media #{$down400}{
        right: 10px;
        bottom: 10px;
    }

    cursor: pointer;

    &.display{
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms, transform 0.3s $ease;
    }

    .plus1,
    .plus2{
        position: absolute;
        top: 50%;
        left: 50%;
        background: white;
        transform: translate(-50%, -50%);
        transition: all $ease 0.7s;
    }
    .plus1{ width: 20px; height: 2px; }
    .plus2{ height: 20px; width: 2px; }

    &:hover{
        transform: scale(1.16); //70px / 60px
        .plus1{ transform: translate(-50%, -50%) scale(0.84); }
        .plus2{ transform: translate(-50%, -50%) scale(0.84); }
    }
}

.showOverlaySearch{
    header:not(.map) .headerRight .searchTopBtn{
        .btnContent.open{
            display: none;
        }
    }

   &.overlayClosing{
        header:not(.map) .headerRight .searchTopBtn{
            .btnContent.open{
                display: flex;
            }
        }
   }
}



