#page-search{

    h1{
        max-width: 850px;
        font-size: 40px;
        i{
            color: $color3;
            font-style: italic;
        }
    }

    #rechercheWrapper{
        #moreResultsWrapper{
            background: white;
            padding: 45px 60px;
            margin-top: 15px;
            @media #{$down768} { padding: 45px; }
            @media #{$down500} { padding: 45px 25px; }
            li{
                &:not(:last-child){
                    margin-bottom: 50px;
                    padding-bottom: 50px;
                    border-bottom: 1px solid rgba($color1,0.2);
                }
                a{
                    .subInfo{
                        font-size: 12px;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                    h4{
                        font-size: 32px;
                        font-weight: 600;
                        margin-bottom: 15px;
                        @media #{$down768} { font-size: 28px; }
                        @media #{$down500} { font-size: 25px; }
                    }
                    p{
                        font-weight: 500;
                    }

                    mark{
                        background-color: #ebebeb;
                    }

                    .btn{
                        margin-top: 30px;
                        display: inline-block;
                    }

                    &:hover{
                        .btn{
                            background-color: $color1;
                            &::after{
                                right: 25px;
                            }
                        }
                    }
                }
            }
        }
    }

}


.filterContainer{
    display: flex;
    margin-top: 35px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    ul{
        display: flex;
        li{
            margin-right: 25px;
            padding: 25px 0;
            a{
                color: $color1;
                font-weight: 600;
                font-size: 17px;
                position: relative;
                opacity: 0.5;
                transition: opacity 0.4s $ease;
                word-break: unset;
                white-space: pre;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    left: 0;
                    background-color: $color3;
                    bottom: -8px;
                    opacity: 0;
                    transition: opacity 0.4s $ease;
                }
            }
            &:last-child{margin-right: 0;}

            &:hover{
                a{
                    opacity: 1;
                }
            }

            &.active{
                a{
                    opacity: 1;
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
    }
}