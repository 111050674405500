#page-banqueDocuments{
    // Main
    .main{
        margin-bottom: 70px;
    }

    .documentsBlock{
        // Recherche
        .formSearch{
            margin-bottom: 60px;
        }
        .searchField{
            position: relative;

            > label {
                font-size: 22px;
                font-family: $font;
                font-weight: 500;
                cursor: text;
                color: $color10;
                opacity: 1;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
            &.notEmpty > label{
                opacity: 0;
            }
            > input {
                color: $color10;
                font-size: 22px;
                font-family: $font;
                font-weight: 500;
                caret-color: $color10;
                width: 100%;
                height: 60px;
                padding: 0;
                border: none;
                border-bottom: 1px solid $color1;
                background: transparent;
            }

            // Btn empty
            .emptyBtn{
                position: absolute;
                right: 70px;
                top: 40%;
                transform: translate(0, -50%);
                width: 33px;
                height: 33px;
                opacity: 0;
                transition: opacity 400ms;
                cursor: text;
                > div{
                    width: 33px;
                    height: 33px;
                    &:before, &:after{
                        content: '';
                        position: absolute;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        top: 50%;
                        left: 50%;
                        z-index: 1;
                        width: 12px;
                        height: 2px;
                        background-color: $color1;
                    }
                    &:after {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(1);
                    width: 33px;
                    height: 33px;
                    background-color: white;
                    border-radius: 50%;
                    transition: transform 0.6s $ease;
                }
                &:hover::after {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
            &.notEmpty .emptyBtn {
                opacity: 1;
                transition: opacity 400ms;
                cursor: pointer;
            }

            // Btn search
            .searchBtn {
                position: absolute;
                top: 40%;
                right: 0;
                transform: translate(0, -50%);
                cursor: pointer;
                > div{
                    width: 48px;
                    height: 48px;
                    position: relative;
                    svg.icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 20px;
                        height: 20px;
                        z-index: 1;
                        cursor: pointer;
                        fill: white;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%) scale(1);
                        width: 48px;
                        height: 48px;
                        background-color: $color3;
                        border-radius: 50%;
                        cursor: pointer;
                        transition: transform $ease 0.3s;
                    }
                }
                &:hover > div:after {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }

        // Filtres et trie
        .filtersWrapper{
            padding-bottom: 20px;
            .select-field{
                position: initial;
                width: 100%;
                margin-bottom: 20px;
            }
            @media screen and (min-width: 680px){
                &__position2{
                    display: flex;
                    .select-field{
                        width: 50%;
                        &:first-child{
                            padding-right: 10px;
                        }
                        &:last-child{
                            padding-left: 10px;
                        }
                    }
                }
            }
            @media screen and (min-width: 1230px){
                display: flex;
                &__position1, &__position2{
                    .select-field{
                        width: 300px;
                    }
                }
                &__position1{
                    .select-field{
                        margin-right: 40px;
                    }
                }
                &__position2{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    .select-field{
                        &:first-child{
                            margin-right: 40px;
                        }
                        &:first-child, &:last-child{
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

/* SWIPER */

.filter-slider {
    position: relative;
  
    .swiper-slide {
      width: max-content !important;
      margin-right: 8px;
    }
    
    &__option {
        font-family: $font, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: 5px 0;
        background-color: transparent;
        border: 0;
        color: rgb($color1, .5);
        position: relative;
        transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
        
        &::after {
            content: '';
            position: absolute;
            bottom: 0; left: 0;
            background-color: rgb($color1, .5);
            width: 100%;
            height: 2px;
        }
        
        &::before {
            content: '';
            position: absolute;
            bottom: 0; left: 0;
            background-color: $color3;
            width: 0;
            height: 2px;
            z-index: 2;
            transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
        }
    }
    
    .activeFilter .filter-slider__option,
    &__option:hover {
        color: rgb($color1, 1);
        &::before {width: 100%;}
        cursor: pointer;
    }
  
    &__button-slider-nav-of-filter {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
    }
  }

  .button-slider-nav-of-filter {
    --button-slider-nav-of-filter-size: 33px;
  }
  
  .button-slider-nav-of-filter {
    &__prev-container,
    &__next-container {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  
    &__prev-container {
      left: 0;
    }
  
    &__next-container {
      right: 0;
    }
  
    &__prev,
    &__next {
      position: relative;
      width: var(--button-slider-nav-of-filter-size);
      height: var(--button-slider-nav-of-filter-size);
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transition: opacity 0.3s, visibility 0.3s;
      border: 0; background-color: transparent;
      z-index: 1;
      cursor: pointer;
    }
  
    &__prev.disabled,
    &__next.disabled {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  
    &__prev::after,
    &__next::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      border-radius: 50%;
      transition: background-color 0.3s;
    }
  
    &__prev:hover::after,
    &__next:hover::after {
      background-color: $color3;
      
    }
  
    &__prev-svg,
    &__next-svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 9px;
      stroke: $color3;
      transition: stroke 0.3s;
      z-index: 1;
    }
  
    &__prev-svg {
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  
    &__prev:hover &__prev-svg,
    &__next:hover &__next-svg {
      stroke: $color2;
    }
  
    &__prev-background,
    &__next-background {
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(var(--button-slider-nav-of-filter-size) + 5px);
      background-color: $color2;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s, visibility 0.3s;
  
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 40px;
      }
    }
  
    &__prev-background {
      left: 0;
  
      &::after {
        left: calc(var(--button-slider-nav-of-filter-size) + 5px);
        $rgb-thirdly: $color2;
        background: linear-gradient(90deg, rgba($rgb-thirdly, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  
    &__next-background {
      right: 0;
  
      &::after {
        right: calc(var(--button-slider-nav-of-filter-size) + 5px);
        $rgb-thirdly: $color2;
        background: linear-gradient(-90deg, rgba($rgb-thirdly, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  
    &__prev.disabled + &__prev-background,
    &__next.disabled + &__next-background {
      opacity: 0;
      visibility: hidden;
    }
  }



