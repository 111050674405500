// BOUTON HAMBURGER __________
@mixin barre() {
    display: block;
    height: 2px;
    background: $color3;
}

.hamburgerTopBtn {
    position: relative;
	.box {
		position: absolute;
        width: 26px;
        height: 18px;
		top: 50%;
		left: 50%;
		vertical-align: middle;
		transform: translate(-50%, -50%);

        &::before, &::after {
            content: '';
            position: absolute;
            width: 26px;
            @include barre();
        }

        &:before {
            top: 0px;
        }
        &:after {
            bottom: 0px;
        }

    	.inner {
    		@include barre();
            margin-top: 8px;
    	}
	}

    &:hover {
		background: #fff;

        .box {
            .inner, &:before, &:after {
                background: $color3;
            }
        }
    }
}

.showOverlayMenu {
    .hamburger { background: #fff; }
    .box {
        &:before, &:after, .inner { background: $color3; }

        &:before {
            top: 8px;
            transform: rotate(45deg);
            transition: background 150ms, top 150ms $ease, transform 150ms $ease 150ms;
        }

        &:after {
            bottom: 8px;
            transform: rotate(-45deg);
            transition: background 150ms, bottom 150ms $ease, transform 150ms $ease 150ms;
        }

    	.inner {
            width: 0px !important;
            transition: background 150ms, width 0ms ease 150ms;
    	}
    }
}

.showOverlayMenu.overlayClosing {
    .box {
        &:before {
            top: 0px;
            transform: none;
            transition: background 300ms, top 150ms $ease 150ms, transform 150ms $ease;
        }
        &:after {
            bottom: 0px;
            transform: none;
            transition: background 300ms, bottom 150ms $ease 150ms, transform 150ms $ease;
        }

    	.inner {
            width: 26px !important;
            transition: background 300ms, transform 150ms $ease, width 150ms;
    	}
	}
}
