.drawers {
	margin: 0;
	max-width: none;
	list-style-type: none;
	@media #{$down1024} { margin-left: 0; }

	& > li {
		margin-bottom: 20px;
		position: relative;
		background-color: white;

		&:last-child{
			margin-bottom: 0;
		}


		& > .toggle {
			position: relative;
			margin: 0;
			cursor: pointer;
			background-color: white;
			padding: 40px 100px 40px 70px;
			z-index: 1;

			@media #{$down1024} { padding: 40px 80px 40px 30px; }
			@media #{$down768}  { padding: 30px 80px 30px 20px; }
			@media #{$down600}  { padding: 20px 50px 20px 20px; }

			&>div{
				transition: padding-left cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
				padding-left: 0;
			}

			.state{
				padding-left: 25px;
				z-index: 2;
				&::before{
					transition: color $ease 0.7s;
				}
				&::after{
					top: calc(50% - 2px );
				}
			}

			h5,h6{
				font-weight: 500;
				font-size: 25px;
				color: $color1;
				transition: color 300ms;
				position: relative;
				z-index: 2;
				transition: color $ease 0.7s;
			}

			h6{
				font-size: 12px;
				font-weight: 600;
				color: $color4;
				text-transform: uppercase;
			}

			@media #{$down500}  {
				h5{ font-size: 20px; }
			}

			@media #{$down400}  {
				h5{ font-size: 18px; }
				h6{ font-size: 11px; }
			}

			p.etat {

				position: relative;
				margin-bottom: 4px;
				line-height: 17px;
				font-size: 15px;
				padding-left: 20px;
				z-index: 2;

				&::after {
					content: '';
					position: absolute;
					border-radius: 50%;
					height: 12px;
					width: 12px;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}

				&.open::after { background-color: $color6 }
				&.close::after { background-color: $color8 }
				&.partial::after { background-color: $color9 }

				&.open 		{ color: $color6; }
				&.close		{ color: $color8; }
				&.partial 	{ color: $color9; }
			}

			.chevronContainer {
				position: absolute;
				top: 50%;
				right: 65px;
				transform: translateY(-50%);
				transition: background 300ms;
				z-index: 2;
				@media #{$down1024} { right: 40px; }
				@media #{$down600} { right: 20px; }

				svg{
					fill: $color3;
					width: 12px;
					height: 9px;
					transition: transform 0.7s $ease 0.1s;
				}
			}

			&:before,
			&:after{
				content: '';
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 5px;
				height: 100%;
				background-color: $color3;
				z-index: 1;
				transition: width cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
			}

			&:before{
				background-color: $color7;
				transition: width $ease 0.8s;
			}

			&:hover {
				&>div{
					padding-left: 20px;
				}
				&:after{
					width: 15px;
				}
			}
		}

		& > .drawer {
			display: none;
			max-width: 100%;
			opacity: 0;
			transform: translateY(100px);
			transition: opacity 300ms, transform 300ms;
			position: relative;

			& > div {
				padding: 60px 150px 60px 90px;

				@media #{$down1024} { padding: 50px 120px 20px 30px; }
				@media #{$down768}  { padding: 50px 120px 20px 20px; }
				@media #{$down600}  { padding: 40px 20px 60px 20px; }
				@media #{$down500}  { padding: 30px 20px 60px 20px; }
				@media #{$down400}  { padding: 20px 20px 60px 20px; }
			}

			.dynamic {
				//max-width: 670px;

				& > p { 
					margin-top: 50px;
					margin-left: 20px;
				}

				& > blockquote { 
					margin-top: 40px;
					margin-left: 20px;

					& > p { 
						margin-bottom: 20px;
					}
				}

			}

			

			.documents{
				max-width: 600px;
				margin-top: 30px;
				li:not(:last-child) {
					margin-bottom: 12px;
				}
			}

			.share{
				width: 60px;
				height: 60px;
				background-color: $color2;
				border-radius: 50%;
				position: relative;
				transition: all $ease 0.3s;

				position: absolute;
				top: 60px;
				right: 42px;
				@media #{$down1024} { right: 30px; }
				@media #{$down768} {
					width: auto;
					height: auto;
					top: auto;
					bottom: 10px;
					background-color: transparent !important;
					span{
						display: block !important;
						color: $color1 !important;
					}
				}

				span{ display: none; }
				svg{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					transition: all $ease 0.3s;
					@media #{$down768} {
						width: 14px;
						height: 14px;
						left: auto;
						right: 0;
						fill: $color1 !important;
					}
				}
				&:hover{
					background-color: $color1;
					transform: scale(1.16);
					svg{
						fill: $color2;
						transform: translate(-50%,-50%) scale(0.84);
					}
				}
			}
		}

		&.open {
			& > .toggle {

				&>div{
					padding-left: 20px;
				}

				.chevronContainer {
					svg{
						transform: rotate(180deg);
						transition: transform 0.7s $ease 0.4s;
					}
				}

				&::before{
					width: 100%;
				}

				&::after{
					width: 15px;
				}
			}

			& > .drawer {
				opacity: 1;
				transform: translateY(0);
				transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms, opacity 600ms linear 300ms;
			}
		}
	}

}

// Styles spécifiques aux rubriques plus
.drawers.plus {
	margin-left: -60px;
	@media #{$down1024} { margin-left: 0; }

	& > li {
		margin-left: 60px;
		margin-top: 152px;
		@media #{$down800} { margin-left: 0; margin-top: 0; }

		&.noThumb {
			margin-left: 60px;
			margin-top: 0;
			@media #{$down1024} { margin-left: 0; }
		}

		.imageWrapper {
			position: absolute;
			width: 208px;
			height: 208px;
			overflow: hidden;
			border-radius: 50%;
			left: 0;
			top: 0;
			z-index: 0;
			margin-left: -60px;
			margin-top: -122px;

			@media #{$down800} { display: none; }

			.image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

	}
}

// Styles pour la page répertoire
.drawers.directory {
	& > li {
		& > .drawer {

			.dynamic{

				ul{
					display: flex;
					flex-wrap: wrap;
					li{
						width: 50%;
						@media #{$down768} { width: 100%; }
					}
				}
			}
		}
	}
}