// UN TEXTAREA __________
.textField {
	position: relative;

	textarea {
		width: 100%;
		height: 45px;
		min-height: 0;
		padding: 6px 0 0 0;
		border: none;
		border-bottom: 3px solid $formBorderColor;
		font: 17px/1.5 $font;
		font-weight: 700;
		color: $color1;
		background: transparent;
		resize: none;
		overflow: hidden;
		transition: border 300ms ease;
		font-weight: 500;

		@media #{$down500} { font-size: 15px; line-height: 1.5; height: 35px; }

		&:focus, &.valid  { border-bottom-color: $color1; }
	}

	label {
		position: absolute;
		top: 0px;
        left: 0;
		font: 17px/45px $font;
		color: $color1;
		cursor: text;
		transition: 300ms $ease;
		font-weight: 500;

		@media #{$down500} { font-size: 15px; line-height: 35px; }
		@media #{$down400} { font-size: 14px; }
	}

	textarea:focus + label,
	textarea.valid + label,
	textarea.error + label,
	textarea.notEmpty + label {
		top: -12px;
		font-size: 11px;
		line-height: 12px;
		cursor: default;
		pointer-events: none;
	}

	textarea.vide + label {
		top: 0px;
		font: 17px/45px $font;

		@media #{$down500} { font-size: 15px; line-height: 35px; }
	}

}
