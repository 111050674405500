// Le fond noir de chaque popup ou sidepanel
#overlay, .dropDownOverlay, .searchOverlay, .menuOverlay, .fastLinksOverlay, .calendarOverlay, .shareOverlay, .overlay, .directoryOverlay, .selectOverlay, .popupOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($color1, 0.7);
    visibility: hidden;
    z-index: 104;
    opacity: 0;
    transition: opacity 0.7s ease;
}
.dropDownOverlay,  .menuOverlay, .searchOverlay{ //Ces 3 overlays doivent apparaître sous le header
    z-index: 100; 
}

.selectOverlay{
    background: rgba($color1, 0.5);
}

// L' icone de play
.iconPlay {
    position: absolute;
    width: 50px;
    height: 50px;
    border: solid 2px rgba(#fff, 0.5);
    border-radius: 50%;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    z-index: 1;
    transition: border-color 300ms;

    &:after {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent rgba(#fff, 0.5);
        transition: border-color 300ms;
    }
}

.rotating { animation: rotating 10s linear infinite; }

@keyframes rotating {
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
}

.info {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#000, 0.1);

    span {
        font: 12px/1 $font;
        color: $color1;
        text-transform: uppercase;

        &:first-child {
            font-weight: 700;
        }

        &:last-child {
            position: relative;
            padding-left: 40px;

            &:before {
                content: '';
                position: absolute;
                width: 6px;
                height: 1px;
                top: 5px;
                left: 14px;
                background: $color1;
            }
        }
    }

    &.white {
        border-bottom-color: #fff;

        span {
            color: #fff;

            &:last-child:before { background: #fff; }
        }
    }
}

.btnShare {
    position: relative;
    padding-left: 50px;
    font: 15px/1 $font;
    font-weight: 700;
    color: rgba($color1, 0.5);
    transition: color 300ms;

    &:hover { color: $color1; }

    div {
        position: absolute;
        width: 36px;
        height: 36px;
        top: 50%;
        left: 0;
        background: #fff;
        border-radius: 50%;
        transform: translateY(-50%);
    
        svg {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 50%;
            left: 50%;
            fill: $color1;
            stroke: $color1; 
            transform: translate(-50%, -50%);
        }
    }
}
