//----------------\\
//---- Helper ----\\
//----------------\\

////////////////////////////////////////////////////////////////////////////////////
// DISPOSITION
////////////////////////////////////////////////////////////////////////////////////
// Display ----------
.flex { display: -webkit-box; display: -ms-flexbox; display: flex; }

// Position ----------
.relative { position: relative; }
.absolute { position: absolute; }
.fixed    { position: fixed;    }
.sticky   { position: sticky; top: 50px; }

// Float ----------
.floatLeft  { float: left;  }
.floatRight { float: right; }



////////////////////////////////////////////////////////////////////////////////////
// TEXTE
////////////////////////////////////////////////////////////////////////////////////
// Text-align ----------
.textLeft   { text-align: left;   }
.textCenter { text-align: center; }
.textRight  { text-align: right;  }

// Vertical-align ---------- 
.alignTop    { vertical-align: top; }
.alignMiddle { vertical-align: middle; }
.alignBottom { vertical-align: bottom; }

// Text-transform ----------
.caps    { text-transform: capitalize; }
.allCaps { text-transform: uppercase; }



////////////////////////////////////////////////////////////////////////////////////
// Alignement BG
////////////////////////////////////////////////////////////////////////////////////
.bgLeft   { background-position: left center };
.bgCenter { background-position: center };
.bgRight  { background-position: right center };



////////////////////////////////////////////////////////////////////////////////////
// LES WRAPPERS
////////////////////////////////////////////////////////////////////////////////////
.w1200 { max-width: 1200px; width: 100%; margin: 0 auto; @media #{$down1200} { margin: 0; }}
.w1400 { max-width: 1400px; width: 100%; margin: 0 auto; @media #{$down1400} { margin: 0; }}


////////////////////////////////////////////////////////////////////////////////////
// UN PEU DE PADDING EN HAUTEUR
////////////////////////////////////////////////////////////////////////////////////
// Padding de 180px en Y ----------
.yP180 { padding-top: 180px; padding-bottom: 180px;
  @media #{$down1400} { padding-top: 150px; padding-bottom: 150px; }
  @media #{$down1200} { padding-top: 120px; padding-bottom: 120px; }
  @media #{$down1024} { padding-top: 80px; padding-bottom: 80px; }
  @media #{$down768}  { padding-top: 70px; padding-bottom: 70px; }
}
.tP180 { padding-top: 180px;
  @media #{$down1400} { padding-top: 150px; }
  @media #{$down1200} { padding-top: 120px; }
  @media #{$down1024} { padding-top: 80px; }
  @media #{$down768}  { padding-top: 70px; }
}
.bP180 { padding-bottom: 180px;
  @media #{$down1400} { padding-bottom: 150px; }
  @media #{$down1200} { padding-bottom: 120px; }
  @media #{$down1024} { padding-bottom: 80px; }
  @media #{$down768}  { padding-bottom: 70px; }
}

// Padding de 150px en Y ----------
.yP150 { padding-top: 150px; padding-bottom: 150px;
  @media #{$down1400} { padding-top: 120px; padding-bottom: 120px; }
  @media #{$down1024} { padding-top: 80px; padding-bottom: 80px; }
  @media #{$down768}  { padding-top: 70px; padding-bottom: 70px; }
}
.tP150 { padding-top: 150px;
  @media #{$down1400} { padding-top: 120px; }
  @media #{$down1024} { padding-top: 80px; }
  @media #{$down768}  { padding-top: 70px; }
}
.bP150 { padding-bottom: 150px;
  @media #{$down1400} { padding-bottom: 120px; }
  @media #{$down1024} { padding-bottom: 80px; }
  @media #{$down768}  { padding-bottom: 70px; }
}

// Padding de 115px en Y ----------
.yP115 { padding-top: 115px; padding-bottom: 115px;
  @media #{$down1400} { padding-top: 90px; padding-bottom: 90px; }
  @media #{$down1024} { padding-top: 80px; padding-bottom: 80px; }
  @media #{$down768}  { padding-top: 70px; padding-bottom: 70px; }
}
.tP115 { padding-top: 115px;
  @media #{$down1400} { padding-top: 90px; }
  @media #{$down1024} { padding-top: 80px; }
  @media #{$down768}  { padding-top: 70px; }
}
.bP115 { padding-bottom: 115px;
  @media #{$down1400} { padding-bottom: 90px; }
  @media #{$down1024} { padding-bottom: 80px; }
  @media #{$down768}  { padding-bottom: 70px; }
}

// Padding de 100px en Y ----------
.yP100 { padding-top: 100px; padding-bottom: 100px;
  @media #{$down1400} { padding-top: 90px; padding-bottom: 90px; }
  @media #{$down1024} { padding-top: 80px; padding-bottom: 80px; }
  @media #{$down768}  { padding-top: 70px; padding-bottom: 70px; }
  @media #{$down600}  { padding-top: 60px; padding-bottom: 60px; }
  @media #{$down500}  { padding-top: 50px; padding-bottom: 50px; }
}
.tP100 { padding-top: 100px;
  @media #{$down1400} { padding-top: 90px; }
  @media #{$down1024} { padding-top: 80px; }
  @media #{$down768}  { padding-top: 70px; }
  @media #{$down600}  { padding-top: 60px; }
  @media #{$down500}  { padding-top: 50px; }
}
.bP100 { padding-bottom: 100px;
  @media #{$down1400} { padding-bottom: 90px; }
  @media #{$down1024} { padding-bottom: 80px; }
  @media #{$down768}  { padding-bottom: 70px; }
  @media #{$down600}  { padding-bottom: 60px; }
  @media #{$down500}  { padding-bottom: 50px; }
}

// Padding de 100px en Y ----------
.yP80 { padding-top: 80px; padding-bottom: 80px;
  @media #{$down768}  { padding-top: 70px; padding-bottom: 70px; }
  @media #{$down600}  { padding-top: 60px; padding-bottom: 60px; }
  @media #{$down500}  { padding-top: 50px; padding-bottom: 50px; }
}
.tP80 { padding-top: 80px;
  @media #{$down768}  { padding-top: 70px; }
  @media #{$down600}  { padding-top: 60px; }
  @media #{$down500}  { padding-top: 50px; }
}
.bP80 { padding-bottom: 80px;
  @media #{$down768}  { padding-bottom: 70px; }
  @media #{$down600}  { padding-bottom: 60px; }
  @media #{$down500}  { padding-bottom: 50px; }
}

// Padding de 100px en Y ----------
.yP50 { padding-top: 50px; padding-bottom: 50px;
  @media #{$down500}  { padding-top: 40px; padding-bottom: 40px; }
}
.tP50 { padding-top: 50px;
  @media #{$down500}  { padding-top: 40px; }
}
.bP50 { padding-bottom: 50px;
  @media #{$down500}  { padding-bottom: 40px; }
}


////////////////////////////////////////////////////////////////////////////////////
// UN PEU DE PADDING EN LARGEUR
////////////////////////////////////////////////////////////////////////////////////
.xP200 { padding-right: 200px; padding-left: 200px;
  @media #{$down1400} { padding-right: 170px; padding-left: 170px; }
  @media #{$down1200} { padding-right: 140px;  padding-left: 140px;  }
  @media #{$down1024} { padding-right: 100px;  padding-left: 100px;  }
  @media #{$down768}  { padding-right: 80px;  padding-left: 80px;  }
  @media #{$down500}  { padding-right: 70px;  padding-left: 70px;  }
}
.rP200 { padding-right: 200px;
  @media #{$down1400} { padding-right: 170px; }
  @media #{$down1200} { padding-right: 140px; }
  @media #{$down1024} { padding-right: 100px; }
  @media #{$down768}  { padding-right: 80px;  }
  @media #{$down500}  { padding-right: 70px;  }
}
.lP200 { padding-left: 200px;
  @media #{$down1400} { padding-left: 170px; }
  @media #{$down1200} { padding-left: 140px;  }
  @media #{$down1024} { padding-left: 100px;  }
  @media #{$down768}  { padding-left: 80px;  }
  @media #{$down500}  { padding-left: 70px;  }
}
.xP150 { padding-right: 150px; padding-left: 150px;
  @media #{$down1400} { padding-right: 120px; padding-left: 120px; }
  @media #{$down1200} { padding-right: 90px;  padding-left: 90px;  }
  @media #{$down1024} { padding-right: 50px;  padding-left: 50px;  }
  @media #{$down768}  { padding-right: 30px;  padding-left: 30px;  }
  @media #{$down500}  { padding-right: 20px;  padding-left: 20px;  }
}
.rP150 { padding-right: 150px;
  @media #{$down1400} { padding-right: 120px; }
  @media #{$down1200} { padding-right: 90px; }
  @media #{$down1024} { padding-right: 50px; }
  @media #{$down768}  { padding-right: 30px; }
  @media #{$down500}  { padding-right: 20px; }
}
.lP150 { padding-left: 150px;
  @media #{$down1400} { padding-left: 120px; }
  @media #{$down1200} { padding-left: 90px; }
  @media #{$down1024} { padding-left: 50px; }
  @media #{$down768}  { padding-left: 30px; }
  @media #{$down500}  { padding-left: 20px; }
}

// Padding de 115px en X ----------
.xP115 { padding-right: 115px; padding-left: 115px;
  @media #{$down1200} { padding-right: 90px; padding-left: 90px; }
  @media #{$down1024} { padding-right: 50px; padding-left: 50px; }
  @media #{$down768}  { padding-right: 30px; padding-left: 30px; }
  @media #{$down500}  { padding-right: 20px; padding-left: 20px; }
}
.rP115 { padding-right: 115px;
  @media #{$down1200} { padding-right: 90px; }
  @media #{$down1024} { padding-right: 50px; }
  @media #{$down768}  { padding-right: 30px; }
  @media #{$down500}  { padding-right: 20px; }
}
.lP115 { padding-left: 115px;
  @media #{$down1200} { padding-left: 90px; }
  @media #{$down1024} { padding-left: 50px; }
  @media #{$down768}  { padding-left: 30px; }
  @media #{$down600}  { padding-left: 20px; }
}

// Padding de 80px en X ----------
.xP80 { padding-right: 80px; padding-left: 80px;
  @media #{$down1024} { padding-right: 50px; padding-left: 50px; }
  @media #{$down768}  { padding-right: 30px; padding-left: 30px; }
  @media #{$down500}  { padding-right: 20px; padding-left: 20px; }
}
.rP80 { padding-right: 80px;
  @media #{$down1024} { padding-right: 50px; }
  @media #{$down768}  { padding-right: 30px; }
  @media #{$down500}  { padding-right: 20px; }
}
.lP80 { padding-left: 80px;
  @media #{$down1024} { padding-left: 50px; }
  @media #{$down768}  { padding-left: 30px; }
  @media #{$down500}  { padding-left: 20px; }
}

// Padding de 50px en X ----------
.xP50 { padding-right: 50px; padding-left: 50px;
  @media #{$down768}  { padding-right: 30px; padding-left: 30px; }
  @media #{$down500}  { padding-right: 20px; padding-left: 20px; }
}
.rP50 { padding-right: 50px;
  @media #{$down768}  { padding-right: 30px; }
  @media #{$down500}  { padding-right: 20px; }
}
.lP50 { padding-left: 50px;
  @media #{$down768}  { padding-left: 30px; }
  @media #{$down500}  { padding-left: 20px; }
}


////////////////////////////////////////////////////////////////////////////////////
// LE 0 ABSOLUE
////////////////////////////////////////////////////////////////////////////////////
// Padding ----------
.noTP	{ padding-top: 0;    }
.noRP { padding-right: 0;  }
.noBP { padding-bottom: 0; }
.noLP { padding-left: 0;   }
.noXP { padding-right: 0; padding-left: 0; }
.noYP { padding-top: 0; padding-bottom: 0; }
.noP  { padding: 0; }

// Margin ----------
.noTM	{ margin-top: 0;    }
.noRM { margin-right: 0;  }
.noBM { margin-bottom: 0; }
.noLM { margin-left: 0;   }
.noXM { margin-right: 0; margin-left: 0; }
.noYM { margin-top: 0; margin-bottom: 0; }
.noM  { margin: 0; }

// Positionnement ----------
.t0    { top: 0;    }
.r0    { right: 0;  }
.b0    { bottom: 0; }
.l0    { left: 0;   }
.trbl0 { top: 0; right: 0; bottom: 0; left: 0; }



////////////////////////////////////////////////////////////////////////////////////
// MISCELLANEOUS
////////////////////////////////////////////////////////////////////////////////////
// White-space ----------
.nowrap { white-space: nowrap; }

// Clear les float ----------
.clearfix { display: block; clear: both; }

// Empêche le scroll
.noscroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll;

  //Fix pour Safari mobile/tablette
  @media screen and (max-width: 768px) {
      overflow-y: hidden;
  }
}

// Empêche la sélection du texte
.noselect { user-select: none; }





////////////////////////////////////////////////////////////////////////////////////
// HIDE AND SEEK
////////////////////////////////////////////////////////////////////////////////////
// Hide ----------
.hide { display: none;  }
@media #{$down1800} { .hide_1800 { display: none; }}
@media #{$down1700} { .hide_1700 { display: none; }}
@media #{$down1600} { .hide_1600 { display: none; }}
@media #{$down1500} { .hide_1500 { display: none; }}
@media #{$down1400} { .hide_1400 { display: none; }}
@media #{$down1300} { .hide_1300 { display: none; }}
@media #{$down1200} { .hide_1200 { display: none; }}
@media #{$down1100} { .hide_1100 { display: none; }}
@media #{$down1024} { .hide_1024 { display: none; }}
@media #{$down1000} { .hide_1000 { display: none; }}
@media #{$down900}  { .hide_900  { display: none; }}
@media #{$down800}  { .hide_800  { display: none; }}
@media #{$down768}  { .hide_768  { display: none; }}
@media #{$down700}  { .hide_700  { display: none; }}
@media #{$down600}  { .hide_600  { display: none; }}
@media #{$down500}  { .hide_500  { display: none; }}
@media #{$down400}  { .hide_400  { display: none; }}



////////////////////////////////////////////////////////////////////////////////////
// I SEE YOU
////////////////////////////////////////////////////////////////////////////////////
// Show ----------
.show { display: block;  }
.show_1800 { display: none; @media #{$down1800} { display: block; }}
.show_1700 { display: none; @media #{$down1700} { display: block; }}
.show_1600 { display: none; @media #{$down1600} { display: block; }}
.show_1500 { display: none; @media #{$down1500} { display: block; }}
.show_1400 { display: none; @media #{$down1400} { display: block; }}
.show_1300 { display: none; @media #{$down1300} { display: block; }}
.show_1200 { display: none; @media #{$down1200} { display: block; }}
.show_1100 { display: none; @media #{$down1100} { display: block; }}
.show_1024 { display: none; @media #{$down1024} { display: block; }}
.show_1000 { display: none; @media #{$down1000} { display: block; }}
.show_900  { display: none; @media #{$down900}  { display: block; }}
.show_800  { display: none; @media #{$down800}  { display: block; }}
.show_768  { display: none; @media #{$down768}  { display: block; }}
.show_700  { display: none; @media #{$down700}  { display: block; }}
.show_600  { display: none; @media #{$down600}  { display: block; }}
.show_500  { display: none; @media #{$down500}  { display: block; }}
.show_400  { display: none; @media #{$down400}  { display: block; }}



////////////////////////////////////////////////////////////////////////////////////
// EN PROFONDEUR
////////////////////////////////////////////////////////////////////////////////////
// Z-index ----------
.zIndex-1 { z-index: -1; }
.zIndex0  { z-index: 0;  }
.zIndex1  { z-index: 1;  }
.zIndex2  { z-index: 2;  }
.zIndex3  { z-index: 3;  }
.zIndex4  { z-index: 4;  }
.zIndex5  { z-index: 5;  }
.zIndex6  { z-index: 6;  }
.zIndex7  { z-index: 7;  }
.zIndex8  { z-index: 8;  }
.zIndex9  { z-index: 9;  }
.zIndex10 { z-index: 10; }