.calendar {
    & > div {
        position: relative;
        padding: 0 20px 10px;
        max-width: 400px;
        //margin: 0 auto;
        background: white;

        &:before {
            content: '';
            position: absolute;
            height: 77px;
            top: 0;
            left: 0;
            right: 0;
            background: $color1;
        }
    }
}
.datepicker {
    width: 300px;
    padding-bottom: 30px;
    border-radius: 4px;
    direction: ltr;
    margin: 0 auto;

    @media #{$down1200} { width: 250px; }
    @media #{$down400}  { width: 220px; }
    
    &.datepicker-inline { width: 100%; }
    &.datepicker-rtl { direction: rtl; }
    &.datepicker-rtl.dropdown-menu { left: auto; }
    &.datepicker-rtl table tr td span { float: right; }
    
    .datepicker-switch { width: 145px; }
    .datepicker-switch, .prev, .next, tfoot tr th { cursor: pointer; }
    .prev.disabled, .next.disabled { visibility: hidden; }
    .cw { width: 12px; padding: 0 2px 0 5px; font-size: 10px; vertical-align: middle; }
    
    table {
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 auto;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
        @media #{$down1200} { width: 250px; }
        @media #{$down400}  { width: 220px; }
        
        thead {
            tr {
                .prev, .next, .datepicker-switch { background: $color1; }

                .prev, .next {
                    position: relative;
                    font-size: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 14px;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 1;
                        background: url('../images/icons/chevronWhite.svg') no-repeat center / contain;
                        transition: opacity 300ms;
                    }

                    &:hover:after { opacity: 0.5; }
                }

                .prev:after { left: 10px; transform: translateY(-50%) rotate(180deg); }
                .next:after { right: 10px; }
                
                .datepicker-switch {
                    padding: 30px 0 30px 0;
                    font: 17px/1 $font;
                    color: #fff;
                    cursor: default;
                }

                .dow {
                    padding-top: 40px;
                    padding-bottom: 15px;
                    font: 12px/1 $font;
                    font-weight: 700;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 10px 0;
                    position: relative;
                    font-weight: 600;
                }
            }
        }
        
        tr {
            td, th {
                width: 30px;
                text-align: center;
                border: none;
                font: 12px/1 $font;
                color: $color1;
            }

            td {
                &:before {
                    content: '';
                    position: absolute;
                    width: 35px;
                    height: 35px;
                    top: 49%;
                    left: 50%;
                    background: transparent;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: background 300ms;

                    @media #{$down400} { width: 36px; height: 36px; }
                    @media #{$down400} { top: 45%; }
                }

                /*&.day:hover, &.focused {
                    background: #eeeeee;
                    cursor: pointer;
                }*/

                &.disabled, &.disabled:hover {
                    background: none;
                    // color: $color1;
                    cursor: default;
                }

                &:not(.disabled){
                    position: relative;
                    cursor: pointer;
                    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.8s;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        top: 3px;
                        right: 13px;
                        background: $color3;
                        border-radius: 50%;
                        transition: background 300ms;

                        @media #{$down1200} { right: 5px; }
                        @media #{$down400}  { right: 4px; }
                    }

                    &:hover{
                        color: white;

                        &:before {
                            background: $color1;
                        }

                        &::after{
                            transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
                            background-color: white;
                        }
                    }
                }

                &.highlighted {
                    color: $color1;
                    border-radius: 0;
                    background: $color1;
                    cursor: pointer;

                    &:focus, &.focus {
                        color: #fff;

                        &:before{
                            background-color: $color1;
                        }
                    }

                    &:hover {
                        color: #fff;
                        &:before{
                            background-color: $color1;
                        }
                    }

                    &:active, &.active {
                        color: #fff;
                        &:before{
                            background-color: $color1;
                        }
                    }

                    &:active:hover, &.active:hover, &:active:focus, &.active:focus, &:active.focus, &.active.focus {
                        color: #fff;
                        // background-color: $color1;
                    }

                    &.focused {
                        color: #fff;
                        &:before{
                            background-color: $color1;
                        }
                    }

                    &.disabled, &.disabled:active {
                        background: #d9edf7;
                        color: #979797;
                    }
                }

                &.today {
                    color: white !important;
                    
                    &:before { background: $color3; }
                    &:not(.disabled):after { background: #fff; }
                }

                &:focus, &.focus {
                    color: white;
                    background-color: rgba($color1,0.6);
                }

                &:hover {
                    // color: white;
                    background-color: transparent;
                }

                &.active {
                    color: white;
                    //background-color: $color1;
                    &::after{
                        background-color: $color1;
                    }
                    &::before{
                        background-color: $color1;
                    }
                }
                
                /*&:active:hover, &.active:hover, &:active:focus, &.active:focus, &:active.focus, &.active.focus {
                    color: white;
                    background-color: #ffbc42;
                }

                &.focused { background: #ffc966; }

                &.disabled, &.disabled:active {
                    background: #ffdb99;
                    color: #979797;
                }*/

                &.old, &.new {
                    color: #979797;
                    &:hover{
                        color: #979797;
                    }
                    &:not(.disabled){
                        &:hover{
                            color: $color1;
                        }
                    }
                }

                &.range {
                    color: #000;
                    background-color: #eeeeee;
                    border-color: #bbbbbb;
                    border-radius: 0;

                    &:focus, &.focus {
                        color: #000;
                        background-color: #d5d5d5;
                        border-color: #7c7c7c;
                    }

                    &:hover {
                        color: #000;
                        background-color: #d5d5d5;
                        border-color: #9d9d9d;
                    }

                    &:active, &.active {
                        color: #000;
                        background-color: #d5d5d5;
                        border-color: #9d9d9d;
                    }

                    &:active:hover, &.active:hover, &:active:focus, &.active:focus, &:active.focus, &.active.focus {
                        color: #000;
                        background-color: #c3c3c3;
                        border-color: #7c7c7c;
                    }

                    &.focused { background: #d5d5d5; }

                    &.disabled, &.disabled:active {
                        background: #eeeeee;
                        color: #979797;
                    }
                }

                .range.highlighted {
                    color: #000;
                    background-color: #e4eef3;
                    border-color: #9dc1d3;
                    

                    &:focus, &.focus {
                        color: #000;
                        background-color: #c1d7e3;
                        border-color: #4b88a6;
                    }

                    &:hover {
                        color: #000;
                        background-color: #c1d7e3;
                        border-color: #73a6c0;
                    }

                    &:active, &.active {
                        color: #000;
                        background-color: #c1d7e3;
                        border-color: #73a6c0;
                    }

                    &:active:hover, &.active:hover, &:active:focus, &.active:focus, &:active.focus, &.active.focus {
                        color: #000;
                        background-color: #a8c8d8;
                        border-color: #4b88a6;
                    }

                    &.focused { background: #c1d7e3; }

                    &.disabled, &.disabled:active {
                        background: #e4eef3;
                        color: #979797;
                    }
                }

                .range.today {
                    color: #000;
                    background-color: #f7ca77;
                    border-color: #f1a417;

                    &:focus, &.focus {
                        color: #000;
                        background-color: #f4b747;
                        border-color: #815608;
                    }

                    &:hover {
                        color: #000;
                        background-color: #f4b747;
                        border-color: #bf800c;
                    }

                    &:active, &.active {
                        color: #000;
                        background-color: #f4b747;
                        border-color: #bf800c;
                    }

                    &:active:hover, &.active:hover, &:active:focus, &.active:focus, &:active.focus, &.active.focus {
                        color: #000;
                        background-color: #f2aa25;
                        border-color: #815608;
                    }

                    &.disabled, &.disabled:active {
                        background: #f7ca77;
                        color: #979797;
                    }
                }

                &.selected {
                    &, &.highlighted {
                        color: #fff;
                        background-color: #979797;
                        border-color: #555555;
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                    }

                    &:focus, &.highlighted:focus, &.focus, &.highlighted.focus {
                        color: #fff;
                        background-color: #5e5e5e;
                        border-color: #161616;
                    }

                    &:hover, &.highlighted:hover {
                        color: #fff;
                        background-color: #5e5e5e;
                        border-color: #373737;
                    }

                    &:active, &.highlighted:active, &.active, &.highlighted.active {
                        color: #fff;
                        background-color: #5e5e5e;
                        border-color: #373737;
                    }

                    &:active:hover, &.highlighted:active:hover, &.active:hover, &.highlighted.active:hover, &:active:focus, &.highlighted:active:focus, &.active:focus, &.highlighted.active:focus, &:active.focus, &.highlighted:active.focus, &.active.focus, &.highlighted.active.focus {
                        color: #fff;
                        background-color: #4c4c4c;
                        border-color: #161616;
                    }
                }

                /*&.active {
                    &, &.highlighted {
                        color: #fff;
                        background-color: #337ab7;
                        border-color: #2e6da4;
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                    }

                    &:focus, &.highlighted:focus, &.focus, &.highlighted.focus {
                        color: #fff;
                        background-color: #286090;
                        border-color: #122b40;
                    }

                    &:hover, &.highlighted:hover {
                        color: #fff;
                        background-color: #286090;
                        border-color: #204d74;
                    }

                    &:active, &.highlighted:active, &.active, &.highlighted.active {
                        color: #fff;
                        background-color: #286090;
                        border-color: #204d74;
                    }

                    &:active:hover, &.highlighted:active:hover, &.active:hover, &.highlighted.active:hover, &:active:focus, &.highlighted:active:focus, &.active:focus, &.highlighted.active:focus, &:active.focus, &.highlighted:active.focus, &.active.focus, &.highlighted.active.focus {
                        color: #fff;
                        background-color: #204d74;
                        border-color: #122b40;
                    }
                }*/

                span {
                    display: block;
                    width: 23%;
                    height: 54px;
                    line-height: 54px;
                    float: left;
                    margin: 1%;
                    cursor: pointer;
                    border-radius: 4px;

                    &:hover, &.focused { background: #eeeeee; }

                    &.disabled, &.disabled:hover {
                        background: none;
                        color: #979797;
                        cursor: default;
                    }

                    &.active, &.active:hover, &.active.disabled, &.active.disabled:hover {
                        color: #fff;
                        background-color: #337ab7;
                        border-color: #2e6da4;
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                    }

                    &.active:focus, &.active:hover:focus, &.active.disabled:focus, &.active.disabled:hover:focus, &.active.focus, &.active:hover.focus, &.active.disabled.focus, &.active.disabled:hover.focus {
                        color: #fff;
                        background-color: #286090;
                        border-color: #122b40;
                    }

                    &.active:hover, &.active:hover:hover, &.active.disabled:hover, &.active.disabled:hover:hover {
                        color: #fff;
                        background-color: #286090;
                        border-color: #204d74;
                    }

                    &.active:active, &.active:hover:active, &.active.disabled:active, &.active.disabled:hover:active, &.active.active, &.active:hover.active, &.active.disabled.active, &.active.disabled:hover.active {
                        color: #fff;
                        background-color: #286090;
                        border-color: #204d74;
                    }

                    &.active:active:hover, &.active:hover:active:hover, &.active.disabled:active:hover, &.active.disabled:hover:active:hover, &.active.active:hover, &.active:hover.active:hover, &.active.disabled.active:hover, &.active.disabled:hover.active:hover, &.active:active:focus, &.active:hover:active:focus, &.active.disabled:active:focus, &.active.disabled:hover:active:focus, &.active.active:focus, &.active:hover.active:focus, &.active.disabled.active:focus, &.active.disabled:hover.active:focus, &.active:active.focus, &.active:hover:active.focus, &.active.disabled:active.focus, &.active.disabled:hover:active.focus, &.active.active.focus, &.active:hover.active.focus, &.active.disabled.active.focus, &.active.disabled:hover.active.focus {
                        color: #fff;
                        background-color: #204d74;
                        border-color: #122b40;
                    }

                    &.old, &.new { color: #979797; }
                }
            }
        }
    }
}

.datepicker-dropdown {
    padding: 4px;
    top: 0;
    left: 0;

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        border-top: 0;
        border-right: 7px solid transparent;
        border-bottom: 7px solid rgba(0, 0, 0, 0.2);
        border-left: 7px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        display: inline-block;
        border-top: 0;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }

    &.datepicker-orient-left:before   { left: 6px;  }
    &.datepicker-orient-left:after    { left: 7px;  }
    &.datepicker-orient-right:before  { right: 6px; }
    &.datepicker-orient-right:after   { right: 7px; }
    &.datepicker-orient-bottom:before { top: -7px;  }
    &.datepicker-orient-bottom:after  { top: -6px;  }
    &.datepicker-orient-top:before    { bottom: -7px; border-bottom: 0; border-top: 7px solid rgba(0, 0, 0, 0.15); }
    &.datepicker-orient-top:after     { bottom: -6px; border-bottom: 0; border-top: 6px solid #fff; }
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th { background-color: transparent; }


.datepicker table tr td.highlighted.disabled:hover, .datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus, .datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus, .datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
    background-color: #d9edf7;
    border-color: #85c5e5;
}


.datepicker table tr td.range.disabled:hover, .datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus, .datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus, .datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
    background-color: #eeeeee;
    border-color: #bbbbbb;
}


.datepicker table tr td.range.highlighted.disabled:hover, .datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus, .datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus, .datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
    background-color: #e4eef3;
    border-color: #9dc1d3;
}


.datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus, .datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus, .datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
    background-color: #f7ca77;
    border-color: #f1a417;
}


.datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover, .datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover, fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus, .datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus, .datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus, fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus, .datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus, .datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus, fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
    background-color: #979797;
    border-color: #555555;
}


.datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover, .datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover, fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus, .datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus, .datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus, fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus, .datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus, .datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus, fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
}


.datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover.disabled:hover, .datepicker table tr td span.active.disabled.disabled:hover, .datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover, .datepicker table tr td span.active:hover[disabled]:hover, .datepicker table tr td span.active.disabled[disabled]:hover, .datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover, fieldset[disabled] .datepicker table tr td span.active:hover:hover, fieldset[disabled] .datepicker table tr td span.active.disabled:hover, fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active:hover.disabled:focus, .datepicker table tr td span.active.disabled.disabled:focus, .datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus, .datepicker table tr td span.active:hover[disabled]:focus, .datepicker table tr td span.active.disabled[disabled]:focus, .datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus, fieldset[disabled] .datepicker table tr td span.active:hover:focus, fieldset[disabled] .datepicker table tr td span.active.disabled:focus, fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus, .datepicker table tr td span.active:hover.disabled.focus, .datepicker table tr td span.active.disabled.disabled.focus, .datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus, .datepicker table tr td span.active:hover[disabled].focus, .datepicker table tr td span.active.disabled[disabled].focus, .datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus, fieldset[disabled] .datepicker table tr td span.active:hover.focus, fieldset[disabled] .datepicker table tr td span.active.disabled.focus, fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
}


.input-group.date .input-group-addon { cursor: pointer; }
.input-daterange { width: 100%; }
.input-daterange input { text-align: center; }
.input-daterange input:first-child { border-radius: 3px 0 0 3px; }
.input-daterange input:last-child { border-radius: 0 3px 3px 0; }
.input-daterange .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    line-height: 1.42857143;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
}
